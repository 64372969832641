import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./event-single.css"
import { serverLink } from "../../../resources/url";
import axios from "axios";
import { setEventsDetails } from "../../../actions/setactiondetailsection";
import * as DOMPurify from 'dompurify';
import { checkImageURL, formatDateAndTime, projectDomain } from "../../../resources/constants";
import { titleCase } from "title-case";
import Facebook from "../../../images/social-icon-facebook-round.svg";
import Twitter from "../../../images/social-icon-twitter-round.svg";
import LinkedIn from "../../../images/social-icon-linkedin-round.svg";
import Instagram from "../../../images/social-icon-instagram-round.svg";
import Whatsapp from "../../../images/social-icon-whatsapp.svg";
import { MuiFbPhotoGrid } from 'mui-fb-photo-grid';
import DefBanner from './events_banner.jpg'

const EventSingle = (props) => {
    let page_url = window.location.pathname;
    let page_id = page_url.split('/');
    let id = page_id[2];

    const slug = id
    const [isLoading, setIsloading] = useState(true)
    const [eventData, seteventData] = useState([])
    const [gallery_images, setgallery_images] = useState([])
    const [img_files, setimg_files] = useState([])

    useEffect(() => {
        if (slug !== '') {
            if (props.eventData !== '') {
                const eventData = props.eventData.filter(item => item.Slug === slug);
                if (eventData.length > 0) {
                    setIsloading(false);
                    seteventData(eventData[0])
                }
                else
                    fetchEvents();
            } else
                fetchEvents();
        } else {
            window.location.href = '/events'
        }


        if (props.eventData !== undefined) {
            const eventData = props.eventData.filter(item => item.Slug === slug)[0];
            if (eventData.EntryID !== undefined) {
                fetGallery(eventData.EntryID);
            }
        }
    }, [slug])

    const fetGallery = (event_id) => {
        axios.get(`${serverLink}event/get-multi-images/${event_id}`).then((res) => {
            if (res.data.length > 0) {
                res.data.map((gallery) => {
                    img_files.push({
                        title: gallery.ImageTitle, // require
                        img: ImgeUrl(gallery.ImagePath), // require
                        imgThumbnail: ImgeUrl(gallery.ImagePath), // optional
                    }
                    )
                })
            }
            setgallery_images(img_files)
        })
    }

    async function fetchEvents() {
        await axios.get(`${serverLink}event/list/active`)
            .then(result => {
                props.setOnEventDetails(result.data);
                const eventData = result.data.filter(item => item.Slug === slug);
                if (eventData.length > 0) {
                    setIsloading(false);
                    seteventData(eventData[0])
                }
                else
                    window.location.href = '/events'

                if (eventData[0].EntryID !== undefined) {
                    fetGallery(eventData[0].EntryID);
                }

            })
            .catch(e => {
                console.log('EVENT FETCH NETWORK ERROR')
            })
    }

    const formatDate = (date) => {
        const dt = new Date(date);
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return `${monthNames[dt.getMonth()]} ${dt.getDate()}, ${dt.getFullYear()}`
    };

    const ImgeUrl = (url) => {
        if (url === null || url === "") {
            return DefBanner;
        } else if (url.includes("simplefileupload")) {
            return checkImageURL(url) ? url : DefBanner
        }
        else {
            return `${checkImageURL(`${serverLink}public/uploads/events_uploads/${url}`) ? `${serverLink}public/uploads/events_uploads/${url}` : DefBanner} `
        }
    }

    return isLoading ?
        <div className="container-fluid layout col1" >
            <div className="row" >
                <div className="layout-col col1 layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-text container-fluid roundable block text">
                        <div className="row">
                            <div className="container">
                                <div className="row header left">
                                    <div className="col-sm-12">
                                        <h2>
                                            <div>Loading...</div>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> :
        <div className="container-fluid layout col1 mtop">
            <div className="row">
                <div className="layout-col layout-12 col-sm-12">

                    <div className="container-fluid roundable block news-content">
                        <div className="row news-hero-section news-header-image-below light-orange-accents">
                            <div className="container">
                                <br />
                                <br />
                                <div className="row news-extras">
                                    <div className="col-xs-9 publish-date">
                                        <p className="title">Event Date: {formatDateAndTime(eventData.EventDate, "date")}</p>
                                        <p> <i className="fa fa-clock-o" /> {eventData.StartTime} -
                                            {eventData.EndTime === "NULL" ? parseInt(eventData.StartTime.split(":")[0]) + 2 + ":00" : eventData.EndTime} &emsp;| <i className="fa fa-map-marker" /> {eventData.Location} &emsp; | <i className="fa fa-calendar-check-o" /> {formatDateAndTime(eventData.EventDate, "date")}</p>


                                    </div>
                                </div>
                                <div className="row news-header">
                                    <div className="col-md-8 content">
                                        <h1 className="head">{titleCase(eventData.Title)}</h1>
                                    </div>
                                    <div className="col-md-4 sidebar">
                                        <div className="sidebar-outline">
                                            <div className="author">
                                                <p className="title">Author</p>
                                                <p className="name">{eventData.Author
                                                    !== null ? titleCase(eventData.Author) :
                                                    titleCase(eventData.InsertedBy)}
                                                </p>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row news-body-content">
                            <div className="container light-orange-accents">
                                <div className="row news-body">
                                    <div className="col-md-10 col-sm-12 content">

                                        <div className="body drop-cap text-justify">
                                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(eventData.Description) }} />
                                        </div>

                                    </div>
                                    <div className="col-md-2 col-sm-12" >
                                        <div id="footer-links" style={style} >
                                            <ul className="social" style={{ textDecoration: "none" }} >
                                                <li style={{ textDecoration: "none" }} >
                                                    <a className="btn-social"
                                                        href={`http://www.facebook.com/sharer/sharer.php?u=${projectDomain}news/${eventData.Slug}`} target="_blank"
                                                        style={{ textDecoration: "none" }}>
                                                        <i className='fa fa-facebook-f' style={{ fontSize: "30px", marginRight: "10px" }} />
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="btn-social"
                                                        title={eventData.Title.trim()}
                                                        href={`https://twitter.com/share?text=${eventData.Title}&url=${projectDomain}news/${eventData.Slug}`}
                                                        target="_blank">
                                                        <i className='fa fa-twitter' style={{ fontSize: "30px", marginRight: "10px" }} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="btn-social"
                                                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${projectDomain}news/${eventData.Slug}`}
                                                        target="_blank">
                                                        <i className='fa fa-linkedin' style={{ fontSize: "30px", marginRight: "10px" }} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="btn-social"
                                                        href={`https://www.instagram.com/?url=${projectDomain}news/${eventData.Slug}`}
                                                        target="_blank">
                                                         <i className='fa fa-instagram' style={{ fontSize: "30px", marginRight: "10px" }} />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            gallery_images.length > 0 &&
                                            <div>
                                                <h3>Event in Photos</h3>
                                                <MuiFbPhotoGrid
                                                    images={gallery_images} // require
                                                    reactModalStyle={{ overlay: { zIndex: 2000 } }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

}

const style = {
    root: {
        background: 'linear-gradient(45deg, #b3b4b5 30%, #545352 90%)',
        borderRadius: 3,
        border: 0,
        boxShadow: '0 3px 5px 2px',
        color: 'white',

    },
    copyContainer: {
        border: '1px solid grey',
        background: 'rgb(0,0,0,0.7)'
    },
    title: {
        color: 'aquamarine',
        fontStyle: 'italic',
    }
};

const mapStateToProps = (state) => {
    return {
        eventData: state.BazeEventDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnEventDetails: (p) => {
            dispatch(setEventsDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventSingle)
