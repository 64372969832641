
import React from "react";
import Banner from "../utils/banner/banner";
import Square from "../../images/ssu.png";
import StudentAffairsDean from "../../images/ssu_tarka.png";
import HRLine from "../utils/hrline";

function StudentAffairs() {
    return <>
        <Banner image={Square} title="Student Support Unit" caption="" />
        <div className="container-fluid layout col1" style={{ margin: "20px" }}>
            <div className="row">

                <div className="container-fluid layout col2-50-50">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="layout-col col1 layout-12 col-sm-12">
                                    <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                        <div className="row">
                                            <div className="container text-justify">
                                                <div className="row header">
                                                    <div className="col-sm-12" style={{ backgroundColor: '#f5f5f5', padding: '30px', borderTopLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
                                                        <span>
                                                            <p>
                                                                The Student Support Unit (SSU), established on 5th September, 2017 is designed to cater for the needs and welfare of the students ranging from educational, vocational, personal, social, psychological, emotional and mental health challenges. SSU is where the interests of students are prioritised.
                                                            </p>
                                                            <p>
                                                                We offer a wide range of services that create an environment for guidance towards good conduct and stability. This fosters a cordial relationship in the University community comprising staff and students.
                                                                According to the NUC (2015) the counselling and human development centre is an organ of the student support unit saddled with the major responsibility of promoting guidance and counselling; assisting students in various areas of their lives.
                                                            </p>
                                                            <p>These services are necessary in order to assist students to clearly define and actualise their personal, social, academic and occupational goals by providing group and individual services. These services are modelled to stimulate the growth of their intellect, emotional comportment and foster personal development goals through effective communication, leadership, interpersonal and social responsibility as well as other relevant skills which can be achieved through either individual or group counselling.
                                                            </p>
                                                            <p>
                                                                SSU has engaged in some services such as guidance, career counselling, marriage and family counselling, rehabilitation counselling, substance abuse counselling, educational counselling, emotional counselling and mental health therapy. The challenges enumerated above continue to task counsellors, leading them to continually evolve therapeutic methods and processes to address the urgency of the varying situations presented before them. These services are accessible at SSU for students to avail themselves.
                                                            </p>
                                                        </span>
                                                    </div>
                                                </div>

                                                <HRLine />
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <p>
                                                            Mrs. Vivienne Seember Tarka is a practicing Counselling Psychologist. She is a graduate of English Language (University of Abuja) with a Post Graduate Diploma in Education, Master’s Degree in Guidance and Counselling (University of Lagos) and a PhD in Guidance and Counselling in-view.
                                                            <br />
                                                            She is currently a lecturer in the Department of Psychology at Baze University Abuja where she teaches the undergraduate courses in Counselling Psychology, Psychology of Ethnicity and Ethnic Groups, Principles of Criminology and Juvenile Delinquency. She also taught Behaviour Modification in the past for some years.<br />
                                                            She also handles an additional responsibility as the Head of Student Support Unit (Counselling Unit) in Baze University where she is privileged to supervise and nurture the take-off few years ago.<br />
                                                            Mrs. Tarka has been a guest speaker/facilitator at different fora and has also presented on numerous counselling topics. Her experience working with children and young people spans over 25 years.<br />
                                                            Mrs. Tarka is a writer with published and unpublished works. She is a member of the Counselling Association of Nigeria (CASSON), Association of Professional Counsellors in Nigeria (APROCON) and Teachers Registration Council of Nigeria (TRCN).
                                                        </p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="media-body">
                                                            <div className="image-wrapper" style={{ float: 'right', borderRadius: '10px' }}>
                                                                <div>
                                                                    <img
                                                                        style={{ borderRadius: '10px' }}
                                                                        height={'400px'}
                                                                        width="100%"
                                                                        loading="lazy"
                                                                        src={StudentAffairsDean}
                                                                        typeof="foaf:Image" />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <HRLine />
                                                    <div className="col-sm-12" style={{ marginTop: '20px' }} >
                                                        <div className="more-info-items two-items">
                                                            <div className="media more-info-item">
                                                                <div className="media-body">
                                                                    <div className="container">
                                                                        <div className="row left" >
                                                                            <div className="col-sm-12 one-col block_quate text-justify">
                                                                                <h2>Vision</h2>
                                                                                <p>
                                                                                    To become a reference centre where members of the University are aided to become self aware and make informed Choices to enable excellent educational and career needs in teaching, learning, career development and psycho-social wellbeing.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row left" >

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media more-info-item">
                                                                <div className="media-body">
                                                                    <div className="image-wrapper" style={{ float: 'right', borderRadius: '10px' }}>
                                                                        <div className="col-sm-12 one-col block_quate text-justify">
                                                                            <h2>Mission</h2>
                                                                            <p>To promote and work effectively with staff and Students educational and career needs, ensuring a positive learning outcome.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <HRLine />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="layout-col col1 layout-12 col-sm-12">
                                    <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                        <div className="row text-center">

                                            <div className="col-md-6">
                                                <div className="col-sm-12 one-col block_quate text-center"
                                                    style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '10px' }}>
                                                    <h4>GUIDANCE SERVICES</h4>
                                                    <p>
                                                        We help individuals to understand themselves, minimise their mistakes and enjoy their world.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="col-sm-12 one-col block_quate text-center"
                                                    style={{ backgroundColor: 'rgb(220,220,220)', padding: '10px', borderRadius: '10px' }}>
                                                    <h4>COUNSELLING SERVICES
                                                    </h4>
                                                    <p>
                                                        We have trained professional counsellors to assist you in Educational, vocational, social, recreational, mental, emotional and/or moral aspects. <br />
                                                        We also encourage Individual and Group Counselling.
                                                        <br />Online sessions available for categories of cases.
                                                    </p>
                                                </div>
                                            </div>

                                            {/* <div className="more-info-items two-items">
                                                    <div className="media more-info-item">
                                                        <div className="media-body">
                                                            <div className="container">
                                                                <div className="row left" >
                                                                    <div className="col-sm-12 one-col block_quate text-justify">
                                                                        <h2>Vision</h2>
                                                                        <p>
                                                                            To become a reference centre where members of the University are aided to become self aware and make informed Choices to enable excellent educational and career needs in teaching, learning, career development and psycho-social wellbeing.

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="row left" >
                                                                    <div className="col-sm-12 one-col block_quate text-justify">
                                                                        <h2>Mission</h2>
                                                                        <p>To promote and work effectively with staff and Students educational and career needs, ensuring a positive learning outcome.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media more-info-item">
                                                        <div className="media-body">
                                                            <div className="image-wrapper" style={{ float: 'right', borderRadius: '10px' }}>
                                                                <div>
                                                                    <img
                                                                        style={{ borderRadius: '10px' }}
                                                                        height={'400px'}
                                                                        width="100%"
                                                                        loading="lazy"
                                                                        src={StudentAffairsDean}
                                                                        typeof="foaf:Image" />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" style={{ textAlign: 'center' }}>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfS4b4OTEbceB5aTWM5-siFPKKtpJXy2_mTpuEC1OejHni5AQ/viewform?vc=0&c=0&w=1&flr=0" target={"_blank"} className="btn btn-default btn-cta btn-primary">
                                        Book a Session
                                    </a>

                                </div>
                                <HRLine />
                                <div className="layout-col col1 layout-12 col-sm-12">
                                    <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                        <div className="row">
                                            <div className="container text-justify">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="more-info-items two-items">
                                                            <div className="media more-info-item">
                                                                <div className="media-body">
                                                                    <h3>Justification for SSU</h3>
                                                                    <p>
                                                                        <ul style={{listStyle : "initial"}}>
                                                                            <li>40 seconds one person commits suicide around the world which tallies with 800,000 suicides annually (WHO). Nigeria is not exempted.</li>
                                                                            <li>3 out 10 Nigerians are mentally sick (Vanguard, 2022)
                                                                            </li>
                                                                            <li>40% of Nigerian youth between age 18 and 35 engage in substance abuse (NDLEA). </li>
                                                                            <li>75% of Nigerian undergraduates engage in substance abuse (Ogoloma, F. 2014).
                                                                            </li>
                                                                            <li>246 million children and adolescents around the world experience school violence and bullying (UNESCO).
                                                                            </li>
                                                                            <li>Sexual violence and harassment is on the rise.
                                                                            </li>
                                                                            <p>The creation of the SSU became necessary to enable students to cope and help them overcome  traumatic experiences which could  affect their academics or their total well-being. SSU is also created in accordance with NUC laws.</p>

                                                                        </ul>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="media more-info-item">
                                                                <div className="media-body" >
                                                                    <h3>Intervention</h3>
                                                                    <p>
                                                                        <ul style={{listStyle : "initial"}}>
                                                                            <li>Needs identification.</li>
                                                                            <li>Community Dialogue and integration</li>
                                                                            <li>Career Development Week/Day
                                                                            </li>
                                                                            <li>Supported Programmes</li>
                                                                            <li>Creativity and powerful storytelling</li>
                                                                            <li>Diagnostic Assessment</li>
                                                                            <li>Individualised educational programmes</li>
                                                                            <li>Evaluation and quality assurance</li>
                                                                            <li>Referral services</li>
                                                                            <li>Follow-up</li>
                                                                            <li>Termination</li>


                                                                        </ul>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <h3>SSU Staff</h3>
                                                        <div className="table table-responsive">
                                                            <table className="table table-responsive table-hover " >
                                                                <thead>
                                                                    <th>SN</th>
                                                                    <th>Name</th>
                                                                    <th>Position</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td>Mrs V.S Tarka</td>
                                                                        <td>Head, SSU / Counsellor/Lecturer</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2</td>
                                                                        <td>Mr Augustine Ochui Akwong</td>
                                                                        <td>Guidance Counsellor </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td>Mrs Angela Ihejieto</td>
                                                                        <td>Forensic Psychologist</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td>Dr O. Fagbohun</td>
                                                                        <td>Mental Health Advisor/Lecturer </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td>Dr. Adaka. T. Ahon</td>
                                                                        <td>Special Needs Expert (Visiting Specialist)</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="layout-col layout-12 col-sm-12">
                                    <div className="layout-blocks-ucws-text container-fluid roundable block text">
                                        <div className="row">
                                            <div className="container">
                                                <div className="row left">
                                                    <div className="col-sm-12 one-col block_quate text-justify">
                                                        <h2>Contact Us</h2>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h4>Address:</h4>
                                                                <address>
                                                                    Block A Flat 2 Gowon Estate , <br />Staff Quarters, <br />Baze University, Abuja.
                                                                    <br />
                                                                    <strong>09060742436 or 08181057030</strong>
                                                                </address>

                                                            </div>
                                                            <div className="col-md-6">
                                                                <h4>Connect with us:</h4>
                                                                <div>
                                                                    <span>Email: <a target={"_blank"} href="mailto:studentsupportunit@bazeuniversity.edu.ng" >studentsupportunit@bazeuniversity.edu.ng</a></span>
                                                                </div>
                                                                <div>
                                                                    <span>Fcebook: <a target={"_blank"} href="https://www.facebook.com/BazeUniversity" >https://www.facebook.com/BazeUniversity</a></span>
                                                                </div>
                                                                <div>
                                                                    <span>Twitter: <a target={"_blank"} href="https://twitter.com/Baze_University" >https://twitter.com/Baze_University</a></span>
                                                                </div>
                                                                <div>
                                                                    <span>Instagram: <a target={"_blank"} href="https://www.instagram.com/Official_BazeUniversity/" >https://www.instagram.com/Official_BazeUniversity/</a></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </>
}

export default StudentAffairs;
