import React, { Component } from "react";
import Banner from "../utils/banner/banner";
import HRLine from "../utils/hrline";
import axios from "axios";
import { serverLink } from "../../resources/url";
import { Link } from "react-router-dom";
import ResearchBanner from "./img/research2.jpeg";
import ResearchAreas from "./research-areas";

class ResearchAtBazeUniversity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            members: []
        }
    }

    componentDidMount() {
        this.getMembers();
    }

    async getMembers() {
        await axios.get(`${serverLink}staff/website/itri_staff/list`)
            .then(result => {
                this.setState({ members: result.data })
            })
            .catch(error => {
                console.log("UNABLE TO RETRIEVE THE MEMBERS LIST");
            })
    }

    render() {
        return <>
            <Banner image={ResearchBanner} title="Research at Baze University" caption="" disableTitle />

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <span>
                                                <h3>Research at Baze University</h3>
                                                <p style={{ marginTop: '20px' }} >
                                                    <h3>Welcome!</h3>
                                                    We are delighted to extend a warm welcome to all researchers, scholars, and innovators from diverse backgrounds.
                                                    Our Office is dedicated to fostering a vibrant and collaborative research community where cutting-edge ideas are explored, transformative discoveries are made, and innovative solutions are developed.
                                                    </p>
                                                <p style={{ marginTop: '20px' }} >
                                                    As a hub of intellectual curiosity and technological advancement, our office provides a stimulating environment that encourages interdisciplinary collaboration and empowers researchers to push the boundaries of knowledge.
                                                    Whether you are an established researcher or an aspiring graduate student, we invite you to join us on an exciting journey of exploration and innovation.
                                                </p>
                                                <p style={{ marginTop: '20px' }} >
                                                    <h3>Our Vision:</h3>
                                                    At the IT, Research, and Innovation Office, our vision is to be at the forefront of research and innovation, making significant contributions to knowledge creation and technological advancements.
                                                    We strive to nurture a culture of excellence, where researchers from various disciplines converge to address complex challenges and seize opportunities that positively impact society as a whole.
                                                </p>
                                                <p style={{ marginTop: '30px', marginBottom: '20px' }} >
                                                    <h3 style={{ marginBottom: '20px' }}>Key Features of Our Office:</h3>
                                                    <ol>
                                                        <li>
                                                        Interdisciplinary Approach: We embrace the power of multidisciplinary research, recognising that breakthroughs often occur at the intersections of different fields. By fostering collaborations between diverse disciplines, we encourage the exchange of ideas, methodologies, and perspectives, enabling researchers to tackle complex problems from various angles.
                                                        </li>

                                                        <li>
                                                        Collaborative Research Projects: We actively promote collaborative research projects that involve both internal and external partners. Through partnerships with industry, government agencies, and other academic institutions, we create opportunities for researchers to translate their findings into real-world applications, fostering innovation and driving economic growth.

                                                        </li>

                                                        <li>
                                                        Funding and Support: We understand the importance of funding and support for researchers to excel in their endeavors. Our office assists researchers in identifying and securing funding opportunities, whether through internal grants, external funding agencies, or industry partnerships. Additionally, we offer mentorship and guidance to early-career researchers, providing them with the necessary tools and support to succeed.

                                                        </li>

                                                        <li>
                                                         Knowledge Exchange and Outreach: We believe in the power of knowledge exchange and the dissemination of research findings to create a lasting impact. Our office organises seminars, workshops, and conferences, providing a platform for researchers to share their insights and connect with peers. Furthermore, we encourage community engagement and public outreach initiatives, aiming to bridge the gap between academia and society.
                                                        </li>

                                                    </ol>

                                                </p>

                                            </span>
                                        </div>
                                    </div>

                                    <HRLine />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <ResearchAreas hideBanner />
        </>;
    }
}

export default ResearchAtBazeUniversity;
