import React from "react";
import Banner from "../utils/banner/banner";
import HRLine from "../../components/utils/hrline";
import ResearchBanner from "./img/RESEARCH NEWS.png";
import { ResearchNewsData } from "./researchNews";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function ResearchNews() {
    return (
        <>
            <Banner image={ResearchBanner} title="Research News" caption="" disableTitle />
            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                            <h2>Research News</h2>
                            <HRLine />

                            <Accordion defaultExpanded>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <div className="hr_card_flex_container">
                                                <img className="img-circle" src={ResearchNewsData[0].image} style={{ width: "80px" }} />
                                                <span style={{ marginLeft: "20px" }}> <h3>{ResearchNewsData[0].name}</h3> <h5>Area: {ResearchNewsData[0].area}</h5> <h5>Title: {ResearchNewsData[0].title}</h5></span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p dangerouslySetInnerHTML={{ __html: ResearchNewsData[0].abstract }} />
                                        </AccordionDetails>
                                    </Accordion>

                            <div className="row">
                                    {
                                        ResearchNewsData.length > 0 && ResearchNewsData.slice(1).map((item, index) => {
                                            return (
                                                <div className="col-md-6" style={{ marginTop: "10px" }} key={index}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel2a-content"
                                                            id="panel2a-header"
                                                        >
                                                            <div className="hr_card_flex_container">
                                                                <img className="img-circle" src={item.image} style={{ width: "80px" }} />
                                                                <span style={{ marginLeft: "20px" }}><h4>{item.name}</h4> <h5>Area: {item.area}</h5> <h5>Title: {item.title}</h5> </span>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <p dangerouslySetInnerHTML={{ __html: item.abstract }} />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            )
                                        })
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
