import React from "react";
import Banner from "../../utils/banner/banner";
import "../../admission/application-guide/application-guide.css"
import 'react-data-table-component-extensions/dist/index.css';
import { serverLink } from "../../../resources/url";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import ThesisBanner from '../../resources/student-thesis/thesis.jpeg'
import Loader from "react-loader-spinner";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { toTitleCase } from "../../../resources/constants";
import { NavLink } from "react-router-dom";


const StudentThesis = (props) => {
    const [isLoading, setisLoading] = useState(true);
    const [thesisList, setThesisList] = useState([]);
    const [thesisUpdate, setUpdate] = useState([]);
    const [thesisBackupList, setthesisBackupList] = useState([])
    const [byHit, setByHit] = useState([])

    const cols = [
        {
            name: "S/N",
            selector: (row, index) => `${index + 1}`,
            sortable: true,
            width: "7%"
        },
        {
            name: "Name",
            selector: row => `${toTitleCase(row.fullname.toString().trim())}`,
            cell: (row) => (

                <div>
                    <span data-toggle="tooltip" title={toTitleCase(row.fullname.toString().trim())}>
                        {toTitleCase(row.fullname.toString().trim())}
                    </span>
                </div>
            ),
            sortable: true,
            width: '15%'
        },
        {
            name: "Title",
            selector: row => `${toTitleCase(row.ThesisTitle.toString().trim())}`,
            cell: (row) => (
                <a onClick={() => { UpdateDownloadCount(row) }} target={"_blank"} href={`https://portal.bazeuniversity.edu.ng/student${row.ThesisFile.split("..")[1]}`} >
                    <span data-toggle="tooltip" title={toTitleCase(row.ThesisTitle.toString().trim())}>
                        {toTitleCase(row.ThesisTitle.toString().trim())}
                    </span>
                </a >
            ),
            sortable: true,
            width: '40%'
        },
        {
            name: "Supervisors(s)",
            selector: row => `${toTitleCase(row.supervisors.toString().trim())}`,
            cell: (row) => (
                <span data-toggle="tooltip" title={row.supervisors.toString().trim()}>
                    {toTitleCase(row.supervisors.toString().trim())}
                </span>
            ),
            sortable: true,
            width: '20%'
        },
        {
            name: "External Examiner",
            selector: row => `${toTitleCase(row.ExternalExaminer.toString().trim())}`,
            cell: (row) => (
                <span data-toggle="tooltip" title={row.ExternalExaminer.toString().trim()}>
                    {toTitleCase(row.ExternalExaminer.toString().trim())}
                </span>
            ),
            sortable: true,
        },
        {
            name: "Downloads",
            selector: row => parseInt(row.DownloadCount.toString().trim()),
            cell: (row) => (
                <div>
                    <span data-toggle="tooltip" title="Views">
                        <i className="fa fa-download" data-toggle="tooltip" title="Views" />
                        &nbsp;{parseInt(row.DownloadCount)}
                    </span>
                </div>
            ),
            sortable: true
        },
    ];

    const [data2, setData2] = useState([])

    useEffect(() => {
        getStudentThesis()
    }, [])
    const getStudentThesis = async () => {
        await axios.get(`${serverLink}resources/student_thesis/list`)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    setThesisList(data)
                    setUpdate(data)
                    setData2(data)
                    setthesisBackupList(data)
                }
            })

        await axios.get(`${serverLink}resources/student_thesis/byhit`)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    setByHit(data)
                }
                setisLoading(false)
            })
    }

    const UpdateDownloadCount = async (item) => {
        await axios.get(`${serverLink}resources/student_thesis/updateCount/${item.EntryID}`)
            .then(result => {
                if (result.data.message === "success") {

                }
                axios.get(`https://portal.bazeuniversity.edu.ng/student${item.ThesisFile.split("..")[1]}`).then((res) => {
                    console.log(res)
                })
            })
    }
    const handleSearch = (e) => {
        const filtered = thesisUpdate.filter(x =>
            x.ExternalExaminer.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) ||
            x.supervisors.toString().toLocaleLowerCase().includes(e.target.value.toString().toLocaleLowerCase()) ||
            x.ThesisTitle.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) ||
            x.fullname.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())
        )
        setThesisList(filtered);
        setData2(filtered)
    }

    const byFaculty = (e) => {
        const filtered = thesisUpdate.filter(x => x.StudentID.split('/')[2].toLocaleLowerCase() === e.toLocaleLowerCase())
        setThesisList(filtered);
        setData2(filtered)
    }
    const [tableData, setTableData] = useState({})
    useEffect(() => {
        const columns = cols;
        const data = data2.sort((a, b) => a.ThesisTitle !== b.ThesisTitle ? a.ThesisTitle < b.ThesisTitle ? -1 : 1 : 0)
        setTableData({
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search',

        })
    }, [data2])

    const getFacultyPubCount = (e) => {
        const filtered = thesisBackupList.filter(j => j.StudentID.split('/')[2].toString().toLowerCase() === e.toString().toLowerCase());
        return filtered.length;
    }

    return isLoading === true ?
        <>
            <Loader
                type="ThreeDots"
                color="#63656A"
                height={200}
                width={200}
                className="text-center"
            /></>

        : <>
            <Banner image={ThesisBanner} title="Student Thesis" caption="Research made by our students" />

            <div className="container-fluid layout col1" style={{ margin: "10px" }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="layout-blocks-ucws-checklist container-fluid roundable block checklist">
                            <div className="row">
                                <div className="">
                                    <div className="row">

                                        <div className="col-lg-12">

                                            <div className="layout-col col1 layout-12 col-sm-12">
                                                <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                                    <div style={{ padding: '8px', borderRadius: '5px' }}>
                                                        <input name="search" placeholder="search" style={{ width: '100%' }} className="textBox" onChange={handleSearch} />
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <DataTableExtensions
                                                                {...tableData}
                                                            >
                                                                <DataTable
                                                                    noHeader
                                                                    defaultSortField="Name"
                                                                    defaultSortAsc={false}
                                                                    pagination
                                                                    paginationPerPage={100}
                                                                    highlightOnHover />
                                                            </DataTableExtensions>
                                                            {/* <div className="text-left">
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered">
                                                                        <tbody>
                                                                            <tr style={{ background: 'grey', color: 'white' }}>
                                                                                <td><h5>S/N</h5></td>
                                                                                <td><h5>Student name</h5></td>
                                                                                <td><h5>Project title</h5></td>
                                                                                <td><h5>Supervisors</h5></td>
                                                                                <td><h5>External Supervisors</h5></td>
                                                                                <td><h5>Hit</h5></td>
                                                                            </tr>
                                                                            {thesisList.length > 0 ?
                                                                                <>
                                                                                    {thesisList.map((x, y) => {
                                                                                        return (
                                                                                            <tr key={y}>
                                                                                                <td><h5>{y + 1}</h5></td>
                                                                                                <td><h5>{x.fullname}</h5></td>
                                                                                                <td><h5>{x.ThesisTitle}</h5></td>
                                                                                                <td><h5>{x.supervisors}</h5></td>
                                                                                                <td><h5>{x.ExternalExaminer}</h5></td>
                                                                                                <td><h5><i className="fa fa-download" />&nbsp;{x.DownloadCount}</h5></td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </>
                                                                                : <>
                                                                                    <tr>
                                                                                        <td><h4>No Thesis/publication</h4></td>
                                                                                    </tr>
                                                                                </>}
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div> */}
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div style={{ background: '#f4f4f4', padding: '8px', borderRadius: '5px' }}>
                                                                <h4>Faculties</h4>
                                                                <ul style={{ listStyle: 'circle' }}>

                                                                    {props.facultyList.length > 0 ?
                                                                        <>
                                                                            {props.facultyList.map((x, y) => {
                                                                                return getFacultyPubCount(x.FacultyCode) > 0 ? (
                                                                                    <li onClick={() => {
                                                                                        byFaculty(x.FacultyCode)
                                                                                    }} key={y}>
                                                                                        <a style={{ cursor: 'pointer' }}>
                                                                                            {x.FacultyName}
                                                                                            &nbsp;({getFacultyPubCount(x.FacultyCode)})
                                                                                        </a>
                                                                                    </li>
                                                                                ) : <></>
                                                                            })}
                                                                        </>
                                                                        : <></>}
                                                                </ul>
                                                            </div>

                                                            <hr />
                                                            <div style={{ background: '#eeeeee', padding: '8px', borderRadius: '5px' }}>
                                                                <h4>Most Downloaded</h4>
                                                                <ul style={{ listStyle: 'circle' }}>
                                                                    {byHit.length > 0 ?
                                                                        <>
                                                                            {
                                                                                byHit.map((x, y) => {
                                                                                    return (
                                                                                        <li key={y} ><a target='_blank' href={`https://portal.bazeuniversity.edu.ng/student${x.ThesisFile.split("..")[1]}`}>{toTitleCase(x.ThesisTitle)}&nbsp;
                                                                                            <small>({x.DownloadCount} downloads)</small>
                                                                                        </a></li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                        : <>
                                                                            No publications</>}
                                                                </ul>
                                                            </div>

                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                <div className="row">
                    <div className="row">
                        <div className="col-sm-12">
                            <hr className="solid light-grey" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid layout col1">
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-info-shim container-fluid roundable block info-shim">
                            <div className="row">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="more-info-line">
                                                <div className="more-info-icon">
                                                    <span className="icon icomoon ucws-info-shim-info light-grey"
                                                        aria-hidden="true"></span> Did you know Baze University Admission system is always open?
                                                    <a href="https://portal.bazeuniversity.edu.ng/admission"
                                                        target="_blank" rel="noopener"> Apply here</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>

}
const mapStateToProps = (state) => {
    return {
        facultyList: state.BazeFacultyListDetails,
    };
};


export default connect(mapStateToProps, null)(StudentThesis)

