

export const InterComList = [
    {
        "sn": 1,
        "ExtensionNo": 1009,
        "Office": "DVC (ADMIN) 1",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A37"
    },
    {
        "sn": 2,
        "ExtensionNo": 1122,
        "Office": "DVC (ADMIN) 2",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A34"
    },
    {
        "sn": 3,
        "ExtensionNo": 1010,
        "Office": "SEC TO DVC (ADMIN)",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A34"
    },
    {
        "sn": 4,
        "ExtensionNo": 1004,
        "Office": "SSA TO PRO-CHANCELLOR",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A37"
    },
    {
        "sn": 5,
        "ExtensionNo": 1078,
        "Office": "DVC IT, RESEARCH & INN. (CONTROL)",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A34"
    },
    {
        "sn": 6,
        "ExtensionNo": 1133,
        "Office": "DVC IT, RRSEARCH & INN.(MAIN)",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A34"
    },
    {
        "sn": 7,
        "ExtensionNo": 1035,
        "Office": "SEC TO DVC IT, R&I",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A34"
    },
    {
        "sn": 8,
        "ExtensionNo": 1049,
        "Office": "DIRECTOR CENTRAL SERVICES",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A35"
    },
    {
        "sn": 9,
        "ExtensionNo": 1121,
        "Office": "DIRECTOR STRATEGIC LINKAGES (DR HAKEEM)",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A35"
    },
    {
        "sn": 10,
        "ExtensionNo": 1031,
        "Office": "SEC TO DIRECTOR STRATEGIC LINKAGES",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A35"
    },
    {
        "sn": 11,
        "ExtensionNo": 1023,
        "Office": "REGISTRAR",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A04"
    },
    {
        "sn": 12,
        "ExtensionNo": 1155,
        "Office": "REGISTRAR (DR MANI)",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A04"
    },
    {
        "sn": 13,
        "ExtensionNo": 1045,
        "Office": "REGISTRAR'S SECRETARY",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A01"
    },
    {
        "sn": 14,
        "ExtensionNo": 1100,
        "Office": "SA TO REGISTRAR",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A04"
    },
    {
        "sn": 15,
        "ExtensionNo": 1002,
        "Office": "BURSAR",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A06"
    },
    {
        "sn": 16,
        "ExtensionNo": 1109,
        "Office": "SECRETARY TO BURSAR",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A06"
    },
    {
        "sn": 17,
        "ExtensionNo": 1008,
        "Office": "ACADEMIC PLANNING",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A32"
    },
    {
        "sn": 18,
        "ExtensionNo": 1061,
        "Office": "ACCOUNTANT",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A08"
    },
    {
        "sn": 19,
        "ExtensionNo": 1091,
        "Office": "ACCOUNTING & FINANCE",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A11"
    },
    {
        "sn": 20,
        "ExtensionNo": 1124,
        "Office": "ADU",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A23"
    },
    {
        "sn": 21,
        "ExtensionNo": 1066,
        "Office": "DEAN BUSINESS",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A06"
    },
    {
        "sn": 22,
        "ExtensionNo": 1111,
        "Office": "CO-ORDINATING DIRECTOR ",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A01"
    },
    {
        "sn": 23,
        "ExtensionNo": 1016,
        "Office": "ECONOMIC LECTURERS",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A19"
    },
    {
        "sn": 24,
        "ExtensionNo": 1064,
        "Office": "FACULTY OFFICER (MANAGEMENT)",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A06"
    },
    {
        "sn": 25,
        "ExtensionNo": 1094,
        "Office": "FINANCE/AUDIT",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A08"
    },
    {
        "sn": 26,
        "ExtensionNo": 1076,
        "Office": "GENERAL STUDIES",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A25"
    },
    {
        "sn": 27,
        "ExtensionNo": 1012,
        "Office": "HOD ACCOUNTING & FINANCE",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A39"
    },
    {
        "sn": 28,
        "ExtensionNo": 1075,
        "Office": "HOD GOVT. & PUBLIC ADMINISTRATION",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A40"
    },
    {
        "sn": 29,
        "ExtensionNo": 1065,
        "Office": "HOD IRD",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A02"
    },
    {
        "sn": 30,
        "ExtensionNo": 1033,
        "Office": "HOD PUBLIC ADMIN",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A33"
    },
    {
        "sn": 31,
        "ExtensionNo": 1037,
        "Office": "IRD LECTURERS",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A18"
    },
    {
        "sn": 32,
        "ExtensionNo": 1124,
        "Office": "IT OFFICE",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A24"
    },
    {
        "sn": 33,
        "ExtensionNo": 1007,
        "Office": "IT OFFICE",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A24"
    },
    {
        "sn": 34,
        "ExtensionNo": 1081,
        "Office": "IT SERVICE DESK 1",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A24"
    },
    {
        "sn": 35,
        "ExtensionNo": 1152,
        "Office": "IT SERVICE DESK 2",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A24"
    },
    {
        "sn": 36,
        "ExtensionNo": 1160,
        "Office": "DEVELOPER (IT)",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A21"
    },
    {
        "sn": 37,
        "ExtensionNo": 1149,
        "Office": "IT NOC",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A21"
    },
    {
        "sn": 38,
        "ExtensionNo": 1032,
        "Office": "IT OFFICE",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A21"
    },
    {
        "sn": 39,
        "ExtensionNo": 1157,
        "Office": "IT DEPT (ABBA)",
        "Block": "MULTI PURPOSE BUILDING",
        "RoomNo": "MPH"
    },
    {
        "sn": 40,
        "ExtensionNo": 1108,
        "Office": "MANAGEMENT LECTURERS",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A20"
    },
    {
        "sn": 41,
        "ExtensionNo": 1029,
        "Office": "PA TO PROCHANCELLOR",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A32"
    },
    {
        "sn": 42,
        "ExtensionNo": 1006,
        "Office": "SECURITY MAIN RECEPTION",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A0"
    },
    {
        "sn": 43,
        "ExtensionNo": 1014,
        "Office": "SOCIAL SCIENCE LIBRARY (MUTALLAB)",
        "Block": "BLOCK A (AHMADU ABUBAKAR BUILDING)",
        "RoomNo": "A10"
    },
    {
        "sn": 44,
        "ExtensionNo": 1062,
        "Office": "DEAN OF COMPUTING",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B27"
    },
    {
        "sn": 45,
        "ExtensionNo": 1063,
        "Office": "SEC. TO DEAN OF COMPUTING",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B26"
    },
    {
        "sn": 46,
        "ExtensionNo": 1030,
        "Office": "BLOCK B LIBRARY (HASSAN TUKUR)",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B22"
    },
    {
        "sn": 47,
        "ExtensionNo": 1038,
        "Office": "CHEMISTRY OFFICE",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B13"
    },
    {
        "sn": 48,
        "ExtensionNo": 1034,
        "Office": "HOD SOCIOLOGY AND ANTHROPOLOGY",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B4"
    },
    {
        "sn": 49,
        "ExtensionNo": 1057,
        "Office": "HOD BIOLOGY",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B59"
    },
    {
        "sn": 50,
        "ExtensionNo": 1090,
        "Office": "HOD BUSINESS MANAGEMENT",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B2"
    },
    {
        "sn": 51,
        "ExtensionNo": 1107,
        "Office": "HOD CHEMISTRY",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B17"
    },
    {
        "sn": 52,
        "ExtensionNo": 1084,
        "Office": "HOD COMPUTER SCIENCE",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B62"
    },
    {
        "sn": 53,
        "ExtensionNo": 1025,
        "Office": "HOD ECONOMICS",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B1"
    },
    {
        "sn": 54,
        "ExtensionNo": 1052,
        "Office": "HOD MASS. COMM.",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B5"
    },
    {
        "sn": 55,
        "ExtensionNo": 1120,
        "Office": "LAB SCIENCE LECTURERS",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B29"
    },
    {
        "sn": 56,
        "ExtensionNo": 1125,
        "Office": "INTERCOM",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B34"
    },
    {
        "sn": 57,
        "ExtensionNo": 1080,
        "Office": "INTERCOM TECNICIAN",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": "B34"
    },
    {
        "sn": 58,
        "ExtensionNo": 1153,
        "Office": "CHEMISTRY LAB",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": " "
    },
    {
        "sn": 59,
        "ExtensionNo": 1156,
        "Office": "PHYSIC  LAB",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": " "
    },
    {
        "sn": 60,
        "ExtensionNo": 1154,
        "Office": "BIOLOGY LAB",
        "Block": "BLOCK B (MICHAEL HODD)",
        "RoomNo": " "
    },
    {
        "sn": 61,
        "ExtensionNo": 1073,
        "Office": "DEAN OF LAW",
        "Block": "BLOCK C (ABIOLA OJO)",
        "RoomNo": "C11"
    },
    {
        "sn": 62,
        "ExtensionNo": 1099,
        "Office": "SECRETARY TO DEAN OF LAW",
        "Block": "BLOCK C (ABIOLA OJO)",
        "RoomNo": "C10"
    },
    {
        "sn": 63,
        "ExtensionNo": 1048,
        "Office": "DR UDOKA",
        "Block": "BLOCK C (ABIOLA OJO)",
        "RoomNo": "C2"
    },
    {
        "sn": 64,
        "ExtensionNo": 1050,
        "Office": "BAZE LAW CLINIC",
        "Block": "BLOCK C (ABIOLA OJO)",
        "RoomNo": "C4"
    },
    {
        "sn": 65,
        "ExtensionNo": 1105,
        "Office": "HOD PUBLIC INTERNATIONAL LAW",
        "Block": "BLOCK C (ABIOLA OJO)",
        "RoomNo": "C8"
    },
    {
        "sn": 66,
        "ExtensionNo": 1060,
        "Office": "HOD COMMERCIAL LAW",
        "Block": "BLOCK C (ABIOLA OJO)",
        "RoomNo": "C16"
    },
    {
        "sn": 67,
        "ExtensionNo": 1039,
        "Office": "HOD PUBLIC & PRIVATE LAW",
        "Block": "BLOCK C (ABIOLA OJO)",
        "RoomNo": "C1"
    },
    {
        "sn": 68,
        "ExtensionNo": 1077,
        "Office": "LAW LECTURERS OFFICE",
        "Block": "BLOCK C (ABIOLA OJO)",
        "RoomNo": "C26"
    },
    {
        "sn": 69,
        "ExtensionNo": 1027,
        "Office": "LAW LIBRARY (UWAIS LIBRARY)",
        "Block": "BLOCK C (ABIOLA OJO)",
        "RoomNo": "C22"
    },
    {
        "sn": 70,
        "ExtensionNo": 1036,
        "Office": "LAW LIBRARY (UWAIS LIBRARY)",
        "Block": "BLOCK C (ABIOLA OJO)",
        "RoomNo": "C22"
    },
    {
        "sn": 71,
        "ExtensionNo": 1089,
        "Office": "MRS IJENMA OKWU",
        "Block": "BLOCK C (ABIOLA OJO)",
        "RoomNo": "C40"
    },
    {
        "sn": 74,
        "ExtensionNo": 1105,
        "Office": "HOD PUBLIC&INTERNATIONAL LAW",
        "Block": "BLOCK C (ABIOLA OJO)",
        "RoomNo": "C8"
    },
    {
        "sn": 73,
        "ExtensionNo": 1092,
        "Office": "UNIVERSITY LIBRARIAN",
        "Block": "BLOCK C (ABIOLA OJO)",
        "RoomNo": "C25"
    },
    {
        "sn": 74,
        "ExtensionNo": 1070,
        "Office": "IT MAINTENACE DESK",
        "Block": "BLOCK C (ABIOLA OJO)"
    },
    {
        "sn": 75,
        "ExtensionNo": 1151,
        "Office": "DVC ACADEMIC",
        "Block": "BLOCK D (ROSE OKWECHIME)",
        "RoomNo": "D41"
    },
    {
        "sn": 76,
        "ExtensionNo": 1105,
        "Office": "DVC ACADEMIC OFFICE",
        "Block": "BLOCK D (ROSE OKWECHIME)",
        "RoomNo": "D41"
    },
    {
        "sn": 77,
        "ExtensionNo": 1139,
        "Office": "DEPUTY DEAN ENGINEERING",
        "Block": "BLOCK D (ROSE OKWECHIME)",
        "RoomNo": "D21"
    },
    {
        "sn": 78,
        "ExtensionNo": 1115,
        "Office": "SEC TO DVC(ACADEMIC)",
        "Block": "BLOCK D (ROSE OKWECHIME)",
        "RoomNo": "D41"
    },
    {
        "sn": 79,
        "ExtensionNo": 1114,
        "Office": "DEAN OF ENGINEERING",
        "Block": "BLOCK D (ROSE OKWECHIME)",
        "RoomNo": "D17"
    },
    {
        "sn": 80,
        "ExtensionNo": 1103,
        "Office": "SECRETARY TO DEAN OF ENGINEERING",
        "Block": "BLOCK D (ROSE OKWECHIME)",
        "RoomNo": "D14"
    },
    {
        "sn": 81,
        "ExtensionNo": 1146,
        "Office": "HOD ELECTRICAL ",
        "Block": "BLOCK D (ROSE OKWECHIME)",
        "RoomNo": "D25"
    },
    {
        "sn": 82,
        "ExtensionNo": 1147,
        "Office": "HOD CIVIL ENGINEERING",
        "Block": "BLOCK D (ROSE OKWECHIME)",
        "RoomNo": "D20"
    },
    {
        "sn": 83,
        "ExtensionNo": 1148,
        "Office": "HOD PETROLEUM AND GAS",
        "Block": "BLOCK D (ROSE OKWECHIME)",
        "RoomNo": "D21"
    },
    {
        "sn": 84,
        "ExtensionNo": 1098,
        "Office": " BLOCK D ENGINEERING LIBRARY",
        "Block": "BLOCK D (ROSE OKWECHIME)",
        "RoomNo": "D33"
    },
    {
        "sn": 85,
        "ExtensionNo": 1021,
        "Office": "VC 1",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E56"
    },
    {
        "sn": 86,
        "ExtensionNo": 1011,
        "Office": "VC 2",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E59"
    },
    {
        "sn": 87,
        "ExtensionNo": 1001,
        "Office": "SEC TO THE VC",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E55"
    },
    {
        "sn": 88,
        "ExtensionNo": 1019,
        "Office": "SEC TO VC",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E58"
    },
    {
        "sn": 89,
        "ExtensionNo": 1018,
        "Office": "SA TO VC",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E57"
    },
    {
        "sn": 90,
        "ExtensionNo": 1141,
        "Office": "DEAN (MEDICAL)",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E56"
    },
    {
        "sn": 91,
        "ExtensionNo": 1140,
        "Office": "SEC TO DEAN MEDICAL",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E56"
    },
    {
        "sn": 92,
        "ExtensionNo": 1150,
        "Office": "PROVOST",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E38"
    },
    {
        "sn": 93,
        "ExtensionNo": 1189,
        "Office": "SEC TO PROVOST",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E38"
    },
    {
        "sn": 94,
        "ExtensionNo": 1138,
        "Office": "DIRECTOR (CFIS)",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E42"
    },
    {
        "sn": 95,
        "ExtensionNo": 1142,
        "Office": "DR ABDUL ZUBAIR",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E47"
    },
    {
        "sn": 96,
        "ExtensionNo": 1194,
        "Office": "FACAULTY OF MEDICAL SCIENCE",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E57"
    },
    {
        "sn": 97,
        "ExtensionNo": 1193,
        "Office": "PHYSIOLOGY",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E60"
    },
    {
        "sn": 98,
        "ExtensionNo": 1201,
        "Office": "HOD ANATOMY",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E61"
    },
    {
        "sn": 99,
        "ExtensionNo": 1198,
        "Office": "ANATOMY OFFICE",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E46"
    },
    {
        "sn": 100,
        "ExtensionNo": 1186,
        "Office": "GENERAL STUDIES",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E44"
    },
    {
        "sn": 101,
        "ExtensionNo": 1196,
        "Office": "HOD BIOLOGY",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E59"
    },
    {
        "sn": 102,
        "ExtensionNo": 1200,
        "Office": "GENERAL OFFICE MATHMATICS",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E63"
    },
    {
        "sn": 103,
        "ExtensionNo": 1187,
        "Office": "BLOCK E MEDICAL LIBRARY",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E50"
    },
    {
        "sn": 104,
        "ExtensionNo": 1044,
        "Office": "DEPT OF RADIOGRAPHY RADIATION SCIENCE"
    },
    {
        "sn": 105,
        "ExtensionNo": 1190,
        "Office": "MATHMATICS DEPT.",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E34"
    },
    {
        "sn": 106,
        "ExtensionNo": 1097,
        "Office": "SECURITY DESK (RECEPTION)",
        "Block": "BLOCK E (JUSTICE ALFA BELGORE)",
        "RoomNo": "E0"
    },
    {
        "sn": 107,
        "ExtensionNo": 1131,
        "Office": "DEAN ENVIROMENTAL SERVICES",
        "Block": "BLOCK F (ALIYU MODIBBO)",
        "RoomNo": "F32-2"
    },
    {
        "sn": 108,
        "ExtensionNo": 1132,
        "Office": "SEC TO DEAN ENVIROMENTAL S",
        "Block": "BLOCK F (ALIYU MODIBBO)",
        "RoomNo": "F32"
    },
    {
        "sn": 109,
        "ExtensionNo": 1126,
        "Office": "DIRECTOR STRATEGY (DR JAMILA SHUARA)",
        "Block": "BLOCK F (ALIYU MODIBBO)",
        "RoomNo": "F69"
    },
    {
        "sn": 110,
        "ExtensionNo": 1130,
        "Office": "HOD (ARCHITECTURE)",
        "Block": "BLOCK F (ALIYU MODIBBO)",
        "RoomNo": "F32 -4"
    },
    {
        "sn": 111,
        "ExtensionNo": 1135,
        "Office": "HOD (BUILDING)",
        "Block": "BLOCK F (ALIYU MODIBBO)",
        "RoomNo": "F11"
    },
    {
        "sn": 112,
        "ExtensionNo": 1137,
        "Office": "HOD (PSYCHOLOGY)",
        "Block": "BLOCK F (ALIYU MODIBBO)",
        "RoomNo": "F73"
    },
    {
        "sn": 113,
        "ExtensionNo": 1128,
        "Office": "HOD (QUANTITY SURVEYING)",
        "Block": "BLOCK F (ALIYU MODIBBO)",
        "RoomNo": "F69"
    },
    {
        "sn": 114,
        "ExtensionNo": 1127,
        "Office": "HOD (SURVEYING &GEOMATIC)",
        "Block": "BLOCK F (ALIYU MODIBBO)",
        "RoomNo": "F06"
    },
    {
        "sn": 115,
        "ExtensionNo": 1134,
        "Office": "HOD (URBAN PLANNING)",
        "Block": "BLOCK F (ALIYU MODIBBO)",
        "RoomNo": "F14"
    },
    {
        "sn": 116,
        "ExtensionNo": 1144,
        "Office": "IT MAINTENACE DESK",
        "Block": "BLOCK F (ALIYU MODIBBO)"
    },
    {
        "sn": 117,
        "ExtensionNo": 1199,
        "Office": "SECURITY DESK (RECEPTION)",
        "Block": "BLOCK F (ALIYU MODIBBO)",
        "RoomNo": "F0 "
    },
    {
        "sn": 118,
        "ExtensionNo": 1026,
        "Office": "MAINTENANCE (CONTAINER) 1",
        "Block": "CONTAINER",
        "RoomNo": "CONTAINER"
    },
    {
        "sn": 119,
        "ExtensionNo": 1068,
        "Office": "MAINTENANCE (CONTAINER) 2",
        "Block": "CONTAINER",
        "RoomNo": "CONTAINER"
    },
    {
        "sn": 120,
        "ExtensionNo": 1095,
        "Office": "MAINTENANCE (CONTAINER) 3",
        "Block": "CONTAINER",
        "RoomNo": "CONTAINER"
    },
    {
        "sn": 121,
        "ExtensionNo": 1047,
        "Office": "MATRON",
        "Block": "HOSTEL (GIRLS)",
        "RoomNo": "GIRLS HOSTEL"
    },
    {
        "sn": 122,
        "ExtensionNo": 1074,
        "Office": "HOSTEL MANAGER",
        "Block": "HOSTEL(BOYS)",
        "RoomNo": "BOYS HOSTEL"
    },
    {
        "sn": 123,
        "ExtensionNo": 1113,
        "Office": "CSO",
        "Block": "MULTI PURPOSE BUILDING",
        "RoomNo": "MPH"
    },
    {
        "sn": 124,
        "ExtensionNo": 1087,
        "Office": "DCSO",
        "Block": "MAIN GATE",
        "RoomNo": "MAIN GATE"
    },
    {
        "sn": 125,
        "ExtensionNo": 1088,
        "Office": "SECURITY MONITORING",
        "Block": "MAIN GATE",
        "RoomNo": "MAIN GATE"
    },
    {
        "sn": 126,
        "ExtensionNo": 1086,
        "Office": "MAIN SECURITY GATE",
        "Block": "MAIN GATE",
        "RoomNo": "MAIN GATE"
    },
    {
        "sn": 127,
        "ExtensionNo": 1028,
        "Office": "SECURITY MPH",
        "Block": "MULTI PURPOSE BUILDING",
        "RoomNo": "MPH"
    },
    {
        "sn": 128,
        "ExtensionNo": 1117,
        "Office": "SECURITY CONTAINER MAIN GATE",
        "Block": "MAIN GATE",
        "RoomNo": "MAIN GATE"
    },
    {
        "sn": 129,
        "ExtensionNo": 1119,
        "Office": "SECURITY CONTAINER  ",
        "Block": "OUTSIDE CONTAINER",
        "RoomNo": "BEHIND MEDICAL"
    },
    {
        "sn": 130,
        "ExtensionNo": 1158,
        "Office": "CCTV MONITORING",
        "Block": "SHOPPING MALL",
        "RoomNo": "SHOP MALL"
    },
    {
        "sn": 131,
        "ExtensionNo": 1058,
        "Office": "HEAD OF HUMAN RESOURCES 1",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R8"
    },
    {
        "sn": 132,
        "ExtensionNo": 1145,
        "Office": "HEAD OF HUMAN RESOURCES  2",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R8"
    },
    {
        "sn": 133,
        "ExtensionNo": 1042,
        "Office": "SEC TO HR",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R9"
    },
    {
        "sn": 134,
        "ExtensionNo": 1106,
        "Office": "EXAM AND RECORDS STAFF 1",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R1"
    },
    {
        "sn": 135,
        "ExtensionNo": 1043,
        "Office": "HR ADMIN ",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R10"
    },
    {
        "sn": 136,
        "ExtensionNo": 1085,
        "Office": "HEAD EXAMS ",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R11"
    },
    {
        "sn": 137,
        "ExtensionNo": 1067,
        "Office": "MR AGHEDO",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R12"
    },
    {
        "sn": 138,
        "ExtensionNo": 1093,
        "Office": "EXAM AND RECORDS STAFF 2",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R18"
    },
    {
        "sn": 139,
        "ExtensionNo": 1024,
        "Office": "EXAM AND RECORDS STAFF 3",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R19"
    },
    {
        "sn": 140,
        "ExtensionNo": 1054,
        "Office": "STUDENT RECORD",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R2"
    },
    {
        "sn": 141,
        "ExtensionNo": 1079,
        "Office": "EXAM AND RECORDS STAFF 4",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R4"
    },
    {
        "sn": 142,
        "ExtensionNo": 1104,
        "Office": "STUDENT AFFAIRS",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R5"
    },
    {
        "sn": 143,
        "ExtensionNo": 1015,
        "Office": "HR ADMIN ",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R6"
    },
    {
        "sn": 144,
        "ExtensionNo": 1055,
        "Office": "EXAM AND RECORDS STAFF 4",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R7"
    },
    {
        "sn": 145,
        "ExtensionNo": 1040,
        "Office": "STUDENTS AFFAIRS ",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R9"
    },
    {
        "sn": 146,
        "ExtensionNo": 1041,
        "Office": "RECEPTION/SECURITY",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R0"
    },
    {
        "sn": 147,
        "ExtensionNo": 1154,
        "Office": "AUDITOR",
        "Block": "REGISTRY (E.A.O SHONEKAN)",
        "RoomNo": "R13"
    },
    {
        "sn": 148,
        "ExtensionNo": 1162,
        "Office": "CMD ",
        "Block": "BAZE HOSPITAL",
        "RoomNo": " "
    },
    {
        "sn": 149,
        "ExtensionNo": 1163,
        "Office": "DOA",
        "Block": "BAZE HOSPITAL",
        "RoomNo": " "
    },
    {
        "sn": 150,
        "ExtensionNo": 1165,
        "Office": "STUDENT SUPPORT  UNIT",
        "Block": "STAFF QTRS",
        "RoomNo": "STAFF QTRS"
    }

]