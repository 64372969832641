import React, {Component} from "react";

class Googlemap extends Component {
    render() {
        return <iframe
            title="Baze University Campus Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.617368003466!2d7.403794115235554!3d9.007310491807262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e7372e355f317%3A0x7f90ec48624fd5b4!2sBaze%20University!5e0!3m2!1sen!2sng!4v1649687000919!5m2!1sen!2sng"
            width="100%"
            height={this.props.height}
            style={{ border:"0" }} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"/>;
    }
}

export default Googlemap;
