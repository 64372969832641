const status = "Live";

export const serverStatus = status;
export const serverLink =
    status === "Dev"
        ? "http://192.168.1.72:4480/"
        : "https://api.bazeuniversity.edu.ng:5001/";


export const _assets = "https://portal.bazeuniversity.edu.ng/staff/"

