import React from "react";
import Banner from "../utils/banner/banner";
import 'react-data-table-component-extensions/dist/index.css';
import { serverLink } from "../../resources/url";
import axios from "axios";
import { connect } from "react-redux";
import { setStaffProfileDetails } from '../../actions/setactiondetailsection'
import { useState } from "react";
import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import './search.css'
import Loader from "react-loader-spinner";
import banner from './workshop.jpg'

import 'react-data-table-component-extensions/dist/index.css';
import { decryptData, formatDateAndTime, toTitleCase } from "../../resources/constants";

const WorkShops = (props) => {
    const [isLoading, setIsloading] = useState(true)
    const [Events, setEvents] = useState([]);
    const [Type, setType] = useState('Events')

    const getRecords = async () => {
        await axios.get(`${serverLink}event/list/active`)
            .then(result => {
                setEvents(result.data);
                setIsloading(false)
            })
            .catch(e => {
                console.log('NEWS FETCH NETWORK ERROR')
            })
    }

    useEffect(() => {
        getRecords();
    }, [""])

    const onChangeType = (type) => {
        if (type === "ITRI Newsletter") {
            setType(type)
            setShowNewsLetter(true)
        }

    }

    const [showNewsLetter, setShowNewsLetter] = useState(false)

    return isLoading === true ?
        <>
            <Loader
                type="ThreeDots"
                color="#63656A"
                height={200}
                width={200}
                className="text-center"
            />
        </> : <>
            <Banner image={banner} title="Workshops" caption="" />

            <div className="container-fluid layout col1" >
                <div className="row">
                    <div className="col-sm-12">
                        <div className="layout-blocks-ucws-checklist container-fluid roundable block checklist">
                            <div className="row">

                                <div className="col-lg-12">
                                    <div className="layout-col col1 layout-12 col-md-12">
                                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                            <div className="row">
                                                <br />
                                                <div className="col-md-3">
                                                    <div style={{ background: '#f4f4f4', padding: '8px', borderRadius: '5px' }}>
                                                        <ul className="tabs">
                                                            <a href="#" onClick={() => { onChangeType('Trainings & Workshops') }} >
                                                                <li>
                                                                    Trainings & Workshops
                                                                    <i className="fa fa-angle-right pull-right icons" />
                                                                </li>
                                                            </a>
                                                            <a href="#" onClick={() => { onChangeType('Grant Opportunities') }} >
                                                                <li>
                                                                    Grant Opportunities
                                                                    <i className="fa fa-angle-right pull-right icons" />
                                                                </li>
                                                            </a>
                                                            <a href="#" onClick={() => { onChangeType('Staff Capacity Development') }}>
                                                                <li>
                                                                    Staff Capacity Development
                                                                    <i className="fa fa-angle-right pull-right icons" />
                                                                </li>
                                                            </a>
                                                            <a href="#" onClick={() => { onChangeType('Webinar Recordings') }} >
                                                                <li>
                                                                    Webinar Recordings
                                                                    <i className="fa fa-angle-right pull-right icons" />
                                                                </li>
                                                            </a>
                                                            <a href="#" onClick={() => { onChangeType('ITRI Newsletter') }} >
                                                                <li>
                                                                    ITR & I Newsletter                                                                    <i className="fa fa-angle-right pull-right icons" />
                                                                </li>
                                                            </a>


                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-9" style={{ paddingTop: '0px' }}>
                                                    {
                                                        showNewsLetter === true &&
                                                        <div className=" row m-0 search-container" style={{ borderTop: '1px solid black' }}>
                                                            <span className="bg-primary pull-right">{Type}</span>

                                                            <div className="col-md-12 search-card">
                                                                <h4 className="title text-capitalize">
                                                                    ITR & I NewsLetter | Vol. 1,  Issue 1
                                                                </h4>
                                                                <small className="links">
                                                                    <a target={"_blank"} className="links" href={`https://drive.google.com/drive/folders/1PCV6dxVSz2VPOIaQbw-1l63pRweo3pHH`} >
                                                                        ITR & I NewsLetter
                                                                    </a>
                                                                </small>
                                                                <br />
                                                            </div>

                                                            <div className="col-md-12 search-card">
                                                                <h4 className="title text-capitalize">
                                                                    ITR & I NewsLetter | Vol. 1,  Issue 2
                                                                </h4>
                                                                <small className="links">
                                                                    <a target={"_blank"} className="links" href={`https://drive.google.com/drive/folders/1eADPwiwY2MEOisT16MXpogrB_jQyJI2f`} >
                                                                        ITR & I NewsLetter
                                                                    </a>
                                                                </small>
                                                                <br />
                                                            </div>
                                                        </div>
                                                    }



                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container-fluid layout col1">
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-info-shim container-fluid roundable block info-shim">
                            <div className="row">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="more-info-line">
                                                <div className="more-info-icon">
                                                    <span className="icon icomoon ucws-info-shim-info light-grey"
                                                        aria-hidden="true"></span> Did you know Baze University Admission system is always open?
                                                    <a href="https://portal.bazeuniversity.edu.ng/admission"
                                                        target="_blank" rel="noopener"> Apply here</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
}
const mapStateToProps = (state) => {
    return {
        staffList: state.BazeStaffListDetails,
        facultyList: state.BazeFacultyListDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnStaffProfileDetails: (p) => {
            dispatch(setStaffProfileDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkShops);
