import React, {Component} from "react";
import "./baze-in-the-press-single.css"
import NewsLogo from "../../../images/news/news.jpg";

class BazeInThePressSingle extends Component {

    render() {
        return <div className="col-sm-12 col-md-12 col-lg-6 chicklet red-back" style={{ marginBottom: "10px" }}>

            <div className="chicklet-image red-back"
                 style={{backgroundImage: `url('${NewsLogo}')`}}>
                <p className="h2">{this.props.news[0].value}</p>
            </div>
            <div className="chicklet-content red-back">
                <div className="content-wrapper">
                    <p className="h2 accent-border">{this.props.news[0].value}</p>
                    <p>{this.props.news[3].value}</p>

                    <a href={this.props.news[1].value} rel="noreferrer noopener" target="_blank">
                        Read more
                    </a>


                </div>
            </div>

        </div>

    }

}



export default BazeInThePressSingle;

