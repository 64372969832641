import React, { useState } from 'react'
import Banner from '../utils/banner/banner'
import HRLine from '../utils/hrline'
import axios from 'axios'
import { _assets, serverLink } from "../../resources/url";
import { useEffect } from 'react';
import DOMPurify from 'dompurify';
import { Link, useLocation, useParams } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { isMobile } from 'react-device-detect';
import { titleCase } from 'title-case';
import "./clubs.css"
import Loader from 'react-loader-spinner';
import { formatDate, formatDateAndTime } from '../../resources/constants';


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1850 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 1850, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};


export default function ClubsDetails() {
    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [clubDetails, setclubDetails] = useState([])
    const [leadershipList, setLeadershipList] = useState([])
    const [newsList, setNewsList] = useState([]);
    const [eventList, setEventList] = useState([])
    const { search } = useLocation();
    const queryString = search.split("=")[1]


    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            const { data } = await axios.get(`${serverLink}general/website/societies/${queryString}`)
            setclubDetails(data.club);
            setLeadershipList(data.leadership)
            setNewsList(data.news);
            setEventList(data.events)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }


    return isLoading ? (<Loader />) : (
        <div>
            <Banner image={require("../../images/general/Publications.jpeg")} title={clubDetails.length > 0 ? clubDetails[0].Name : ""} />
            {
                clubDetails.length > 0 &&
                <div className="row delay-show" style={{ margin: "20px" }}>
                    <div className="col-md-9 col-sm-12 col-lg-9">
                        <div className=" text-justify">
                            <div className="row header">
                                <div className="col-md-3 block_quate text-center">
                                    <img src={_assets + clubDetails[0].Logo} width={"170px"} />
                                    <hr />
                                    <a className='btn btn-sm btn-primary mt-2' href="https://portal.bazeuniversity.edu.ng/student/" target='_blank'>
                                        Join Us Now
                                    </a>

                                    <div>
                                        <h5>Office Address</h5>
                                        <div>{clubDetails[0].OfficeAddress}</div>
                                    </div>
                                    <hr />
                                    <div>
                                        <i className='fa fa-envelope-o' />
                                        &emsp;<a target='_blank' href={clubDetails[0].OfficeAddress}>{clubDetails[0].OfficeAddress}</a>
                                    </div>
                                    <div>
                                        <i className='fa fa-mobile' />
                                        &emsp;<a target='_blank' href={clubDetails[0].Phone}>{clubDetails[0].Phone}</a>
                                    </div>

                                </div>
                                <div className="col-md-9 ">
                                    <span>
                                        <h3>{clubDetails[0].Name}</h3>
                                        <i><h6>{clubDetails[0].Category}</h6></i>
                                        <br />
                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(clubDetails[0].Description) }} />

                                    </span>
                                </div>
                            </div>

                            <HRLine />
                            {
                                leadershipList.length > 0 &&
                                <>
                                    <h4>Leadership</h4>
                                    <div className="row header">
                                        <div className="col-md-12">
                                            <div class="wrapper_list" >
                                                <div className='section-team_rs'>
                                                    <Carousel
                                                        swipeable={true}
                                                        draggable={false}
                                                        showDots={true}
                                                        responsive={responsive}
                                                        infinite={true}
                                                        autoPlay={isMobile === "mobile" ? true : false}
                                                        autoPlaySpeed={3000}
                                                        keyBoardControl={true}
                                                        customTransition="all .55"
                                                        transitionDuration={500}
                                                        containerClass="carousel-container"
                                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                                        deviceType={isMobile ? "mobile" : "desktop"}
                                                        dotListClass="custom-dot-list-style"
                                                        itemClass="carousel-item-padding-40-px"
                                                    >
                                                        {
                                                            leadershipList.map((x, i) => {
                                                                return (
                                                                    <div className="col-md-12 text-center" key={i}>
                                                                        <div className="single-person">
                                                                            <div className="person-image"
                                                                                style={{ marginBottom: "20px" }}>
                                                                                <img
                                                                                    src={_assets + x.ImagePath}
                                                                                    alt=""
                                                                                    style={{
                                                                                        borderRadius: "50%",
                                                                                        minHeight: "100px",
                                                                                        maxHeight: "250px",
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="person-info mt-5">
                                                                                <p>
                                                                                    <div className="speciality text-decoration-underline"
                                                                                        style={{ textDecoration: "underline", fontWeight: "bold" }}>
                                                                                        {titleCase(x.Title + " " + x.FullName)}
                                                                                    </div>

                                                                                    <span className="speciality mt-3">
                                                                                        <span style={{ marginTop: "-10px" }}>{x.Description}</span> </span>

                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </Carousel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                            }
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12 col-lg-3 delay-news-show'>
                        <div className="col-md-12">
                            <h4>Events</h4>
                            {
                                eventList.length > 0 ?
                                    eventList.map((x, i) => {
                                        return (
                                            <div className='card' key={i}>
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <div>
                                                                <Link to={`/${slug}/event/${x.Slug}?q=${queryString}&e=${x.EntryID}`} style={{ textDecoration: "underline" }} >
                                                                    {x.Title.substr(0, 100)}
                                                                </Link>
                                                            </div>
                                                            <small>
                                                                {formatDateAndTime(formatDate(x.InsertedDate), "date")} | {x.StartTime} - {x.EndTime} | {x.Location}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    < div className='alert alert-info'>
                                        <label>
                                            No events
                                        </label>
                                    </div>
                            }
                        </div>

                        <div className='col-md-12' style={{ marginTop: "100px" }}>
                            <h4>News</h4>
                            {
                                newsList.length > 0 ?
                                    newsList.map((x, i) => {
                                        return (
                                            <div className='card' key={i}>
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        <div className='col-md-3'>
                                                            <img src={_assets + x.ImagePath} />
                                                        </div>
                                                        <div className='col-md-9'>
                                                            <div>
                                                                <Link to={`/${slug}/news/${x.Slug}?q=${queryString}&e=${x.EntryID}`} style={{ textDecoration: "underline" }} >
                                                                    {x.Title.substr(0, 100)}
                                                                </Link>
                                                            </div>
                                                            <small>{formatDateAndTime(formatDate(x.InsertedDate), "date")}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className='alert alert-info'>
                                        <label>
                                            No news
                                        </label>
                                    </div>
                            }
                        </div>

                    </div>

                </div>
            }
        </div>
    )
}
