import React, {useEffect, useState} from "react";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import PageRoutes from "./components/page_routes/page_routes";
import {BrowserRouter as Router, useLocation} from "react-router-dom";

function App (props) {
    return (
        <div className="App">
            <Router>
                <Header />
                <PageRoutes/>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
