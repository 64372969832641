
import React from "react";
import Banner from "../utils/banner/banner";
import Square from "./img/career_2.png";
import HRLine from "../utils/hrline";

function ResearchCareerDevelopment() {
    return <>
        <Banner image={Square} title="Student Support Unit" caption="" disableTitle />
        <div className="container-fluid layout col1" style={{ margin: "20px" }}>
            <div className="row">

                <div className="container-fluid layout col2-50-50">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="layout-col col1 layout-12 col-sm-12">
                                    <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                        <div className="row">
                                            <div className="container text-justify">
                                                <div className="row header">
                                                    <div className="col-sm-12" style={{ backgroundColor: '#f5f5f5', padding: '30px', borderTopLeftRadius: '5px', borderBottomRightRadius: '20px' }}>
                                                        <h2>Ignite Your Path to Success: Career Development at Baze University</h2>
                                                        <span>
                                                            <p>
                                                                Welcome to Baze University, where we believe that education is not just about obtaining a degree, but about cultivating the skills, knowledge, and mindset needed to excel in the real world. Our commitment to your growth extends beyond the classroom, into the realm of career development. At Baze, we don't just prepare you for a job – we empower you to shape your future and thrive in your chosen path. We offer Career Guidance and Counseling, Internships and Experiential Learning, Professional Development Workshops, Networking and Alumni Connections, Global Exposure, Career Fairs and Recruitment Events, Entrepreneurship and Innovation and more.
                                                            </p>
                                                            <p>
                                                                For researchers, Baze University is a fertile ground for innovation and discovery. Our commitment to your career extends to providing resources, funding, and a nurturing environment for your research pursuits. From grant opportunities to state-of-the-art facilities, we fuel your drive to push boundaries and make impactful contributions.
                                                            </p>
                                                        </span>
                                                        <br />

                                                    </div>
                                                </div>

                                                <HRLine />
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p>Embrace your journey, unlock your potential, and shape a future that's uniquely yours. Your career begins now.</p>
                                                        <div className="image-wrapper" style={{borderRadius: '10px' }}>
                                                            <div>
                                                                <img
                                                                    style={{ borderRadius: '10px' }}
                                                                    width="100%"
                                                                    loading="lazy"
                                                                    src={require("./img/research-career.png")}
                                                                    typeof="foaf:Image" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <HRLine />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </>
}

export default ResearchCareerDevelopment;
