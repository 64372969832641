import React from 'react'
import Banner from '../../utils/banner/banner'

export default function StudentSiwes() {
    return (
        <div>
            <Banner image={require("../../../images/general/academics.jpeg")} title="SIWES" caption="" />
            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-text container-fluid roundable block text">
                            <div className="row">
                                <div className="container">
                                    <div className="row left">
                                        <div className="col-sm-12 one-col block_quate text-justify">
                                            <p>The Student Industrial Work Experience Scheme (SIWES) is a crucial component of higher education in
                                                Nigeria, aimed at bridging the gap between theoretical knowledge gained in the classroom and practical
                                                skills required in the workplace. SIWES serves as a platform for students to gain hands-on experience in
                                                their chosen fields, fostering personal and professional development.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid layout col2-50-50">
                <div className="row">
                    <div className="container">
                        <div className="layout-col col1 layout-12 col-sm-12">
                            <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                <div className="row">
                                    <div className="col-md-12">
                                        The following are objectives of the Baze University SIWES:
                                    </div>
                                    <div className="col-md-6" style={{ marginBottom: "2px" }}>
                                        <div className="col-sm-12 one-col block_quate"
                                            style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
                                            <h4>Skill Development</h4>
                                            <p>Provide students with an opportunity to apply classroom knowledge to real-world
                                                situations, helping them acquire practical skills and competencies essential for their future careers.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ marginBottom: "2px" }}>
                                        <div className="col-sm-12 one-col block_quate"
                                            style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
                                            <h4>Industry Exposure</h4>
                                            <p>
                                                Expose students to the actual workings of industries, businesses, and organizations
                                                related to their fields of study, gaining insights into industry practices, trends, and challenges.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-sm-12 one-col block_quate"
                                            style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
                                            <h4>Networking</h4>
                                            <p>
                                                Give students the chance to build a professional network by interacting with industry
                                                professionals, mentors, and peers, which can be valuable for future job opportunities.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-sm-12 one-col block_quate"
                                            style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
                                            <h4>Career Readiness</h4>
                                            <p>
                                                Equip students with workplace etiquette, teamwork, problem-solving, and
                                                communication skills, making them more prepared for their future careers.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid layout col2-50-50">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="layout-col col2-50 layout-6 col-md-6">
                                <div className="layout-blocks-ucws-text container-fluid roundable block text">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row left">
                                                <div className="col-sm-12 one-col text-justify">
                                                    <h4>In line with stated objectives, the Baze University SIWES is enhanced to:</h4>
                                                    <ul style={{ listStyle: "outside" }}>
                                                        <li>
                                                            Help students acquire practical skills, making them more competitive in the job market.
                                                        </li>
                                                        <li>Gain a better understanding of their chosen industries, which can help them make informed
                                                            career decisions.</li>
                                                        <li>Offer opportunities to establish professional relationships and secure potential job offers.</li>
                                                        <li>The experience gained during SIWES can enhance students&#39; academic performance by providing
                                                            real-world context to their studies.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="layout-col col2-50 layout-6 col-md-6">
                                <div className="layout-blocks-ucws-image container-fluid roundable block image">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="image-wrapper">
                                                        <div>
                                                            <img
                                                                style={{ borderRadius: '10px' }}
                                                                height={250}
                                                                width="100%"
                                                                loading="lazy"
                                                                src={require("../../../images/general/siwes.png")}
                                                                typeof="foaf:Image" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div className="container-fluid layout col2-50-50">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <h3>Components of Baze SIWES:</h3>
                            <div className="col-md-6 text-justify">
                                <p><b>Placement:</b> Students are typically placed in organizations related to their fields of study, including
                                    government agencies, private companies, research institutions, and non-profit organizations.</p>
                            </div>
                            <div className="col-md-6 text-justify">
                                <p><b>Duration:</b> SIWES programs vary in duration but typically last between three and six months. Longer
                                    durations provide students with more in-depth experiences.</p>
                            </div>

                            <div className='col-md-12'>
                                <h4>The following are duration schedule for SIWES in Baze University.</h4>
                                <div className='table-responsive'>
                                    <table className='table table-striped'>
                                        <tbody>
                                            <tr>
                                                <td>Faculty of Computing and Applied Sciences</td>
                                                <td>16 Weeks (4 Months)</td>
                                            </tr>
                                            <tr>
                                                <td>Faculty of Management and Social Sciences</td>
                                                <td>16 Weeks (4 Months)</td>
                                            </tr>
                                            <tr>
                                                <td>Faculty of Engineering (SIWES I)</td>
                                                <td>12 Weeks (3 Months)</td>
                                            </tr>
                                            <tr>
                                                <td>Faculty of Engineering (SIWES I)</td>
                                                <td>24 Weeks (6 Months)</td>
                                            </tr>
                                            <tr>
                                                <td>Faculty of Environmental Studies</td>
                                                <td>24 Weeks (6 Months)</td>
                                            </tr>
                                            <tr>
                                                <td>Faculty of Basic Medical Sciences</td>
                                                <td>24 Weeks (6 Months)</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <div className="col-md-12 text-justify">
                                <p><b>Supervision:</b> Students are assigned supervisors or mentors within the host organization to guide and
                                    evaluate their progress during the program. Faculty staff members are also allocated to visit students at
                                    intervals, for supervision while on training to monitor student’s progress.</p>
                            </div>
                            <div className="col-md-12 text-justify">
                                <p><b>Reporting:</b> Students are required to maintain regular reports, detailing their activities, achievements,
                                    and challenges faced during the SIWES period in a logbook.</p>
                            </div>
                            <div className="col-md-12 text-justify">
                                <p><b>Assessment:</b> The performance of students is usually assessed through evaluations conducted by both
                                    their supervisors and academic institutions. These assessments contribute to their overall academic
                                    grades.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
