import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./news-page.css"
import { serverLink } from "../../../resources/url";
import axios from "axios";
import { setNewsDetails, setNewsPageDetails } from "../../../actions/setactiondetailsection";
import Loader from "react-loader-spinner";
import * as DOMPurify from 'dompurify';
import { titleCase } from "title-case";
import Facebook from "../../../images/social-icon-facebook-round.svg";
import Twitter from "../../../images/social-icon-twitter-round.svg";
import LinkedIn from "../../../images/social-icon-linkedin-round.svg";
import Instagram from "../../../images/social-icon-instagram-round.svg";
import Whatsapp from "../../../images/social-icon-whatsapp.svg";
import { checkImageURL, projectDomain } from "../../../resources/constants";
import DefBanner from '../news_default.png'
import { MuiFbPhotoGrid } from 'mui-fb-photo-grid';


const NewsPage = (props) => {

    let page_url = window.location.pathname;
    let page_id = page_url.split('/');
    let id = page_id[2];

    const slug = id
    const [isLoading, setIsloading] = useState(true)
    const [gallery_images, setgallery_images] = useState([])
    const [img_files, setimg_files] = useState([])


    useEffect(() => {
        setgallery_images([])
        if (slug !== '') {
            if (slug !== props.newsData.Slug) {
                fetchNews();
            } else
                setIsloading(false);
        }

        if (props.newsData !== undefined) {
            if (props.newsData.EntryID !== undefined) {
                fetchGallery(props.newsData.EntryID);
            }
        }
    }, [slug])

    const fetchGallery = (news_id) => {
        setgallery_images([]);
        axios.get(`${serverLink}news/get-multi-images/${news_id}`).then((res) => {
            if (res.data.length > 0) {
                res.data.map((gallery) => {
                    img_files.push({
                        title: gallery.ImageTitle, // require
                        img: ImgeUrl(gallery.ImagePath), // require
                        imgThumbnail: ImgeUrl(gallery.ImagePath), // optional
                    }
                    )
                })
            }
            setgallery_images(img_files);
        })
    }
    async function fetchNews() {
        setgallery_images([])
        await axios.get(`${serverLink}news/list/active`)
            .then(result => {
                props.setOnNewsDetails(result.data);
                const newsSingle = result.data.filter(item => item.Slug === slug);
                props.setOnNewsSingleDetails(newsSingle[0]);
                setIsloading(false)
                if (newsSingle[0].EntryID !== undefined) {
                    fetchGallery(newsSingle[0].EntryID);
                }
            })
            .catch(e => {
                console.log('NEWS FETCH NETWORK ERROR')
            })
    }

    const formatDate = (date) => {
        const dt = new Date(date);
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return `${monthNames[dt.getMonth()]} ${dt.getDate()}, ${dt.getFullYear()}`
    };

    const ImgeUrl = (url) => {
        if (url === null || url === "") {
            return DefBanner;
        } else if (url.includes("simplefileupload")) {
            return checkImageURL(url) ? url : DefBanner
        }
        else {
            return `${checkImageURL(`${serverLink}public/uploads/news_uploads/${url}`) ? `${serverLink}public/uploads/news_uploads/${url}` : DefBanner} `
        }
    }

    return isLoading ?
        <Loader
            type="ThreeDots"
            color="#63656A"
            height={200}
            width={200}
            className="text-center"
        />
        :
        <div className="container-fluid layout col1 mtop">
            <div className="row">
                <div className="layout-col layout-12 col-sm-12">

                    <div className="container-fluid roundable block news-content">
                        <div className="row news-hero-section news-header-image-below light-orange-accents">
                            <div className="container">
                                <div className="row news-extras">
                                    <div className="col-xs-9 publish-date">
                                        <p className="title">{formatDate(props.newsData.InsertedDate)}</p>
                                    </div>
                                </div>
                                <div className="row news-header">
                                    <div className="col-md-8 content">
                                        <h1 className="head">{props.newsData.Title}</h1>
                                        <div>

                                        </div>
                                    </div>
                                    <div className="col-md-4 sidebar">
                                        <div className="sidebar-outline">
                                            <div className="author">
                                                <p className="title">Author</p>
                                                <p className="name">
                                                    {props.newsData.Author !== null ? titleCase(props.newsData.Author) : titleCase(props.newsData.InsertedBy)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="image-wrapper">

                                    <div className="row">
                                        <div className={"col-md-12"}>
                                            <div className="news-item ">
                                                <div className="item-info">
                                                    <div className="w3-content w3-section" >
                                                        <img className="mySlides w3-animate-fading2"
                                                            src={ImgeUrl(props.newsData.ImagePath)}
                                                            alt="" style={{ width: "100%", borderRadius: '5px', maxWidth: '100%', height: 'auto' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row news-body-content">
                            <div className="container light-orange-accents">
                                <div className="row news-body">
                                    <div className="col-md-10 col-sm-12 content">

                                        <div className="body drop-cap text-justify">
                                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.newsData.Description) }} />
                                        </div>

                                    </div>
                                    <div className="col-md-2 col-sm-12" >
                                        <div id="footer-links" style={style} >
                                            <ul className="social" style={{ textDecoration: "none" }} >
                                                <li style={{ textDecoration: "none" }} >
                                                    <a className="btn-social"
                                                        href={`http://www.facebook.com/sharer/sharer.php?u=${projectDomain}news/${props.newsData.Slug}`} target="_blank"
                                                        style={{ textDecoration: "none" }}>
                                                        <i className='fa fa-facebook-f' style={{ fontSize: "30px", marginRight: "10px" }} />
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="btn-social"
                                                        title={props.newsData.Title.trim()}
                                                        href={`https://twitter.com/share?text=${props.newsData.Title}&url=${projectDomain}news/${props.newsData.Slug}`}
                                                        target="_blank">
                                                        <i className='fa fa-twitter' style={{ fontSize: "30px", marginRight: "10px" }} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="btn-social"
                                                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${projectDomain}news/${props.newsData.Slug}`}
                                                        target="_blank">
                                                        <i className='fa fa-linkedin' style={{ fontSize: "30px", marginRight: "10px" }} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="btn-social"
                                                        href={`https://www.instagram.com/?url=${projectDomain}news/${props.newsData.Slug}`}
                                                        target="_blank">
                                                        <i className='fa fa-instagram' style={{ fontSize: "30px", marginRight: "10px" }} />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            gallery_images.length > 0 &&
                                            <div>
                                                <h3>Photo News</h3>
                                                <MuiFbPhotoGrid
                                                    images={gallery_images} // require
                                                    reactModalStyle={{ overlay: { zIndex: 2000 } }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

}

const style = {
    root: {
        background: 'linear-gradient(45deg, #b3b4b5 30%, #545352 90%)',
        borderRadius: 3,
        border: 0,
        boxShadow: '0 3px 5px 2px',
        color: 'white',

    },
    copyContainer: {
        border: '1px solid grey',
        background: 'rgb(0,0,0,0.7)'
    },
    title: {
        color: 'aquamarine',
        fontStyle: 'italic',
    }
};


const mapStateToProps = (state) => {
    return {
        newsData: state.BazeNewsPageDetails,
        newsListData: state.BazeNewsDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnNewsSingleDetails: (p) => {
            dispatch(setNewsPageDetails(p));
        },
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(NewsPage)
