import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { logger } from "redux-logger";
import { persistStore } from "redux-persist";
import rootReducer from "./reducers/rootreducer";
import {serverStatus} from "./resources/url";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store;
if (serverStatus === 'Dev') {
    store = createStore(
        rootReducer
        , composeEnhancers(applyMiddleware(logger, thunkMiddleware))
    );
}else {
    store = createStore(
        rootReducer
        , composeEnhancers(applyMiddleware(thunkMiddleware))
    );
}

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
      <PersistGate persistor={persistor}>
          <App />
      </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
