import React, { Component } from "react";
import { serverLink } from "../../resources/url";
import NewsLogo from "../../images/news/news.jpg";
import { Link } from "react-router-dom";
import { checkImage, checkImageURL, toTitleCase } from "../../resources/constants";
import NewsDefault from './news_default.png'


class NewsSection extends Component {
    constructor(props) {
        super(props);
    }

    ImgeUrl = (url) => {
        if(url === null || url === "" ) {
            return NewsDefault;
        }else if(url.includes("simplefileupload")) {
            return checkImageURL(url) ? url : NewsDefault
        }
        else {
            return `${checkImageURL(`${serverLink}public/uploads/news_uploads/${url}`) ? `${serverLink}public/uploads/news_uploads/${url}` : NewsDefault} ` 
        }
    }

    render() {
        const news = typeof this.props.news !== "undefined" ? this.props.news.length : 0;
        return (
            <div className="layout-blocks-ucws-news-feed container-fluid roundable block news-feed" >
                <div className="row">
                    <div className="container">
                        <div className="row header">
                            <div className="col-sm-12">
                                <h2 style={{ color: `${typeof this.props.color !== "undefined" && this.props.color}` }}>News</h2>
                            </div>
                        </div>

                        <div className="row divider">
                            <div className="col-sm-12">
                                <hr className="light-grey" />
                            </div>
                        </div>

                        <div className="row news-items light-grey-accents">
                            {
                                news > 0 ?
                                    <>
                                        {
                                            this.props.news.length > 0 &&
                                            <div className="col-sm-12 col-md-12 news-items__second">
                                                <div className="row" style={{ marginTop: '10px' }}>
                                                    {
                                                        this.props.news.slice(0, 3).map((news, index) => {
                                                            // if (index > 0 && index < 3) {
                                                            return <div key={index} className="col-md-4 col-lg-4 news-item">
                                                                <div className="item-info">
                                                                    <div className="w3-content w3-section" >
                                                                        <img className="mySlides w3-animate-fading2 img-thumbnail"
                                                                            src={this.ImgeUrl(news.ImagePath)}
                                                                            alt=""
                                                                            style={{ width: "100%", borderRadius: '5px', minHeight: '200px', maxHeight: '200px' }}

                                                                        />
                                                                    </div>
                                                                    <div className="title-border dark-orange-accents" />

                                                                    <h4>{news.Title}</h4>
                                                                    <p>
                                                                        <Link to={`/news/${news.Slug}`} className="read-more">
                                                                            Read more
                                                                        </Link>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            // }
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        }


                                    </> : <div className="col-md-12">No Upcoming News at the moment</div>
                            }

                        </div>

                        <div className="row divider link-section-divider">
                            <div className="col-sm-12">
                                <hr className="light-grey" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <strong>
                                    <Link to="/news" className="read-more all-news-link pull-right">
                                        More Baze News
                                    </Link>
                                </strong>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default NewsSection;
