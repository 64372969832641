import React from 'react'
import Banner from '../../utils/banner/banner'
import { isMobile } from 'react-device-detect'
import './clean-energy-style.css';
import ResearchFellows from './clean-energy-researchers';
import AssociateResearchFellows from './associate-researchers';
import { CleanEnergyPartners } from './clean-energy-data';
import HRLine from '../../utils/hrline';
import { Link } from 'react-router-dom';

function CleanEnergy() {
  return (
    <div>
      <Banner image={require("../images/climate_2.png")} title="CCECC" caption="" disableTitle />

      <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right mt-5" style={{ marginTop: "30px" }}>
        <div className="row">
          <div className="col-sm-12 ">
            <div className={isMobile ? "col-md-12" : "col-md-9"}>
              <h2>Energy is at the center of the climate crisis – and key to the solution.</h2>
              <p>
                The linkages between the energy system and the climate system are of critical contemporary interest and concern. Our global reliance on fossil fuels is the primary cause of climate change, and transitioning toward lower-emissions ways of producing and using energy will be the primary way that we limit climate change. At the same time, energy systems are vulnerable to a changing climate, often in ways that are not currently well understood. Empirical evidence makes a strong case that we have not identified all the solutions needed to address the challenges at the intersection of energy and climate change. The Center for Clean Energy & Climate Change, Baze University, aims to tackle this challenging issue and advance practical solutions through quality research and collaboration. <br />

                The Center's activities are developed by its researchers in collaboration with players in the socio-economic world. We combine social and economic sciences, materials sciences and engineering, applied mathematics, computer science, and geophysics. The center will develop instrumental platforms, models for energy forecasting and prediction, and a data center.

              </p>
              <p>
                In addition to our sound and first-rate buildings, we have
                employed and acquired highly experienced individuals that will
                ensure that these facilities are put to the best and most
                productive use. These individuals - ranging from the academic
                staff (who are visionaries of the education system) to the
                security guards and cleaning services - are dedicated to the
                provision of the best services available to guarantee that
                standards set forth by the University's board are exceedingly met.
              </p>
            </div>
            {
              !isMobile &&
              <div className="col-md-3">
                <img
                  style={{ borderRadius: '10px', maxHeight: "300px" }}
                  className="img-responsive-top"
                  loading="lazy"
                  src={require('../images/Aminu_Hussain_.png')}
                  alt={'Dr. Aminu Hussaini'}
                  title={'Dr. Aminu Hussaini'}
                  typeof="foaf:Image" />
                <div className='row text-center'>
                  <h4>Engr. Munir Aminu Husein, Ph.D.</h4>
                  <h5>Director, Center for Clean Energy & Climate Change</h5>
                </div>
              </div>
            }


          </div>
          <div className='col-md-12'>
            <iframe width="100%" height="450"
              src="https://www.youtube.com/embed/eHMLszamZ9w">
            </iframe>
          </div>

          <div className="col-md-12 min-height-fix">
            <div className="rounded-box grey-back">
              <div className="sidekick-text">
                <h2 className="text-center">Our Aim</h2>
                <p className="text-center">
                  The aim of the Center for Clean Energy & Climate Change is to further increase, enhance and synergise cross Faculty and external research collaboration in clean energy and climate change.  These would enhance and develop the existing expertise in these areas.
                </p>
              </div>
              <div className="sidekick-text">
                <h2 className="text-center">Our Objectives</h2>
                <p className="text-center">
                  <ul>
                    <li>Develop a center of excellence in clean energy and climate change research.</li>
                    <li>Promote the use of clean and renewable energy for socioeconomic development through research, development, and deployment.</li>
                    <li>Set up programs that will lead to more clean and renewable energy adoption and climate change awareness in Nigeria.</li>
                  </ul>
                </p>
              </div>
              <div className="sidekick-text">
                <h2 className="text-center">Our Vision</h2>
                <p className="text-center">
                  To accomplish its mandate in a manner that clean energy technologies will form a significant part of the Nigerian energy generation system and consequently help in mitigating the effects of climate change.
                </p>
              </div>
            </div>
          </div>


          <div className="col-md-12" style={{ marginTop: "40px" }}>
            <p>
              Our work reflects the need for projects to focus on generating outcomes that have clear benefits for society in efforts to advance clean energy and tackle the global climate crisis. These outcomes should seek to generate novel clean energy solutions or help the assessment, mitigation, and adaptation of climate change impacts on society, people, and communities. <br />
            </p>
            <div class="wrapper_list">
              <h2>The Center’s works have the following philosophy:</h2>
              <p>It is our vision to be a center of research excellence in clean energy and climate change not only in the country but globally.</p>
              <ol role="list" className='ol_list'>
                <li className='li_list'>Our work aims to produce scientific insights and solve practical problems.</li>
                <li className='li_list'>Although our work is primarily concerned with basic science, it might help to resolve practical problems or issues.</li>
                <li className='li_list'>Our projects and research questions were defined by scientists in collaboration with a user/practitioner community.</li>
                <li className='li_list'>Our projects and research have the potential to be implemented in the near future (e.g., through technology transfer).</li>
                <li className='li_list'>Our work will produce academic and non-academic publications.</li>
                <li className='li_list'>The results of our work will be made accessible to the public outside of academia.</li>
                <li className='li_list'>Our research team is composed of scientists and practitioners.</li>
              </ol>
            </div>
          </div>

          <div className='col-md-12' style={{ backgroundColor: "#f2f2f2" }}>
            <section className="hr_card ">
              <div className="">
                <div className="section-title">
                  <h2>What we do</h2>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="block text-center" >
                      <ul className="list-inline hr_card-list">
                        <li className="list-inline-item">
                          <div className="image-block text-center" style={{ backgroundColor: "#f2f2f2", borderRadius: "10px" }}>
                            <h4>Research</h4>
                            <p className='text-justify'>We are performing cutting-edge research in the areas of clean energy and climate change and publishing the results in reputable journals and technical reports.
                            </p>
                            <p className='text-justify'>
                              We established a peer-reviewed journal, the African Journal of Energy & Climate Change (AFJEC). The journal is dedicated to publishing high-quality research in the areas of energy and climate change that will likely lead to sustainable development in Nigeria and beyond.
                            </p>
                          </div>

                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="block text-center">
                      <ul className="list-inline hr_card-list">
                        <li className="list-inline-item">
                          <div className="image-block text-center" style={{ backgroundColor: "#f2f2f2", borderRadius: "10px" }}>
                            <h4 className='text-decoration-underline'>Capacity Development</h4>
                            <p className='text-justify'>We develop and run short courses related to clean energy and climate change targeting experienced and mid-level career professionals in the energy and climate change sectors.
                            </p>
                            <br />
                            <p className='text-justify'>
                              We collaborate with stakeholders including governments, private sectors, development institutions, and Non-Governmental Organizations (NGOs) to run programs and projects in the areas of clean energy and climate change.
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="block text-center">
                      <ul className="list-inline hr_card-list">
                        <li className="list-inline-item">
                          <div className="image-block text-center" style={{ backgroundColor: "#f2f2f2", borderRadius: "10px" }}>
                            <h4>Investment and Business Promotion</h4>
                            <p className='text-justify'>We provide consultancy services to stakeholders in energy and climate change.</p>
                          </div>

                        </li>
                      </ul>
                    </div>

                    <div className="block text-center" style={{ marginTop: "-40px" }}>
                      <ul className="list-inline hr_card-list">
                        <li className="list-inline-item">
                          <div className="image-block text-center" style={{ backgroundColor: "#f2f2f2", borderRadius: "10px" }}>
                            <h4>Knowledge Management and Awareness Raising</h4>
                            <p className='text-justify'>We organize, together with our partners, seminars, workshops, training, and conferences to advance clean energy and climate change issues and challenges.</p>
                          </div>

                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </section>

          </div>

          <div className="col-md-12" style={{ marginTop: "40px" }}>
            <h2>Research topics</h2>
            <p>Our research has two main themes: clean energy and climate change.<br />
            </p>
            <div class="wrapper_list" style={{ maxWidth: "80%" }}>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='card'>
                    <div className='card-body'>
                      <img
                        style={{ borderRadius: '10px', maxHeight: isMobile ? "150px" : "350px" }}
                        className="img-responsive-top"
                        loading="lazy"
                        src={require('../images/clean_2.jpg')}
                        alt={'Clean Energy'}
                        title={'Clean Energy'}
                        typeof="foaf:Image" />
                    </div>
                    <div className='card-body'>
                      <h4>Clean Energy</h4>
                      <ol>
                        <li>Battery innovation, clean energy storage, and recycling</li>
                        <li>Sustainable electrification planning</li>
                        <li>Clean/Green Hydrogen production, storage, and distribution</li>
                        <li>Solar-powered energy sources</li>
                        <li>Other clean-energy sources (e.g., geothermal, hydroelectric)</li>
                        <li>Environmental cost of new scaled-up energy technology</li>
                        <li>Sustainable architecture</li>
                        <li>Grid physical & economic resilience</li>
                        <li>Critical minerals exploration and green/clean mining</li>
                        <li>Design approaches to Net Zero</li>
                        <li>Consumer acceptance and behavior of Net Zero solutions</li>
                        <li>Bio-based manufacturing and biopolymers</li>
                        <li>Negative carbon energy sources (including carbon capture and storage)</li>
                      </ol>
                    </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='card'>
                    <div className='card-body'>
                      <img
                        style={{ borderRadius: '10px', maxHeight: isMobile ? "150px" : "350px" }}
                        className="img-responsive-top"
                        loading="lazy"
                        src={require('../images/climate_3.jpg')}
                        alt={'Climate Change'}
                        title={'Climate Change'}
                        typeof="foaf:Image" />
                    </div>
                    <div className='card-body'>
                      <h4>Climate Change</h4>
                      <ol>
                        <li>Food and/or water resources and security on the changing planet</li>
                        <li>Municipal solid waste management</li>
                        <li>Climate-driven air quality issues</li>
                        <li>Biodiversity protection/ conservation/ restoration</li>
                        <li>Climate-ready/resilient agriculture/aquaculture/fisheries</li>
                        <li>Climate-ready/resilient cities and infrastructures</li>
                        <li>Nature-based solutions to climate change</li>
                        <li>Climate-resilient economy and supply chain</li>
                        <li>Carbon-neutral society/circular carbon economy</li>
                        <li>Greenhouse gas mitigation</li>
                        <li>Decarbonization/Carbon Capture/Direct Air Capture</li>
                        <li>Building knowledge and guidance into best practices & policy</li>
                        <li>Climate risk assessment tools tailored to local needs</li>
                        <li>Disaster resilience, e.g., flood/sea level rise-, storm-, heat wave-, extreme weather</li>
                        <li>Transport resilience in the changing planet</li>
                        <li>Economics, policy, and social sciences for climate-resilience solutions</li>
                        <li>Computational methods and data sciences for climate risk analytics</li>
                      </ol>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="col-md-12" style={{ marginTop: "40px" }}>
            <h2>Researchers</h2>
            <p>The center is multidisciplinary with researchers of diverse backgrounds in engineering, economics, architecture, computing, applied sciences, and law.<br />
            </p>
            <div class="wrapper_list" >
              <h4>Research Fellows</h4>
              <div className='section-team_rs'>
                <ResearchFellows deviceType={isMobile ? "mobile" : "desktop"} />
              </div>
            </div>

            <div class="wrapper_list" >
              <h4>Associate Research Fellows</h4>
              <div className='section-team_rs'>
                <AssociateResearchFellows deviceType={isMobile ? "mobile" : "desktop"} />
              </div>
            </div>
          </div>

          <div className='col-md-12' style={{ marginTop: "40px" }}>
            <div className='row'>
              <div className='col-md-8'>
                <div style={{ background: "rgb(244, 244, 244)", padding: 8, borderRadius: 5 }}>
                  <h2 className="nav-top">Resources</h2>
                  <ul className="tabs">
                    <Link to="/center-for-clean-energy-and-climate-change/center-leadership">
                      <li>
                        Center leadership
                        <i className="fa fa-angle-right pull-right icons" />
                      </li>
                    </Link>
                    <Link to="/center-for-clean-energy-and-climate-change/ongoing-projects">
                      <li>
                        Ongoing projects
                        <i className="fa fa-angle-right pull-right icons" />
                      </li>
                    </Link>
                    <Link to="/center-for-clean-energy-and-climate-change/journals">
                      <li>
                        Our journals
                        <i className="fa fa-angle-right pull-right icons" />
                      </li>
                    </Link>
                    <Link to="/center-for-clean-energy-and-climate-change/capacity-development">
                      <li>
                        Capacity development
                        <i className="fa fa-angle-right pull-right icons" />
                      </li>
                    </Link>
                    <Link to="/center-for-clean-energy-and-climate-change/investment-and-business-promotion">
                      <li>
                        Investment and business promotion
                        <i className="fa fa-angle-right pull-right icons" />
                      </li>
                    </Link>
                    <Link to="/center-for-clean-energy-and-climate-change/knowledge-management-and-awareness-raising">
                      <li>
                        Knowledge Management and Awareness Raising
                        <i className="fa fa-angle-right pull-right icons" />
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
              <div className='col-md-4 text-right'>
                <div style={{ background: "rgb(244, 244, 244)", padding: 8, borderRadius: 5, paddingRight: "20px" }}>
                  <h2 className="nav-top">Contact Us</h2>
                  <p>
                    Engineering Building, Block D, Office D7, <br />
                    Baze University, Plot 686, Cadastral Zone C00, <br />
                    Kuchigoro, Abuja, Nigeria <br />
                  </p>
                  <p><i className='fa fa-envelope-o' />&nbsp;<a href='mailto:ccecc@bazeuniversity.edu.ng'>ccecc@bazeuniversity.edu.ng</a></p>
                  <p><i className='fa fa-mobile-phone' />&nbsp;<a href='tel:08032988932'>08032988932</a></p>

                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12" style={{ marginTop: "40px" }}>
            <h2>Partners</h2>
            <p>Tackling clean energy and climate change is a complex issue that requires multiple stakeholders to advance innovative solutions. It is for this reason that the Center maintains close and strategic partnerships with relevant stakeholders. The center has either developed or is in the process of developing partnerships with the following organizations:
              <br />
            </p>
            <div class="wrapper_list">
              <div className='row'>
                <div className='col-md-6'>
                  <div className='table-responsive'>
                    <table className='table table-striped'>
                      <thead style={{ backgroundColor: "#c9c6c5" }}>
                        <tr className='text-center'>
                          <td colSpan={2}><strong>Government Departments and Agencies</strong></td>
                        </tr>
                        <tr >
                          <td>Organization Name & Leaders</td>
                          <td>Address</td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          CleanEnergyPartners.government.map((x, i) => {
                            return (
                              <tr key={i}>
                                <td>{x.organization}</td>
                                <td> <span dangerouslySetInnerHTML={{ __html: x.address }} /></td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <HRLine />
                  <div className='table-responsive'>
                    <table className='table table-striped'>
                      <thead style={{ backgroundColor: "#c9c6c5" }}>
                        <tr className='text-center'>
                          <td colSpan={2}><strong>Research Institutions</strong></td>
                        </tr>
                        <tr >
                          <td>Organization Name & Leaders</td>
                          <td>Address</td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          CleanEnergyPartners.research.map((x, i) => {
                            return (
                              <tr key={i}>
                                <td>{x.organization}</td>
                                <td> <span dangerouslySetInnerHTML={{ __html: x.address }} /></td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='table-responsive'>
                    <table className='table table-striped'>
                      <thead style={{ backgroundColor: "#c9c6c5" }}>
                        <tr className='text-center'>
                          <td colSpan={2}><strong>Private Sector</strong></td>
                        </tr>
                        <tr >
                          <td>Organization Name & Leaders</td>
                          <td>Address</td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          CleanEnergyPartners.private.map((x, i) => {
                            return (
                              <tr key={i}>
                                <td>{x.organization}</td>
                                <td> <span dangerouslySetInnerHTML={{ __html: x.address }} /></td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <HRLine />

                  <div className='table-responsive'>
                    <table className='table table-striped'>
                      <thead style={{ backgroundColor: "#c9c6c5" }}>
                        <tr className='text-center'>
                          <td colSpan={2}><strong>Development Institutions</strong></td>
                        </tr>
                        <tr >
                          <td>Organization Name & Leaders</td>
                          <td>Address</td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          CleanEnergyPartners.development_institutions.map((x, i) => {
                            return (
                              <tr key={i}>
                                <td>{x.organization}</td>
                                <td> <span dangerouslySetInnerHTML={{ __html: x.address }} /></td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <HRLine />
                  <div className='table-responsive'>
                    <table className='table table-striped'>
                      <thead style={{ backgroundColor: "#c9c6c5" }}>
                        <tr className='text-center'>
                          <td colSpan={2}><strong>Professional Associations and NGOs</strong></td>
                        </tr>
                        <tr >
                          <td>Organization Name & Leaders</td>
                          <td>Address</td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          CleanEnergyPartners.professionals.map((x, i) => {
                            return (
                              <tr key={i}>
                                <td>{x.organization}</td>
                                <td> <span dangerouslySetInnerHTML={{ __html: x.address }} /></td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default CleanEnergy;
