import React, { useEffect, useState } from "react";
import { setCourseListDetails, setCoursePageDetails } from "../../../actions/setactiondetailsection";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./course-detail.css";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { Link, useLocation } from "react-router-dom";
import HRLine from "../../utils/hrline";
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import Banner from "../../utils/banner/banner";
import AlertDialog from "../../DialogBoxes/alert/alertdialog";
import Loader from "react-loader-spinner";
import * as DOMPurify from 'dompurify';
import { checkImageURL, toTitleCase } from "../../../resources/constants";
import DefBanner from './DefBanner.jpg'
import HodDefBanner from './avater.jpg'
import { Divider } from "@material-ui/core";

function CourseDetail(props) {
    let page_url = window.location.pathname;
    const { slug } = useParams();

    const [moduleDetails, setModuleDetails] = useState([])

    const navigate = useNavigate();

    const [courseData, setCourseData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [courseDetail, setCourseDetail] = useState([]);
    const [runnungModule, setRunningModule] = useState([]);
    const [staffList, setstaffList] = useState([]);
    const [grouped, setgrouped] = useState([])

    const [dt, setDt] = useState({
        select: [],
        selectedImage: "",
        selectedTitle: "",
        selectedDescription: "",
        openAlertDialog: false,
        isCloseAlertDialog: true
    })

    const path = useLocation().pathname.split('/')[2]
    useEffect(() => {
        if (courseData.length > 0) {
            setRunningModule(props.runningModule.length > 0 ? props.runningModule.filter(x => parseInt(x.CourseID) === courseData[0].CourseID) : []);

            const dd = props.runningModule.length > 0 ? props.runningModule.filter(x => parseInt(x.CourseID) === courseData[0].CourseID) : [];

            let grouped = [];
            const groupByLevelAndSemester = groupBy("GroupByVal");
            setgrouped(Object.values(groupByLevelAndSemester(dd)))
            grouped = Object.values(groupByLevelAndSemester(dd));

            // let filtered_ = props.staffList.length > 0 ? props.staffList.filter(x => x.CourseID === courseData[0].CourseID) : [];
            // setstaffList(filtered_)
        }
    }, [courseData])



    useEffect(() => {
        getModuleDetails();
    }, [])

    useEffect(async () => {
        await axios.get(`${serverLink}course/modules/details/all`).then((res) => {
            if (res.data.length > 0) {
                setModuleDetails(res.data)
            }
        })
    }, [])

    const getModuleDetails = async () => {
        let course = [];
        await axios.get(`${serverLink}course/list/course-details/${slug}`)
            .then(res => {
                if (res.data.length > 0) {
                    course = res.data;
                    setCourseData(res.data);
                    setLoading(false);
                }
            })
            .then(async () => {
                await axios.get(`${serverLink}course/staff/${slug}`)
                    .then(res => {
                        if (res.data.length > 0) {
                            setstaffList(res.data)
                        }
                    })
            })
            .then(async () => {
                setLoading(false);
                if (course.length > 0) {
                    await axios.get(`${serverLink}course/content/${course[0].CourseID}`)
                        .then(result => {
                            if (result.data.length > 0) {
                                const course_details = result.data
                                if (course_details.length < 1) {
                                    window.location.href = "/admission/course-finder"
                                } else {
                                    setCourseDetail(course_details)
                                }

                            }
                        })
                        .catch(error => {
                            console.log("SERVER NETWORK ERROR");
                        })
                }
            })

    }

    function groupBy(key) {
        return function group(array) {
            return array.reduce((acc, obj) => {
                const property = obj[key];
                acc[property] = acc[property] || [];
                acc[property].push(obj);
                return acc;
            }, {});
        };
    }

    const openAlertDialog = (det) => {
        setDt({
            ...dt,
            select: det,
            selectedImage: "",
            selectedTitle: `Title`,
            selectedDescription: det[0],
            openAlertDialog: true
        })
    }

    const closeAlertDialog = () => {
        setDt({
            ...dt,
            openAlertDialog: false,
            selectedImage: "",
            selectedTitle: "",
            selectedDescription: ""
        })
    }

    const ImgeUrl = (url) => {
        if (url === null || url === "") {
            return DefBanner;
        } else if (url.includes("simplefileupload")) {
            return checkImageURL(url) ? url : DefBanner
        }
        else {
            return `${checkImageURL(`${serverLink}public/uploads/course_uploads/${url}`) ? `${serverLink}public/uploads/course_uploads/${url}` : DefBanner} `
        }
    }

    const ImgeUrl2 = (url) => {
        if (url === null || url === "") {
            return HodDefBanner;
        } else if (url.includes("simplefileupload")) {
            return checkImageURL(url) ? url : HodDefBanner
        }
        else {
            return `${checkImageURL(`${serverLink}public/uploads/course_uploads/${url}`) ? `${serverLink}public/uploads/course_uploads/${url}` : HodDefBanner} `
        }
    }

    return loading ?
        <Loader type="ThreeDots"
            color="#63656A"
            height={200}
            width={200}
            className="text-center"
        /> :
        <>

            <div className="layout col1 hero">
                <div className="layout-col hero layout-12 col-sm-12" style={{ marginTop: "0px" }}>
                    <div className="layout-blocks-ucws-sidekick-cta block  sidekick-cta full-width full-bleed inline-bg-image bg-image" style={{ margin: "0px" }}>
                        <div className="row red-back hleft">
                            <div className="col-image col-image-banner">
                                {
                                    courseDetail.length > 0 ?
                                        <picture>
                                            <img
                                                src={ImgeUrl(courseDetail[0].ImagePath)}
                                                alt="Baze University Abuja" style={{ width: '100%' }} width='100%' />
                                        </picture>
                                        :
                                        <picture>
                                            <img src={`https://case.edu/utech/sites/case.edu.utech/files/styles/hero_one_column/public/2019-01/RCCI-Faculty-Banner.jpg?h=29c18064&itok=tNamtAJW`} alt="Baze University Abuja" style={{ width: '100%' }} width='100%' />
                                        </picture>
                                }
                                {/* <picture>
                                    <img src={`${serverLink}public/uploads/course_uploads/${courseDetail[0].ImagePath}`} alt="Baze University Abuja" style={{ width: '100%' }} width='100%' />
                                </picture> */}
                            </div>
                            <div className="col-text">
                                <div className="cta-wrapper">
                                    <div className="cta-content">
                                        <h1>{courseData.length > 0 && courseData[0].Description}</h1>
                                        <div className="btn-wrapper">
                                            <a className="btn btn-default btn-primary" href="https://portal.bazeuniversity.edu.ng/admission" role="button">Start your application</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid layout col2-66-33">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="layout-col col2-66 layout-12 col-sm-12">
                                <div className="layout-blocks-ucws-text container-fluid roundable block text">
                                    <div className="row">
                                        <div className="container">
                                            <div className="container row left">

                                                <AlertDialog
                                                    openDialog={dt.openAlertDialog}
                                                    title={dt.select.length > 0 ? dt.selectedDescription.ModuleCode : 'NO MODULE DETAILS'}
                                                    isFullWidth={true}
                                                    onSetOpenDialog={closeAlertDialog}
                                                    isCloseBtnAppear={dt.isCloseAlertDialog}
                                                >
                                                    {
                                                        dt.select.length > 0 ?
                                                            <div className="text-justify">
                                                                <h3>Overview</h3>
                                                                <div className="text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dt.selectedDescription.Overview) }} />

                                                                <h3>Aims</h3>
                                                                <div className="text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dt.selectedDescription.Aims) }} />

                                                                <h3>Learning Outcomes</h3>
                                                                <div className="text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dt.selectedDescription.LearningOutcomes) }} />

                                                                <h3>Reading List</h3>
                                                                <div className="text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dt.selectedDescription.ReadingList) }} />

                                                                <h3>Syllabus</h3>
                                                                <div className="text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dt.selectedDescription.Syllabus) }} />

                                                                <h3>Teaching Methods</h3>
                                                                <div className="text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dt.selectedDescription.TeachingMethods) }} />

                                                                <div className="row" >
                                                                    <div className="col-md-3">
                                                                        <h3>Course Work</h3>
                                                                        <h1>{dt.selectedDescription.CourseWork}</h1>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <h3>Examination</h3>
                                                                        <h1>{dt.selectedDescription.Examination}</h1>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <h3>Quiz</h3>
                                                                        <h1>{dt.selectedDescription.Quiz}</h1>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <h3>Test</h3>
                                                                        <h1>{dt.selectedDescription.Test}</h1>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            :
                                                            <div>
                                                                <h1>No course Content</h1>
                                                            </div>
                                                    }

                                                </AlertDialog>


                                                <Tabs
                                                    activeTab="1"
                                                    className=""
                                                    ulClassName=""
                                                    activityClassName="bg-success"
                                                    style={{ width: '100%' }}

                                                >
                                                    <Tab title="Brief History" className="mr-3" >
                                                        <div className="mt-3" style={{ marginTop: '25px' }}>

                                                            {
                                                                courseDetail.length > 0 ?
                                                                    <div className="col-sm-12 one-col text-justify">
                                                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(courseDetail[0].Description) }} />
                                                                    </div>
                                                                    :
                                                                    <div className="col-sm-12 one-col text-justify">
                                                                        <div >
                                                                            <h3>No Course Details</h3>
                                                                        </div>
                                                                    </div>

                                                            }

                                                            {
                                                                courseDetail.length > 0 &&
                                                                courseDetail[0].HODContent !== undefined &&
                                                                <div className="profile_content">
                                                                    <div className="profile_card" style={{ width: "100%" }}>
                                                                        <div className="firstinfo">
                                                                            <img src={ImgeUrl2(courseDetail[0].HODImage !== undefined ? courseDetail[0].HODImage : '')} />
                                                                            <div className="profileinfo">
                                                                                <h1>{courseDetail[0].hod_name}</h1>
                                                                                <h3>Head, {courseData.length > 0 && courseData[0].Description} </h3>
                                                                                <p className="bio">
                                                                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(courseDetail[0].HODContent) }} />
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="badgescard">
                                                                        {" "}
                                                                        <span className="devicons devicons-django" />
                                                                        <span className="devicons devicons-python"> </span>
                                                                        <span className="devicons devicons-codepen" />
                                                                        <span className="devicons devicons-javascript_badge" />
                                                                        <span className="devicons devicons-gulp" />
                                                                        <span className="devicons devicons-angular" />
                                                                        <span className="devicons devicons-sass" />
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    </Tab>
                                                    <Tab title="Module Content" className="mr-3">
                                                        <div className="mt-3" style={{ marginTop: '25px' }}>
                                                            {runnungModule.length > 0 ?
                                                                <>
                                                                    <div className="layout-col col1 layout-12 col-sm-12">
                                                                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                                                            <div className="row">
                                                                                <div className="container text-justify">
                                                                                    <div className="table-responsive">

                                                                                        <table className="table table-bordered">
                                                                                            {
                                                                                                grouped.length > 0 &&
                                                                                                grouped.map((x, y) => {


                                                                                                    return (
                                                                                                        <tbody key={y}>
                                                                                                            <tr style={{ textAlign: 'center' }}>
                                                                                                                <td><h6>Course Code</h6></td>
                                                                                                                <td><h6>Module</h6></td>
                                                                                                                <td><h6>Level</h6></td>
                                                                                                                <td><h6>Semester</h6></td>
                                                                                                                <td><h6>Action</h6></td>
                                                                                                            </tr>
                                                                                                            {
                                                                                                                x.map((j, k) => {
                                                                                                                    const moduleDet = moduleDetails.length > 0 ?
                                                                                                                        moduleDetails.filter(m => m.ModuleCode === j.ModuleCode) : [];
                                                                                                                    return (
                                                                                                                        <tr key={k}>
                                                                                                                            <td
                                                                                                                                onClick={() => { openAlertDialog(moduleDet) }} >
                                                                                                                                <span style={{ cursor: 'pointer' }}  >{j.ModuleCode}</span>
                                                                                                                            </td>
                                                                                                                            <td>{j.ModuleDescription}</td>
                                                                                                                            <td>{j.CourseLevel}</td>
                                                                                                                            <td>{j.CourseSemester}</td>
                                                                                                                            <td>
                                                                                                                                {
                                                                                                                                    moduleDet.length > 0 ?
                                                                                                                                        <button onClick={() => {
                                                                                                                                            openAlertDialog(moduleDet)
                                                                                                                                        }} >
                                                                                                                                            <span >
                                                                                                                                                View Details
                                                                                                                                            </span>
                                                                                                                                        </button>
                                                                                                                                        :
                                                                                                                                        ''
                                                                                                                                }
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : <></>}
                                                        </div>
                                                    </Tab>
                                                    <Tab title="Staff" className="mt-3" style={{ marginTop: '25px' }}>
                                                        <div className="table-responsive mt-3" style={{ marginTop: '25px' }}>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <td>Staff Name</td>
                                                                        <td>Department</td>
                                                                        <td>Position</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        staffList.length > 0 &&
                                                                        staffList.map((x, y) => {
                                                                            return (
                                                                                <tr key={y}>
                                                                                    <td>
                                                                                        <Link style={{ textDecoration: 'underline' }} to={`/academics/staff/${x.slug !== null ? x.slug : ''}`} className="text-capitalize">
                                                                                            {/* {x.title !== null ? x.title.toLowerCase() : ''}&nbsp;
                                                                                            {x.staff_name !== null ? x.staff_name.toLowerCase() : ''} */}
                                                                                            {x.staff_name}
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td>{x.Department}</td>
                                                                                    <td>{x.designation}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Tab>
                                                </Tabs>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
}

const mapStateToProps = (state) => {
    return {
        courseList: state.BazeCourseListDetails,
        courseData: state.BazeCoursePageDetails,
        runningModule: state.BazeRunningModule,
        staffList: state.BazeStaffListDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnCourseListDetails: (p) => {
            dispatch(setCourseListDetails(p));
        },
        setOnCourseDataDetails: (p) => {
            dispatch(setCoursePageDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetail);
