import React, { useState } from 'react'
import Banner from '../utils/banner/banner'
import HRLine from '../utils/hrline'
import axios from 'axios'
import { _assets, serverLink } from "../../resources/url";
import { useEffect } from 'react';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';


export default function ClubsLanding() {
    const [isLoading, setIsLoading] = useState(true)
    const [clubsList, setClubsList] = useState([])


    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            const { data } = await axios.get(`${serverLink}general/website/societies/list`)
            setClubsList(data);
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }


    return isLoading ? (<Loader />) : (
        <div>
            <Banner image={require("../../images/general/Publications.jpeg")} title="Clubs and Societies" caption="" />

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <div className="row header">
                                        <div className="col-md-8 ">
                                            <span>
                                                <h3>Explore Your Passions with Baze University Clubs and Societies</h3>
                                                <p style={{ marginTop: '20px' }} >
                                                    At Baze University, we believe that education extends far beyond the classroom walls. Our vibrant and diverse array of clubs and societies provide students with the perfect platform to connect, collaborate, and cultivate their interests outside of academics. Whether you're eager to explore new hobbies, engage in thought-provoking discussions, or make lifelong friends who share your passions, our clubs and societies offer a fulfilling experience for everyone.
                                                </p>
                                                <div style={{ marginTop: '20px' }} >
                                                    <h3>Why Join a Club or Society?</h3>
                                                    <ol>
                                                        <li>
                                                            <b>Personal Growth:</b>
                                                            Joining a club or society allows you to step out of your comfort zone, develop leadership skills, and enhance your communication abilities.
                                                        </li>

                                                        <li>
                                                            <b>Networking Opportunities: </b>
                                                            Connect with like-minded individuals who share your interests. These connections can pave the way for valuable friendships and professional relationships.
                                                        </li>

                                                        <li>
                                                            <b>Learning Beyond Curriculum:</b>
                                                            Our clubs and societies offer an opportunity to delve deeper into subjects that intrigue you, gaining insights that go beyond your academic curriculum.
                                                        </li>

                                                        <li>
                                                            <b>Inclusivity:</b>
                                                            Baze University clubs and societies welcome students from all walks of life, fostering an inclusive environment where diverse perspectives thrive.
                                                        </li>
                                                    </ol>
                                                </div>

                                            </span>
                                        </div>
                                        <div className="col-md-4 block_quate">
                                            <span>
                                                <h3>Getting Involved</h3>
                                                <p style={{ marginTop: '20px' }} >
                                                    Joining a club or society at Baze University is easy. Visit our dedicated Clubs and Societies Hub to explore the full list of options and contact details for each group. Attend their meetings, events, and workshops to get a taste of what they offer. Whether you're a freshman or a senior, there's a place for you to belong and thrive within our dynamic campus community.
                                                </p>
                                                <p>
                                                    At Baze University, education is a holistic journey, and our clubs and societies are an integral part of that journey. Discover your passion, make lasting memories, and broaden your horizons by becoming an active participant in our vibrant club culture.
                                                </p>
                                            </span>
                                        </div>
                                    </div>

                                    <HRLine />
                                    <section className="sponsors section" >
                                        <div className="container">
                                            <div className="row">
                                                {
                                                    clubsList.length > 0 &&
                                                    clubsList.map((x, i) => {
                                                        return (
                                                            <div className='col-md-4' key={i}>
                                                                <div className='card'>
                                                                    <div className='card-body text-center'>
                                                                        <img src={_assets + x.Logo} width={"70px"} />
                                                                    </div>
                                                                    <div className='card-body'>
                                                                        <h3>{x.Name}</h3>
                                                                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(x.Description.substr(0, 100)) }} />
                                                                        <div>
                                                                            <Link to={`/clubs-and-societies/${x.Slug}?p=${x.EntryID}`}>Read more...</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
