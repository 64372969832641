import image_path from './img/ewao.png';
import image_path1 from './img/oluwatobi.png';
import image_path2 from './img/obisesan.jpg';

import noimage from './img/noimage.png';

export const ResearchNewsData = [

    {
        title: "Pharmaceutical drugs vs Healing foods",
        abstract: "This research area focuses on providing an alternative treatment for diseases currently being treated with allopathic drugs.",
        name: "Ewaoche Itodo",
        area: "Medical Laboratory Science",
        image: image_path,

    },

    {
        title: "Trends in Oil and Gas Research in Nigeria: A Bibliometric Analysis using Google Scholar database",
        abstract: "The research study aims to determine the research trend in oil and gas research in Nigeria on the Google Scholar database.",
        name: "Amina Badaru",
        area: "Library",
        image: noimage,
    },

    {
        title: "Creation of African Human Iris Dataset for Personal Recognition, Age, Gender, Ethnicity and Disease Prediction",
        abstract: "This research area aims to capture human Iris datasets using Iris scanners, Preprocessing the captured iris datasets and making them publicly available for Iris related research in Africa.",
        name: "Oluwatobi Noah Akande",
        area: "Computer Science",
        image: image_path1,
    },

    {
        title: "Influence of cashless policy on Students' mental health",
        abstract: "This research area aims to investigate the predictive effect of coping strategies on mental well-being among Baze University Students during the cashless policy in Nigeria.",
        name: "Obisesan Oluwunmi",
        area: "Psychology",
        image: image_path2,
    },

    {
        title: "The Role of Law in a growing entrepreneurial economy",
        abstract: "This research area aims to understand how legal frameworks can support or hinder entrepreneurship and to identify ways in which legal systems can be improved to promote the growth of entrepreneurial activities.",
        name: "Bilkisu Hassan",
        area: "Law",
        image: noimage,
    },

   
]