import React from 'react'
import Banner from '../utils/banner/banner'
import { useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { serverLink } from '../../resources/url';
import { useEffect } from 'react';
import DOMPurify from 'dompurify';
import { titleCase } from 'title-case';
import "./clubs.css"
import HRLine from '../utils/hrline';
import { projectDomain } from '../../resources/constants';

export default function ClubsNewsAndEventsDetails() {
    const { society } = useParams();
    const [data, setdata] = useState([])
    const { pathname } = useLocation();
    const params = new URL(document.location).searchParams;
    const path = pathname.includes("news") ? "news" : "events";
    const club_id = params.get("q")
    const item_id = params.get("e")


    useEffect(() => {
        getData();

    }, [])
    const getData = async () => {
        if (path === "news") {
            const { data } = await axios.get(`${serverLink}general/website/societies-news/${club_id}/${item_id}`)
            setdata(data)
        } else {
            const { data } = await axios.get(`${serverLink}general/website/societies-events/${club_id}/${item_id}`)
            setdata(data)
        }
    }

    return (
        <div>
            <Banner image={require("../../images/general/Publications.jpeg")} title={titleCase(society.replace("-", " "))} />
            <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right mt-5 delay-show" style={{ marginTop: "30px" }}>
                <div className="row">

                    {
                        data.length > 0 &&
                        <div className="col-sm-12 ">
                            <h3>
                                {titleCase(society.replace("-", " "))} | {path === "news" ? "News" : "Events"}
                            </h3>
                            <HRLine />
                            <br />
                            <h4>{data[0].Title}</h4>
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data[0].Description) }} />
                            <HRLine />

                            <div className="row col-md-12" style={{ marginTop: "20px" }}>
                                <h4>share</h4>
                                <a className="btn-social"
                                    href={`http://www.facebook.com/sharer/sharer.php?u=${projectDomain}${society}/${path === "news" ? "news" : "events"}/${data[0].Slug}?q=${club_id}&e=${item_id}`} target="_blank"
                                    style={{ textDecoration: "none" }}>
                                    <i className='fa fa-facebook-f' style={{ fontSize: "30px", marginRight: "10px" }} />
                                </a>
                                <a className="btn-social"
                                    title={data[0].Slug.trim()}
                                    href={`https://twitter.com/share?text=${data[0].Slug}&url=${projectDomain}${society}/${path === "news" ? "news" : "event"}/${data[0].Slug}?q=${club_id}&e=${item_id}`}
                                    target="_blank">
                                    <i className='fa fa-twitter' style={{ fontSize: "30px", marginRight: "10px" }} />
                                </a>
                                <a className="btn-social"
                                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${projectDomain}${society}/${path === "news" ? "news" : "event"}/${data[0].Slug}?q=${club_id}&e=${item_id}`}
                                    target="_blank">
                                    <i className='fa fa-linkedin' style={{ fontSize: "30px", marginRight: "10px" }} />
                                </a>
                                <a className="btn-social"
                                    href={`https://www.instagram.com/?url=${projectDomain}${society}/${path === "news" ? "news" : "event"}/${data[0].Slug}?q=${club_id}&e=${item_id}`}
                                    target="_blank">
                                    <i className='fa fa-instagram' style={{ fontSize: "30px", marginRight: "10px" }} />
                                </a>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

