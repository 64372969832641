import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
    AlumniFeedbackDetailsReducer, BazeInThePressDetailsReducer,
    CourseListDetailsReducer, CoursePageDetailsReducer,
    EventsDetailsReducer,
    FacultyListDetailsReducer, GalleryDetailsReducer,
    HomepageDetailsReducer, ManagementListDetailsReducer,
    NewsDetailsReducer, NewsPageDetailsReducer, StaffListDetailsReducer, StaffProfileDetailsReducer,
    RunningModulesReducer, FacultyDetailsReducer, StaffPublicationsReducer
} from "./detailsReducer";

const rootReducer = combineReducers({
    BazeFacultyListDetails: FacultyListDetailsReducer,
    BazeHomepageDetails: HomepageDetailsReducer,
    BazeEventDetails: EventsDetailsReducer,
    BazeNewsDetails: NewsDetailsReducer,
    BazeNewsPageDetails: NewsPageDetailsReducer,
    BazeCourseListDetails: CourseListDetailsReducer,
    BazeCoursePageDetails: CoursePageDetailsReducer,
    BazeStaffListDetails: StaffListDetailsReducer,
    BazeStaffProfileDetails: StaffProfileDetailsReducer,
    BazeManagementListDetails: ManagementListDetailsReducer,
    BazeGalleryDetails: GalleryDetailsReducer,
    BazeAlumniFeedbackDetails: AlumniFeedbackDetailsReducer,
    BazeInThePress: BazeInThePressDetailsReducer,
    BazeRunningModule: RunningModulesReducer,
    BazeFacultyDetails: FacultyDetailsReducer,
    BazeStaffPublications : StaffPublicationsReducer
});

const persistConfig = {
    key: "baze_university_website",
    storage,
};

export default persistReducer(persistConfig, rootReducer);



