export const LibraryStaff = [
{staff_name:"James O. Daniel", position:"clan, FNLA, MALS, Ph.D.", email:"james.daniel@bazeuniversity.edu.ng"},
{staff_name:"Ngozi Odili", position:"cln, BLIS, MLS", email:"ngozi.odili@bazeuniversity.edu.ng"},
{staff_name:"Isah Modu Yerima", position:"cln, BLIS, MIM", email:"isah.modu@bazeuniversity.edu.ng"},
{staff_name:"Angelyna Kimu Mindapa", position:"cln, Dip. Law, BLS", email:"angelina.mindapa@bazeuniversity.edu.ng"},
{staff_name:"Hassana Koro Muhammed", position:"cln, B. Tech LIS, M.Sc", email:"hassana.muhammed@bazeuniversity.edu.ng"},
{staff_name:"Kayla Chanai", position:"cln, BLIS, MBA, MIM", email:"kayla.chanai@bazeuniversity.edu.ng"},
{staff_name:"Fatima Muhammed Saba", position:"cln, B.A. LIS", email:"fatimah.saba@bazeuniversity.edu.ng"},
{staff_name:"Habiba Ochepa", position:"cln, B.Tech. LIS, MLS", email:"habibat.ochepa@bazeuniversity.edu.ng"},
{staff_name:"Bala Abdullahi Shehu", position:"cln, B.Tech. MLS, M.Sc", email:"sheav.abdullahi@bazeuniversity.edu.ng"},
{staff_name:"Innocent Idakwo", position:"cln, BLS, MLS", email:"idakwa.Sunday@bazeuniversity.edu.ng"},
{staff_name:"Oluchi Okeh - Okezie", position:"cln, BLIS", email:"oluchy.okezie@bazeuniversity.edu.ng"},
{staff_name:"Ishaq Dankwalba", position:"cln, BLIS", email:"isyaka.abdullahi@bazeuniversity.edu.ng"},
{staff_name:"Nuhu Mustapha", position:"BLIS", email:"nuhu.mustapha@bazeuniversity.edu.ng"},
{staff_name:"Gloria Nnamdi", position:"B.A.", email:"emelobum.gloria@bazeuniversity.edu.ng"},
{staff_name:"Ernes Okon", position:"SSCE", email:"ernest.okon@bazeuniversity.edu.ng"},
{staff_name:"Dung Davou", position:"SSCE", email:"dung.davou@bazeuniversity.edu.ng"},

]