import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../utils/banner/banner";
import { useState } from "react";
import Loader from "react-loader-spinner";
import { useEffect } from "react";
import axios from "axios";
import { serverLink } from "../../resources/url";
import './short-courses.css'
import { EmailTemplates, formatDate, sendEmail } from "../../resources/constants";

const ShortCoursesLogin = () => {
    const navigate =useNavigate();
    const [isLoading, setIsloading] = useState(false);
    const [formtype, setformtype] = useState("Login");
    const [message, setMessage] = useState({
        type: "",
        message: ""
    })
    const confirm_account = window.location.href.split('=')[1] === undefined ? '' : window.location.href.split('=')[1]

    const confirmAccount = async () => {
        if (confirm_account !== "") {
            await axios.patch(`${serverLink}short_courses/confirm_account/${confirm_account}`)
                .then((result) => {
                    if (result.data.message === "success") {
                        setMessage({
                            ...message,
                            type: 'success',
                            message: 'Account confirmation successful!. Please proceed to login.'
                        })
                    }
                })
        }
    }
    const [data, setData] = useState({
        EmailAddress: "",
        PhoneNumber: "",
        FullName: "",
        Email: "",
        Phone: "",
        cPassword: "",
        ccPassword: "",
        Indexer: Math.floor(1000 + Math.random() * 999900),
        Email:""
    })

    const email = {
        subject: "Baze University short courses",
        title: "Profile creation",
        name: "",
        body: `<div style="line-height:1.6">
                Your account have been created. Click here to activate your account and proceed to login;
                <a href="http://localhost:3000/academics/short-courses/login?z=${data.Indexer}">activate account</a>
                </div>`,
        signature: ""
    }

    const onEdit = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const onLogin = async(e) => {
        let user = data.EmailAddress
        e.preventDefault();
        await axios.post(`${serverLink}short_courses/login`, data)
            .then((result)=>{
                if(result.data.message === "success"){
                    window.sessionStorage.setItem("user", user)
                    window.sessionStorage.setItem("name", result.data.name)
                    setMessage({
                        ...message,
                        type: 'success',
                        message: 'Login successful, please wait...'
                    })
                    setTimeout(() => {
                        navigate('/academics/short-courses/dashboard')
                    }, 1000);
                }else{
                    setMessage({
                        ...message,
                        type: 'danger',
                        message: 'wring credentials...'
                    })
                }
            })
    }
    const onRegister = async (e) => {
        e.preventDefault()
        if (data.ccPassword !== data.cPassword) {
            setMessage({
                ...message,
                type: 'danger',
                message: 'Password does not match'
            })
            return;
        }
        else if (data.FullName === "" || data.Email === "" || data.Phone === "") {
            setMessage({
                ...message,
                type: 'warning',
                message: 'fill all fields'
            })
            return;
        } else {
            await axios.post(`${serverLink}short_courses/create_account`, data)
                .then((result) => {
                    if (result.data.message === "success") {
                        setMessage({
                            ...message,
                            type: 'success',
                            message: 'Your profile have been created'
                        })
                        sendEmail(data.Email, email.subject, email.title, data.FullName, email.body, email.signature)
                    } else if (result.data.message === "exist"){
                        setMessage({
                            ...message,
                            type: 'danger',
                            message: 'Account with this email already exists...'
                        })
                    } else {
                        setMessage({
                            ...message,
                            type: 'danger',
                            message: 'Account creation failed, try again...'
                        })
                    }
                })
        }

    }

    const onForgetPassword = async(e)=>{
        e.preventDefault();

    }
    const toggleFormType = () => {
        if (formtype === "Login") {
            setformtype("Register")
        } else {
            setformtype("Login")
        }

    }
    useEffect(() => {
        confirmAccount();
    }, [])
    
    return isLoading === true ?
        <>
            <Loader />
        </>
        : <>
            <div className="container-fluid layout col1" style={{ marginTop: '20px' }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-accordion container-fluid roundable block accordion border">
                            <div className="row">
                                <div className="container">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <h2>Login</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="jumbotron" >
                                                {
                                                    message.type !== "" &&
                                                    <div className={`alert alert-${message.type}`} role="alert">{message.message}</div>
                                                }
                                                {
                                                    formtype === "Login" &&
                                                 <form onSubmit={onLogin}>
                                                       <div className="container-fluid" >
                                                        <div className="form-group">
                                                            <label htmlFor="EmailAddress">Email Address: </label>
                                                            <input className="textBox" onChange={onEdit} name="EmailAddress" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="Password">Password: </label>
                                                            <input className="textBox" onChange={onEdit} name="Password" />
                                                        </div>
                                                        <button type="submit" className={"btn btn-primary"}>Login</button>
                                                        <div style={{ float: 'right' }}>
                                                            <div><span className="link" onClick={()=>{setformtype("ForgetPassword")}} >forget password</span></div>
                                                            <div><span className="link" onClick={toggleFormType}>create account</span></div>
                                                        </div>
                                                    </div>
                                                 </form>
                                                }

                                                {
                                                    formtype === "Register" &&
                                                  <form onSubmit={onRegister}>
                                                      <div className="container-fluid" >
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label htmlFor="FullName">Full Name: </label>
                                                                <input className="textBox" onChange={onEdit} name="FullName" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="Email">Email Address: </label>
                                                                <input className="textBox" onChange={onEdit} name="Email" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="Phone">Phone Number: </label>
                                                                <input className="textBox" onChange={onEdit} name="Phone" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="cPassword">Password: </label>
                                                                <input className="textBox" onChange={onEdit} name="cPassword" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="ccPassword">Confirm Password: </label>
                                                                <input className="textBox" onChange={onEdit} name="ccPassword" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <button type="submit"  className={"btn btn-primary"}>Create Account</button>
                                                            <div style={{ float: 'right' }}>
                                                                <div><span className="link">forget password</span></div>
                                                                <div><span className="link" onClick={toggleFormType}>Login</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  </form>
                                                }

{
                                                    formtype === "ForgetPassword" &&
                                                 <form onSubmit={onForgetPassword}>
                                                       <div className="container-fluid" >
                                                        <div className="form-group">
                                                            <label htmlFor="Email">Email Address: </label>
                                                            <input className="textBox" onChange={onEdit} name="Email" />
                                                        </div>
                                                        <button type="submit" className={"btn btn-primary"}>Submit</button>
                                                        <div style={{ float: 'right' }}>
                                                            <div><span className="link" onClick={()=>{setformtype("Login")}} >Login</span></div>
                                                        </div>
                                                    </div>
                                                 </form>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
}

export default ShortCoursesLogin;