import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import BabaAhmedSquare from "../../../images/faculties/square.jpg";
import HRLine from "../../utils/hrline";
import ITRIDVC from "../itridvc";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { Link } from "react-router-dom";
import ITRIVC from './itri_vc.jpeg'
import Accomplishments from './accomplishments.png'

class ITRIDirectorate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            members: []
        }
    }

    componentDidMount() {
        this.getMembers();
    }

    async getMembers() {
        await axios.get(`${serverLink}staff/website/itri_staff/list`)
            .then(result => {
                this.setState({ members: result.data })
            })
            .catch(error => {
                console.log("UNABLE TO RETRIEVE THE MEMBERS LIST");
            })
    }

    render() {
        return <>
            <Banner image={BabaAhmedSquare} title="Office of the DVC ITR & I" caption="" />

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <ITRIDVC />
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <div className="row header">


                                        <div className="col-sm-12">
                                            The Information Technology, Research, and Innovation (DVC IT, R & I) office was recently created and placed under the stewardship of a newly appointed DVC IT, R & I, Dr. Rislan Abdulazeez Kanya, to move forward Baze University's Information Technology, Research, and Innovation (IT, R & I) agenda as the institution progresses into its second decade. The DVC IT, R, & I is responsible for the strategic development, leadership, and management of the IT, Research, and Innovation agenda of Baze University. The DVC IT, R & I will also leverage the competitive advantage achieved by Baze University through strategic investment in Digital transformation assets. The digital asset at Baze University is strategically positioned to actualise the Baze philosophy.
                                            The primary mandate of the IT, R & I office will be to adhere to and uphold the Baze philosophy. Since its inception, Baze University has successfully enrolled students in various undergraduate and postgraduate degree programs accredited by the National Universities Commission (NUC) and other accreditation or regulatory agencies. Also, over the last ten years, Baze has significantly invested in high-quality infrastructure (buildings, equipment, digital assets, etc.), recruited competent staff at various levels, and rolled out disruptive service delivery. This tri-combination of people, processes, and technologies enabled Baze to deliver significant expectations of its founding members, students, parents, and society.
                                            As Baze progresses into its second decade of excellence and as part of its transformation agenda, the IT, R & I office is ready to exploit all the possibilities of digital transformation beyond building a connected campus for teaching and learning but as an enabler that can make a difference in the research and innovation ecosystem.
                                        </div>
                                        <br />

                                        <div className="layout-col col1 layout-12 col-sm-12">
                                            <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                                <div className="row">
                                                    <div className="container text-justify">
                                                        <div className="image-wrapper">
                                                            <div>
                                                                <picture>
                                                                    <img
                                                                        src={Accomplishments}
                                                                    />
                                                                </picture>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12" style={{ marginTop: '20px' }}>
                                            <h3>What we do</h3>
                                            <span>We are responsible for all IT services at Baze University. We advance and support teaching, research and learning at Baze by helping the campus community solve technology’s challenges and harness its solutions. We make sure the University’s information systems are easily accessed from anywhere 24/7. We provide prompt and responsive service to Staff and Students, as well as advice and guidance on technology requirements and projects. We embrace innovation, and promote the ethical and secure use of communications technologies. We develop our IT strategies through a collaborative process with various group of campus stakeholders.</span>
                                        </div>
                                    </div>

                                    <HRLine />

                                    <div className="row header">
                                        <div className="col-sm-12 text-justify">
                                            <h3>The IT R & I is designed to meet the following needs:</h3>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-emoticons-robot-line"
                                                        aria-hidden="true" />
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="media media-body">
                                                        <h3>Support</h3>
                                                        <p>To support administration, academic and research needs of staff and students.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-business-and-finance-bill-line"
                                                        aria-hidden="true" />
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="media media-body">
                                                        <h3>Develop</h3>
                                                        <p>To develop Information and Communications Technologies capabilities for both staff and Students</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <HRLine />

                                    <div className="row header">
                                        <div className="col-sm-12 text-justify">
                                            <h3>The Baze IT, R & I Units</h3>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-business-and-finance-cockade-line"
                                                        aria-hidden="true" />
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="media media-body">
                                                        <h3>ISS</h3>
                                                        <p className="text-left">Infrastructure Support Services (ISS):</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-technology-computer-monitor-line"
                                                        aria-hidden="true" />
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="media media-body">
                                                        <h3>ASSD</h3>
                                                        <p className="text-left">Application Solutions and Development Services (ASSD)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-design-and-digital-gradient-line"
                                                        aria-hidden="true" />
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="media media-body">
                                                        <h3>SD</h3>
                                                        <p className="text-left">Service Desk (SD)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-text-grid container-fluid roundable block text-grid">
                            <div className="row red-accents">
                                <div className="container text-justify">
                                    <div className="row divider">
                                        <div className="col-sm-12">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row header">
                                        <div className="col-sm-12 text-justify">
                                            <h3>Service Portfolio</h3>
                                            <p>Below is our Service Portfolio</p>
                                        </div>
                                    </div>
                                    <div className="row section">
                                        <div className="col-md-6">
                                            <div className="text-chunk">
                                                <h4>Network Administration</h4>
                                                <p>Ensure that the University’s Local Area Network (LAN), VPN and the Internet are in optimal performance; in case of downtime liaise with the service provider to restore the service within a very limited time</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="text-chunk">
                                                <h4>Software</h4>
                                                <p>In collaboration with other Faculties, Departments and Services Unit we ensure that the Enterprise Resource Planner (ERP) for both intranet and the internet which is supporting the University’s operations (Application and admission, Registration, Examination and Grading, Attendance, Library, Hostel, Accounting, Human resources, and general staff and student management) are always available and consistent</p>

                                            </div>
                                        </div>
                                        <div className="clearfix visible-lg-block" />
                                        <div className="col-md-6">
                                            <div className="text-chunk">
                                                <h4>Attendance</h4>
                                                <p>Ensure that proper attendance for all modules in the University are properly collected, calculated and communicated to the stakeholders (Staff, parents and students)</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="text-chunk">
                                                <h4>Timetable</h4>
                                                <p>Ensure that proper timetables (teaching and examination) are generated, managed and communicated to the staff and student at the right time</p>

                                            </div>
                                        </div>
                                        <div className="clearfix visible-lg-block" />
                                        <div className="col-md-6">
                                            <div className="text-chunk">
                                                <h4>Examination</h4>
                                                <p>Part of the university policy is to maintain the integrity of the examination process, we oversee generation of Barcode answer script, ensure that right barcode are attached to the right student, and ensure that students biometrics are verified before and after the examination</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="text-chunk">
                                                <h4>University Website</h4>
                                                <p>Oversee the university’s website and ensure that proper update is done at the right time</p>

                                            </div>
                                        </div>
                                        <div className="clearfix visible-lg-block" />
                                        <div className="col-md-6">
                                            <div className="text-chunk">
                                                <h4>Learning Support</h4>
                                                <p>Ensure that all classrooms multimedia tools (smartboards, projectors, audio devices, etc.) are in good condition
                                                    ELearning: Administration of the university eLearning portal for effective learning delivery</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="text-chunk">
                                                <h4>Email and Mailing List</h4>
                                                <p>Administration of University electronic mailing system, account creation, mailing list, update and maintain the system security.</p>

                                            </div>
                                        </div>
                                        <div className="clearfix visible-lg-block" />
                                        <div className="col-md-6">
                                            <div className="text-chunk">
                                                <h4>Security</h4>
                                                <p>Ensure that University's CCTV and other security systems and gadgets are operational and in good condition</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="text-chunk">
                                                <h4>Intercom</h4>
                                                <p>Ensure that University’s internal communication system (PABX/IP) are running effectively and in case of any service disruption ensure that the system is fixed immediately</p>

                                            </div>
                                        </div>
                                        <div className="clearfix visible-lg-block" />
                                        <div className="col-md-6">
                                            <div className="text-chunk">
                                                <h4>Power Backup</h4>
                                                <p>Ensure that the university’s power backup system (inverter) is running at optimal performance</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="text-chunk">
                                                <h4>Training</h4>
                                                <p>Provide training in systems usage and access; and ensure that users are trained in a timely manner to effectively use University’s systems. Provide individual training and support on request and provide ICT orientation to all new staff and student</p>

                                            </div>
                                        </div>
                                        <div className="clearfix visible-lg-block" />
                                        <div className="col-md-12">
                                            <div className="text-chunk">
                                                <h4>Collaboration and Networking</h4>
                                                <p>Collaborate and network with Universities locally and abroad for improvement of service delivery and knowledge sharing</p>
                                            </div>
                                        </div>
                                        <div className="clearfix visible-lg-block" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <HRLine />

                                    <div className="row header">
                                        <div className="col-sm-12 text-justify">
                                            <h3>What We Offer:</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-technology-cable-49-line"
                                                        aria-hidden="true" />
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="media media-body">
                                                        <h3>Digital Literacy</h3>
                                                        <p>IT R&I organises computer courses for students at the undergraduate level in all disciplines, especially on Computer application. The courses are: Introduction to Computing (COM101) and Computing for Academic Skills (GEN110), the courses are compulsory for all Baze students and is part of the graduation requirements for undergraduate degree programmes.</p>
                                                        <p>IT R&I also, organise induction for all new Staff and Students (Undergraduate and Postgraduate) to enable them integrate fully with all core and support department of the University. During the induction the participants are expose to Baze University Enterprise Resource Planner (ERP), the ERP services are available in the Intranet and the Internet infrastructure. The ERP services support all the Universities operations, from students Application to graduation, human resources management, account, etc.</p>
                                                        <p>Please visit IT Service Desk website (<a href="https://portal.bazeuniversity.edu.ng/help_desk" rel="noopener noreferrer" target="_blank">Service Desk Portal</a>) for any complain or service request</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-files-and-folders-question-line"
                                                        aria-hidden="true" />
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="media media-body">
                                                        <h3>IT Service Desk</h3>
                                                        <p>We have a wide range of IT services for Staff and students which include:</p>
                                                        <p>The Help Desk is available to answer questions regarding any of the services that IT provides and any of the software applications that we support. They are able to assist with network configurations, software troubleshooting and training, email support and configuration, and more. The Help Desk staff uses remote tools as well as a shared knowledge base to help provide resolutions to reported issues as quickly as possible. All requests for assistance are documented in the <a
                                                            href="https://portal.bazeuniversity.edu.ng/help_desk" rel="noopener noreferrer" target="_blank">Help Desk software</a>. If the Help Desk is unable to resolve the issue, the ticket will be routed to the appropriate IT technician for resolution. The Help Desk makes every effort to respond to each request the same day it is received.</p>
                                                        <p>If you have any questions please contact our service desk team via servicedesk@bazeuniversity.edu.ng /1081/1052 or you can walk into our service desk secretariat at Room A23 or Control rooms in each building.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <HRLine />

                                    <div className="row header">
                                        <div className="col-sm-12 text-justify">
                                            <h3>Who We Are:</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <ITRIDVC /> */}

                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">

                                    <div className="row">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Staff Name</th>
                                                    <th>Email Address</th>
                                                    <th>Role</th>
                                                    <th>Unit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.members.length > 0 &&
                                                    this.state.members.map((member, index) => {
                                                        return <tr key={index}>
                                                            <td>{(index + 1)}</td>
                                                            <td>
                                                                <Link to={`/academics/staff/${member.Slug}`}>
                                                                    {member.staff_name}
                                                                </Link>
                                                            </td>
                                                            <td><a href={`mailto:${member.BazeEmail}`}>{member.BazeEmail}</a></td>
                                                            <td>{member.designation}</td>
                                                            <td>{member.Unit}</td>
                                                        </tr>
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-banner container-fluid roundable block banner full-width full-bleed-ready graphic">
                            <div className="row" style={{ backgroundColor: "#63656A", color: "#fff", transition: "background-color 500ms ease" }}>
                                <div className="container">
                                    <div className="row cta-wrapper">
                                        <div className="cta-content text-center">
                                            <h2>Contact Us</h2>
                                            <a style={{ color: 'white' }} href="mailto: itri@bazeuniversity.edu.ng" target={"_blank"} >itri@bazeuniversity.edu.ng</a>
                                            <p>
                                                Block A, Room A34<br />
                                                Intercom Line: <strong>1035</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>;
    }
}

export default ITRIDirectorate;
