import React, { Component } from "react";
import "./news-single.css"
import { Link } from "react-router-dom";
import NewsLogo from "../../../images/news/news.jpg";
import { serverLink } from "../../../resources/url";
import { setNewsPageDetails } from "../../../actions/setactiondetailsection";
import { connect } from "react-redux";
import { useState } from "react";
import * as DOMPurify from 'dompurify';
import { checkImageURL, toTitleCase } from "../../../resources/constants";
import DefBanner from '../news_default.png'
const NewsSingle = (props) => {

    const [News, setNews] = useState(props.news)

    const ImgeUrl = (url) => {
        if (url === null || url === "") {
            return DefBanner;
        } else if (url.includes("simplefileupload")) {
            return checkImageURL(url) ? url : DefBanner
        }
        else {
            return `${checkImageURL(`${serverLink}public/uploads/news_uploads/${url}`) ? `${serverLink}public/uploads/news_uploads/${url}` : DefBanner} `
        }
    }


    return <div className="col-sm-12 col-md-12 col-lg-6 chicklet red-back" style={{ marginBottom: "10px", borderRadius: '10px', }}>
        <div className="chicklet-image red-back"
            style={
                {
                    backgroundImage:
                        `url('${ImgeUrl(props.news.ImagePath)}')`
                }}>
            <p className="h2">{toTitleCase(props.news.Title)}</p>
        </div>
        <div className="chicklet-content red-back">
            <div className="content-wrapper">
                <p className="h2 accent-border">{toTitleCase(props.news.Title)}</p>

                <div dangerouslySetInnerHTML={{ __html: `${DOMPurify.sanitize(props.news.Description.substring(0, 200))}...` }} />
                <Link className="link read-more" to={`/news/${props.news.Slug}`} onClick={() => this.props.setOnNewsDetails(props.news)}>
                    Read more
                </Link>

            </div>
        </div>

    </div>

}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnNewsDetails: (p) => {
            dispatch(setNewsPageDetails(p));
        },
    };
};

export default connect(null, mapDispatchToProps)(NewsSingle)

