import React, {Component} from "react";
import {connect} from "react-redux";
import './gallery.css';
import {setGalleryDetails} from "../../../actions/setactiondetailsection";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import Square from "../../../images/faculties/square.jpg";
import Banner from "../../utils/banner/banner";
import {MuiFbPhotoGrid} from "mui-fb-photo-grid";

class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gallery: [],
            galleryTitle: "Gallery"
        }
    }

    componentDidMount() {
        if (this.props.galleryData.length > 0) {
            this.loadGallery();
            this.getData();
        }
        else {
            this.getData();
            this.loadGallery();
        }

    }

    async getData () {
        let homepageData = {};

        await axios.get(`${serverLink}gallery/list/all`)
            .then(data => {
                this.props.setOnGalleryDetails(data.data);
            })
            .catch(error => {
                console.log("ERROR FETCHING THE HOMEPAGE NEWS")
            });
    }

    loadGallery = () => {
        let gallery_images = [];

        this.props.galleryData.map((gallery, index) => {
            gallery_images.push(
                {
                    title: gallery.ImageTitle, // require
                    img: gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath : `${serverLink}public/uploads/gallery/${gallery.ImagePath}`, // require
                    imgThumbnail: gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath :  `${serverLink}public/uploads/gallery/${gallery.ImagePath}`, // optional
                }
            )
        });


        this.setState({gallery: gallery_images, galleryTitle: 'Gallery'});


    }

    CategoryClicked = (category_name) => {
        let gallery_images = [];

        this.props.galleryData.map(gallery => {
            if (gallery.CategoryName === category_name) {
                gallery_images.push(
                    {
                        title: gallery.ImageTitle, // require
                        img: gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath : `${serverLink}public/uploads/gallery/${gallery.ImagePath}`, // require
                        imgThumbnail: gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath : `${serverLink}public/uploads/gallery/${gallery.ImagePath}`, // optional
                    }
                )
            }

        });
        this.setState({gallery: gallery_images, galleryTitle: `Gallery -> ${category_name}`});
    }

    SubCategoryClicked = (category_name, sub_category) => {
        let gallery_images = [];

        this.props.galleryData.map(gallery => {
            if (gallery.CategoryName === category_name && gallery.SubCategoryName === sub_category) {
                gallery_images.push(
                    {
                        title: gallery.ImageTitle, // require
                        img: gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath : `${serverLink}public/uploads/gallery/${gallery.ImagePath}`, // require
                        imgThumbnail: gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath : `${serverLink}public/uploads/gallery/${gallery.ImagePath}`, // optional
                    }
                )
            }

        });
        this.setState({gallery: gallery_images, galleryTitle: `Gallery -> ${category_name} -> ${sub_category}`});
    }

    render() {
        let categories = [];
        let sub_categories = [];
        if (this.props.galleryData.length > 0) {
            const cat_key = 'CategoryName';
            categories = [...new Map(this.props.galleryData.map(item => [item[cat_key], item])).values()];

            const sub_key = 'SubCategoryName';
            sub_categories = [...new Map(this.props.galleryData.map(item => [item[sub_key], item])).values()];

        }

        return <>
            <Banner image={Square} title="Gallery" caption=""/>

            <div className="container-fluid layout col1">
                <div className="row" style={{paddingTop: '30px'}}>
                    <div className="layout-col col1 layout-12 col-sm-12">

                        <div className="layout-blocks-ucws-text container-fluid roundable block text">
                            <div className="row">
                                <div className="container">
                                    <div className="row header left">
                                        <div className="col-sm-12">
                                            <h2>
                                                {this.state.galleryTitle}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <hr className="dotted light-grey"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid layout col2-50-50">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="layout-col col2-50 layout-9 col-md-9">
                                <div className="layout-blocks-ucws-image container-fluid roundable block image">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="image-wrapper">
                                                        <MuiFbPhotoGrid
                                                            images={this.state.gallery} // require
                                                            reactModalStyle={{overlay: { zIndex: 2000 }}}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="layout-col col2-50 layout-3 col-md-3">
                                <div className="layout-blocks-ucws-text container-fluid roundable block text">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row left">
                                                <div className="col-sm-12 one-col">
                                                    <h4><strong>Categories</strong></h4>
                                                    <ul>
                                                        <li>
                                                            <span role="button" onClick={this.loadGallery}>View All</span>

                                                        </li>
                                                    </ul>
                                                    {
                                                        categories.length > 0 &&
                                                        categories.map((category, cat_index) => {
                                                                return <ul key={`cat-key-${cat_index}`}>
                                                                    <li>
                                                                        <span role="button" onClick={() => this.CategoryClicked(category.CategoryName)}>{category.CategoryName}</span>
                                                                        <ul>
                                                                            {
                                                                                sub_categories.length > 0 &&
                                                                                sub_categories.map((sub_category, sub_index) => {
                                                                                    if (sub_category.CategoryName === category.CategoryName) {
                                                                                        return <li key={`sub-cat-key-${sub_index}`}>
                                                                                            <span role="button" onClick={() => this.SubCategoryClicked(category.CategoryName,sub_category.SubCategoryName)}>{sub_category.SubCategoryName}</span>
                                                                                            </li>
                                                                                    }

                                                                                })
                                                                            }

                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            })
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            </>;
    }
}

const mapStateToProps = (state) => {
    return {
        galleryData: state.BazeGalleryDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGalleryDetails: (p) => {
            dispatch(setGalleryDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)
