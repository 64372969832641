import React from "react";
import "./card.css";
import {Link} from "react-router-dom";

const Card = ({image=null, title, description, button, btn_link, icon=false}) => {
    return <div className="sidekick-text">
        {
            image !== null ?
                !icon ? <img src={image} alt="About us" height={80} width={80}/> : <i className={image} style={{fontSize: "40px"}} />
                : ''
        }
        <h2 className="text-center">{title}</h2>
        <p className="text-center">{description}</p>
        {
            button !== '' ? <p> <Link to={btn_link} role="button" className="btn btn-default btn-ghost">{button}</Link></p> : ''
        }

    </div>
}

export default Card;
