export const MembersData = [

    {
        name: "Dr. Rislan A. Kanya",
        rank: "Senior Research Fellow",
        area: "Data Analytics",
        highest: "PhD",

        profile: "https://bazeuniversity.edu.ng/academics/staff/abdulazeez-kanya-rislana"

    },

    {
        name: "Prof. James Ogunleye",
        rank: "Professor",
        area: "Analytics and Innovation",
        highest: "PhD",
        profile: ""

    },
    
    {
        name: "Dr. Mani Ibrahim",
        rank: "Senior Lecturer",
        area: "Entrepreneurship",
        highest: "PhD",
        profile: "https://bazeuniversity.edu.ng/academics/staff/mani-ahmad-ibrahim"
    },

    {
        name: "Dr. Emeka Ene",
        rank: "Senior Lecturer",
        area: "Entrepreneurship",
        highest: "PhD",
        profile: "https://bazeuniversity.edu.ng/academics/staff/emeka-emmanuel-ene"
    },



    {
        name: "Sani Hamisu",
        rank: "Senior Lecturer",
        area: "Analytics and Innovation",
        highest: "PhD",
        profile: ""
    },

    {
        name: "Umar Dayyabu",
        rank: "Senior Research Fellow",
        area: "Analytics and Innovation",
        highest: "Masters",
        profile: "https://bazeuniversity.edu.ng/academics/staff/umar-yusuf-dayyabu"
    },

    {
        name: "Bayo Fasheyitan",
        rank: "Senior Research Fellow",
        area: "Analytics and Innovation",
        highest: "PhD",
        profile: ""
    },
    

    {
        name: "Festus Adebo",
        rank: "Senior Research Fellow",
        area: "Analytics and Innovation",
        highest: "PhD",
        profile: ""
    },

    {
        name: "Chukwuka Onyekwana",
        rank: "Senior Research Fellow",
        area: "Analytics and Innovation",
        highest: "PhD",
        profile: ""
    },

   
]