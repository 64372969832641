
import React from "react";
import Banner from "../utils/banner/banner";
import Square from "../../images/faculties/square.jpg";
import Security from "../../images/security_1.png";

function SecurityDirectorate() {
    return <>
        <Banner image={Square} title="Security" caption="" />
        <div className="container-fluid layout col1" style={{ margin: "20px" }}>
            <div className="row">
                <div className="layout-col layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-text container-fluid roundable block text">
                        <div className="row">
                            <div className="container">
                                <div className="row left">
                                    <div className="col-sm-12 one-col block_quate text-justify">
                                        <h2>Security and Safety</h2>
                                        <p>The Department of Safety and Security at Baze University strives to maintain a safe, secure and healthy campus for students, staff and visitors by developing partnerships within the university community to effectively address security concerned matters.<br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className="container-fluid layout col2-50-50">
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="layout-col col2-50 layout-6 col-md-6">
                            <div className="layout-blocks-ucws-text container-fluid roundable block text">
                                <div className="row">
                                    <div className="container">
                                        <div className="row left">
                                            <div className="col-sm-12 one-col text-justify" >
                                                <p>The department works closely with local law enforcement and community response teams to ensure the environment in which we all work and live in is safe to learn and play.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="layout-col col2-50 layout-6 col-md-6">
                            <div className="layout-blocks-ucws-image container-fluid roundable block image">
                                <div className="row">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="image-wrapper">
                                                    <div>
                                                        <img

                                                            width="100%"
                                                            loading="lazy"
                                                            src={Security}
                                                            typeof="foaf:Image" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>
}

export default SecurityDirectorate;
