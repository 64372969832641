import React from "react";
import Banner from "../../utils/banner/banner";
import Square from "../../../images/faculties/square.jpg";
import Img1 from './images/1.jpg'
import Img2 from './images/2.jpg'
import Img3 from './images/3.jpg'
import Img5 from './images/5.jpg'
import Img6 from './images/6.jpg'
import Img7 from './images/7.jpg'
import Img8 from './images/8.JPG'


function Accommodation() {
    return <>
        <Banner image={Square} title="Accommodation" caption="" />
        <div className="container-fluid layout col1" style={{ margin: "20px" }}>
            <div className="row">
                <div className="layout-col layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-text container-fluid roundable block text">
                        <div className="row">
                            <div className="container">
                                <div className="row left">
                                    <div className="col-sm-12 one-col block_quate">
                                        <h2>Accommodation</h2>
                                        <p>Students can either use accommodation provided by the University, live at home, or arrange their own accommodation. In terms of proximity and ease of access to university resources, the campus accomodation is the preferrable option due to the location of the university.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container text-justify">
                            <div className="row">
                                <h3>Campus Accommodation and Cost</h3>
                                <div className="col-md-6">
                                    <p>Providing a space that serves as a home away from home is our priority at Baze University. Our campus accommodation options are designed with the utmost consideration for the students’ welfare. Students will be housed in either one-person, or two-person per rooms hostel accommodation. The cost will depend on the number of students sharing, and the nature of the facilities. Private accommodation (one person per room) costs N715,412.50* per semester. Shared accommodation (two persons per room) costs N357,706.25* per semester.</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="image-wrapper">
                                        <img
                                            style={{ borderRadius: '10px' }}
                                            loading="lazy"
                                            width={'100%'}
                                            src={Img7}
                                            typeof="foaf:Image" />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="image-wrapper">
                                        <img
                                            style={{ borderRadius: '10px' }}
                                            width={'100%'}
                                            loading="lazy"
                                            src={Img5}
                                            typeof="foaf:Image" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <br />
                                    <p><b>Students are strongly advised to confirm the availability of Accommodation in the <a target={"_blank"} href="https://portal.bazeuniversity.edu.ng/hostel" style={{ color: "blue" }}>Hostel Portal</a> before making payment.</b></p>
                                    <p>Each room is provided with study desks and reading lamps to ensure that students have comfortable spaces for focusing on their studies in their living accomodations. Refridgerators and quality furniture are also provided to create the perfect home-like environment for our students.</p>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="image-wrapper">
                                                <img
                                                    style={{ borderRadius: '10px' }}
                                                    width={'100%'}
                                                    loading="lazy"
                                                    src={Img1}
                                                    typeof="foaf:Image" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="image-wrapper">
                                                <img
                                                    style={{ borderRadius: '10px' }}
                                                    width={'100%'}
                                                    loading="lazy"
                                                    src={Img6}
                                                    typeof="foaf:Image" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="image-wrapper">
                                                <img
                                                    style={{ borderRadius: '10px' }}
                                                    width={'100%'}
                                                    loading="lazy"
                                                    src={Img8}
                                                    typeof="foaf:Image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="container text-justify">



                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    </>
}

export default Accommodation;
