import { render } from "@testing-library/react";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { Component } from "react";
import { useState } from "react";
import Loader from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../../resources/constants";
import { serverLink } from "../../resources/url";
import './feedback.css'


class Survey extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            data: {
                UserSuggestion: "",
                UserExperience: "",
                webEase: "",
                webCapabilities: "",
                UserFeeling: "",
                VisitReason: "",
                UserDescription: "",
                UserType: "",
                ///
                Experience: "",
                Reason: "",
            }
        }
    }

    // email = {
    //     subject: "Baze University short courses",
    //     title: "Profile creation",
    //     name: "",
    //     body: `<div style="line-height:1.6">
    //             Your account have been created. Click here to activate your account and proceed to login;
    //             <a href="http://localhost:3000/academics/short-courses/login?z=${this.state.data.Indexer}">activate account</a>
    //             </div>`,
    //     signature: ""
    // }

    experience = []
    onUserExpEdit = (e) => {
        const data = this.state.data;
        if (e.target.checked === true) {
            this.experience.push(e.target.value)
        } else {
            var index = this.experience.indexOf(e.target.value);
            if (index > -1) {
                this.experience.splice(index, 1)
            }
        }
        this.setState({
            data: {
                ...data,
                Experience: this.experience.join(", ")
            },
        });
    }

    reason = []
    onReasonEdit = (e) => {
        const data = this.state.data;
        if (e.target.checked === true) {
            this.reason.push(e.target.value)
        } else {
            var index = this.reason.indexOf(e.target.value);
            if (index > -1) {
                this.reason.splice(index, 1)
            }
        }
        this.setState({
            data: {
                ...data,
                Reason: this.reason.join(", ")
            },
        });
    }

    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.data.UserSuggestion === "" || this.state.data.webEase === "" || this.state.data.webCapabilities === "" ||
            this.state.data.UserFeeling === "" || this.state.data.UserDescription === "" || this.state.data.UserType === "" ||
            this.state.data.Experience === "" || this.state.data.Reason === "") {
            showAlert("Error", "All fields are required", "error");
            return false;
        }

        await axios.post(`${serverLink}general/website/add-feedback`, this.state.data).then((res) => {
            if (res.data.message === "success") {
                showAlert("Thank You!", "Your response have been submitted. Thank you", "success").then(() => window.location.href = "/")
            } else {
                showAlert("Error", "Netwrok error, please try again", "error")
            }
        })
    }

    onEdit = (e) => {
        const data = this.state.data;
        this.setState({
            data: {
                ...data,
                [e.target.id]: e.target.value
            },
        });
    }

    render() {
        return this.state.isLoading === true ?
            <>
                <Loader />
            </>
            : <>
                <div className="container-fluid layout col1" style={{ marginTop: '20px' }}>
                    <div className="row">
                        <div className="layout-col col1 layout-12 col-sm-12">
                            <div className="layout-blocks-ucws-accordion container-fluid roundable block accordion border">
                                <div className="row">
                                    <div className="container">
                                        <div className="row header">
                                            <div className="col-sm-12">
                                                <h2>Website Feedback</h2>
                                                <h5>Thank you for taking time to submit your response about our website. </h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 jumbotron">
                                                <form onSubmit={this.onSubmit} >
                                                    <div className="form-group">
                                                        <div className="col-md-12"> <label htmlFor="UserType">1. Are you a? </label></div>
                                                        <div className="radio-inline">
                                                            <label className="radio radio-lg">
                                                                <input type="radio" value={"Student"} id="UserType" name="UserType" onChange={this.onEdit} />
                                                                <span />Student</label>
                                                            <label className="radio radio-lg">
                                                                <input type="radio" id="UserType" name="UserType" value={"Staff"} onChange={this.onEdit} />
                                                                <span />Staff</label>
                                                            <label className="radio radio-lg">
                                                                <input type="radio" id="UserType" name="UserType" value={"External Visitor"} onChange={this.onEdit} />
                                                                <span />External Visitor</label>
                                                        </div>
                                                    </div>
                                                    <hr />

                                                    <div className="form-group">
                                                        <div className="col-md-12"> <label htmlFor="UserDescription">2. Please select an option that best describes you </label></div>
                                                        <div className="radio-inline">
                                                            <label className="radio radio-lg">
                                                                <input type="radio" value={"Prospective Undergraduate Student"} id="UserDescription" name="UserDescription" onChange={this.onEdit} />
                                                                <span />Prospective Undergraduate Student</label>
                                                            <label className="radio radio-lg">
                                                                <input type="radio" value={"Prospective Postgraduate (Masters) Student"} id="UserDescription" name="UserDescription" onChange={this.onEdit} />
                                                                <span />Prospective Postgraduate (Masters) Student</label>
                                                            <label className="radio radio-lg">
                                                                <input type="radio" value={"Prospective PhD Student"} id="UserDescription" name="UserDescription" onChange={this.onEdit} />
                                                                <span />Prospective PhD Student
                                                            </label>
                                                            <label className="radio radio-lg">
                                                                <input type="radio" value={"Prospective Foundation Student"} id="UserDescription" name="UserDescription" onChange={this.onEdit} />
                                                                <span />Prospective Foundation Student</label>
                                                            <label className="radio radio-lg">
                                                                <input type="radio" value={"Current Baze University Student (All Levels)"} id="UserDescription" name="UserDescription" onChange={this.onEdit} />
                                                                <span />Current Baze University Student (All Levels)</label>
                                                        </div>
                                                    </div>

                                                    <hr />
                                                    <div className="form-group">
                                                        <div className="col-md-12"> <label htmlFor="VisitReason">3. Why did you come to the website today? (Please click all that apply) </label></div>
                                                        <div className="checkbox-inline">
                                                            <label className="checkbox checkbox-lg">
                                                                <input type="checkbox" value={"Find a person"} id="VisitReason" onChange={this.onReasonEdit} />
                                                                <span />Find a person
                                                            </label>
                                                            <label className="checkbox checkbox-lg">
                                                                <input type="checkbox" value={"Find help to solve a problem"} id="VisitReason" onChange={this.onReasonEdit} />
                                                                <span />Find help to solve a problem
                                                            </label>
                                                            <label className="checkbox checkbox-lg">
                                                                <input type="checkbox" value={"Find research that is going on"} id="VisitReason" onChange={this.onReasonEdit} />
                                                                <span />Find research that is going on
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="form-group">
                                                        <div className="col-md-12">
                                                            <label htmlFor="UserFeeling">
                                                                4. Overall how did you feel about using the website today?
                                                            </label>
                                                        </div>
                                                        <div className="radio-inline">
                                                            <label className="radio radio-lg">
                                                                <input type="radio" value={"1"} id="UserFeeling" name="UserFeeling" onChange={this.onEdit} />
                                                                <span />1</label>
                                                            <label className="radio radio-lg">
                                                                <input type="radio" value={"2"} id="UserFeeling" name="UserFeeling" onChange={this.onEdit} />
                                                                <span />2</label>
                                                            <label className="radio radio-lg">
                                                                <input type="radio" value={"3"} id="UserFeeling" name="UserFeeling" onChange={this.onEdit} />
                                                                <span />3
                                                            </label>
                                                            <label className="radio radio-lg">
                                                                <input type="radio" value={"4"} id="UserFeeling" name="UserFeeling" onChange={this.onEdit} />
                                                                <span />4</label>
                                                            <label className="radio radio-lg">
                                                                <input type="radio" value={"5"} id="UserFeeling" name="UserFeeling" onChange={this.onEdit} />
                                                                <span />5</label>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span>1. Very Dissatisfied</span>&emsp;
                                                            <span>5. Very Satisfied</span>

                                                        </div>
                                                   </div>
                                                    <hr />
                                                    <div className="form-group">
                                                            <div className="col-md-12">
                                                                <label htmlFor="webCapabilities">
                                                                    5. The website's capabilities meet my requirements?
                                                                </label>
                                                            </div>
                                                            <div className="radio-inline">
                                                                <label className="radio radio-lg">
                                                                    <input type="radio" value={"1"} id="webCapabilities" name="webCapabilities" onChange={this.onEdit} />
                                                                    <span />1</label>
                                                                <label className="radio radio-lg">
                                                                    <input type="radio" value={"2"} id="webCapabilities" name="webCapabilities" onChange={this.onEdit} />
                                                                    <span />2</label>
                                                                <label className="radio radio-lg">
                                                                    <input type="radio" value={"3"} id="webCapabilities" name="webCapabilities" onChange={this.onEdit} />
                                                                    <span />3
                                                                </label>
                                                                <label className="radio radio-lg">
                                                                    <input type="radio" value={"4"} id="webCapabilities" name="webCapabilities" onChange={this.onEdit} />
                                                                    <span />4</label>
                                                                <label className="radio radio-lg">
                                                                    <input type="radio" value={"5"} id="webCapabilities" name="webCapabilities" onChange={this.onEdit} />
                                                                    <span />5</label>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <span>1. Very Dissatisfied</span>&emsp;
                                                                <span>5. Very Satisfied</span>
                                                            </div>
                                                        </div>

                                                    <hr />
                                                    <div className="form-group">
                                                            <div className="col-md-12">
                                                                <label htmlFor="webEase">
                                                                    6. The website is easy to use
                                                                </label>
                                                            </div>
                                                            <div className="radio-inline">
                                                                <label className="radio radio-lg">
                                                                    <input type="radio" value={"1"} id="webEase" name="webEase" onChange={this.onEdit} />
                                                                    <span />1</label>
                                                                <label className="radio radio-lg">
                                                                    <input type="radio" value={"2"} id="webEase" name="webEase" onChange={this.onEdit} />
                                                                    <span />2</label>
                                                                <label className="radio radio-lg">
                                                                    <input type="radio" value={"3"} id="webEase" name="webEase" onChange={this.onEdit} />
                                                                    <span />3
                                                                </label>
                                                                <label className="radio radio-lg">
                                                                    <input type="radio" value={"4"} id="webEase" name="webEase" onChange={this.onEdit} />
                                                                    <span />4</label>
                                                                <label className="radio radio-lg">
                                                                    <input type="radio" value={"5"} id="webEase" name="webEase" onChange={this.onEdit} />
                                                                    <span />5</label>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <span>1. Very Dissatisfied</span>&emsp;
                                                                <span>5. Very Satisfied</span>
                                                            </div>
                                                    </div>

                                                    <hr />
                                                        <div className="form-group">
                                                            <div className="col-md-12">
                                                                <label htmlFor="UserExperience">
                                                                    7. Please tick all the words that best describe your experience with the website today
                                                                </label>
                                                            </div>
                                                            <div className="checkbox-inline">
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Difficult"} id="Difficult" onChange={this.onUserExpEdit} />
                                                                    <span />Difficult
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Complex"} id="Complex" onChange={this.onUserExpEdit} />
                                                                    <span />Complex
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Overwhelming"} id="Overwhelming" onChange={this.onUserExpEdit} />
                                                                    <span />Overwhelming
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Up-to-dated"} id="Up-to-dated" onChange={this.onUserExpEdit} />
                                                                    <span />Up-to-dated
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Reliable"} id="Reliable" onChange={this.onUserExpEdit} />
                                                                    <span />Reliable
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Hard to use"} id="HardToUse" onChange={this.onUserExpEdit} />
                                                                    <span />Hard to use
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Motivating"} id="Motivating" onChange={this.onUserExpEdit} />
                                                                    <span />Motivating
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Confusing"} id="Confusing" onChange={this.onUserExpEdit} />
                                                                    <span />Confusing
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Easy to use"} id="EasyToUse" onChange={this.onUserExpEdit} />
                                                                    <span />Easy to use
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Useful"} id="Useful" onChange={this.onUserExpEdit} />
                                                                    <span />Useful
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Fragile"} id="Fragile" onChange={this.onUserExpEdit} />
                                                                    <span />Fragile
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Clean"} id="Clean" onChange={this.onUserExpEdit} />
                                                                    <span />Clean
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Convenient"} id="Convenient" onChange={this.onUserExpEdit} />
                                                                    <span />Convenient
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Simple"} id="Simple" onChange={this.onUserExpEdit} />
                                                                    <span />Simple
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Time Consuming"} id="TimeConsuming" onChange={this.onUserExpEdit} />
                                                                    <span />Time Consuming
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Annoying"} id="Annoying" onChange={this.onUserExpEdit} />
                                                                    <span />Annoying
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Straightforward"} id="Straightforward" onChange={this.onUserExpEdit} />
                                                                    <span />Straightforward
                                                                </label>
                                                                <label className="checkbox checkbox-lg">
                                                                    <input type="checkbox" value={"Patronising"} id="Patronising" onChange={this.onUserExpEdit} />
                                                                    <span />Patronising
                                                                </label>
                                                            </div>
                                                    </div>

                                                    <hr />
                                                    <div className="form-group">
                                                            <div className="col-md-12"> <label htmlFor="VisitReason">8. Any suggestions for improvement? </label></div>
                                                            <div className="form-inline">
                                                                <textarea className="f_textBox" id="UserSuggestion" onChange={this.onEdit} >
                                                                </textarea>
                                                            </div>
                                                    </div>
                                                    <button type="submit" className={"btn btn-primary"}>Submit</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
    }

}
export default Survey;