import React from 'react'

import { useEffect } from 'react'
import { gallery_images_data, } from './faclities'
import Banner from "../utils/banner/banner";
import ResearchBanner from "./img/RESEARCH FACILITIES.png";
import { MuiFbPhotoGrid } from "mui-fb-photo-grid";



export default function ResearchFacility() {
    useEffect(() => {
    }, [])

    let gallery_images = [];
    gallery_images_data.map((x => {
        gallery_images.push(
            {
                title: "Clinic Gallery",
                img: x.images,
                imgThumbnail: x.images
            }
        )
    }))

    return (

        <div className="body-wrapper">
            <>
                <Banner image={ResearchBanner} title="Research Facility" caption="" disableTitle />

                <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12" style={{marginTop: "40px"}}>
                                    <h2>The Research Facility</h2>
                                    <hr className="dotted light-grey" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid layout col2-50-50 ">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="layout-col col2-50 layout-7 col-md-7">
                                    <div className="layout-blocks-ucws-image container-fluid roundable block image">
                                        <div className="row">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="image-wrapper" style={{ width: "100%", borderRadius: '5px', minHeight: '30%', maxHeight: '30%' }}>
                                                            <MuiFbPhotoGrid
                                                                images={gallery_images} // require
                                                                reactModalStyle={{ overlay: { zIndex: 200 } }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-5">

                                    <div className="col-md-12" style={{ textAlign: "justify" }}>
                                        <h6><b>FUNCTIONS OF THE UNIVERSITY RESEARCH COMMITTEE</b></h6>
                                        <p>Baze Research is a clear direction and emphasis on strategic research (basic and applied) tailored towards societal needs. Group and individual research projects will be funded through internal and external resources. In general, research will focus on advancing national development in all aspects of human endeavours and governance. Research activities will be geared toward the realisation of the University's philosophy, goals, and mission as detailed in its strategic plan.</p>
                                        <p>Baze will also classify research according to sectorial components of development. Research policies and trajectories will deliberately connect to each sector of knowledge and its application to improve the standard and quality of life of the people. Potential thematic areas of focus will include:</p>
                                        <p>It is recognised that Baze University may at this time not develop academic provisions covering every aspect of each of these sectors. Instead, the focus will be on applying knowledge from the University’s chosen areas of specialisation to problems of relevance to these sectors. Overall, the University has the following specific objectives concerning research:</p>
                                        <p>a) To become recognised as a centre of research excellence in the University’s key disciplines both within Nigeria and Internationally.</p>
                                        <p>b) To provide quality undergraduate and postgraduate teaching – including the supervision of research students –underpinned by staff research of international quality.</p>
                                        <p>c) To contribute to the economic, social, cultural, and environmental development of Nigeria and the region by conducting and publishing relevant, high-quality research.</p>
                                        <p>d) To provide a suitable environment, equipment and tools that facilitate research and innovation in the University.</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <br /> <br />
            </>

        </div>
    )
}
