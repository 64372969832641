import React, {Component} from "react";
import BazeIntroVideo from "../../images/video/baze_university.mp4";
import VCVideo from "../../images/video/vc-opening-remarks.mp4";

class VirtualTour extends Component {
    render() {
        return <div className="container-fluid roundable block">
            <div className="row">
                <div className="container">

                    <div className="row news-items light-grey-accents">
                        <div className="col-sm-12 col-md-12 news-item news-items__main">
                            <div className="row header">
                                <div className="col-sm-12">
                                    <h2>Take a <span style={{color: "#8b8b8b"}}>Virtual Tour</span></h2>
                                    <span style={{fontWeight: "normal", fontStyle: "italic", textTransform: "capitalize", fontSize: "1.5rem"}}>Get to know the Baze campus by taking a tour.
                                                <a href="https://portal.bazeuniversity.edu.ng/tour/index.html" target="_blank" rel="noreferrer" className="btn btn-info" style={{color: "white", background: "#63656A", marginLeft: "10px"}}> Campus tour</a> </span>
                                </div>
                            </div>
                            <div className="row divider link-section-divider">
                                <div className="col-sm-12">
                                    <hr className="light-grey"/>
                                </div>
                            </div>


                            <div className="news-item" style={{ marginTop: "10px" }}>
                                <div className="item-info discover-section">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-6">
                                            <div className="video-wrapper" style={{verticalAlign: "center"}}>
                                                <video controls className="embed-responsive-item">
                                                    <source src={BazeIntroVideo} width="580" height="450" type="video/mp4"/>
                                                </video>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-md-6">
                                            <div className="video-wrapper" style={{verticalAlign: "center"}}>
                                                <video controls className="embed-responsive-item">
                                                    <source src={VCVideo} width="580" height="450" type="video/mp4"/>
                                                </video>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row divider link-section-divider">
                        <div className="col-sm-12">
                            <hr className="light-grey"/>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    }
}
export default VirtualTour;
