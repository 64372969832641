import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import BabaAhmedSquare from "../../../images/faculties/square.jpg";
import HRLine from "../../utils/hrline";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './hr.css'
import { HrStaffData } from "./hr_data";
class HumanResourcesOffice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            members: []
        }
    }

    render() {
        return <>
            <Banner image={require("../images/hr.png")} title="Human Resources Office" caption="" disableTitle />

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <span>
                                                <h3>About The Human Resources Office</h3>
                                                <p style={{ marginTop: '20px' }} >
                                                    The Human Resources Team thrives on diversity and proactive partnerships to
                                                    recruit, develop and retain a highly skilled and engaged workforce. We
                                                    endeavour to inspire and develop the University’s workforce in their efforts to
                                                    fulfil their personal and professional goals.
                                                    The HR team is committed to delivering the University’s mission, striving to
                                                    provide a rewarding, supportive and dynamic work experience for employees.
                                                    We are also unswerving in developing and maintaining a diverse workforce.
                                                    Baze University’s diversity maximizes our collective talents and fosters a
                                                    broader spectrum of thinking that allows for better innovation and decision-
                                                    making.

                                                    The Human Resources Team is also committed to preserving
                                                    the confidentiality and privacy of information entrusted to its care. We
                                                    oversee all facets of Employment Policies and Conditions of Service,
                                                    Recruitment, Faculty Services, Compensation and Benefits, Employee Training
                                                    and Development, Educational Opportunities, Employer-Employee Relations,
                                                    Pension, Staff Awards and Recognition, Onboarding of new Employees, Health
                                                    and Welfare Benefits, Employment Verification and maintaining Baze
                                                    University culture and a Safe work environment.
                                                </p>
                                            </span>
                                        </div>
                                    </div>

                                    <section className="hr_card " >
                                        <div className="">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="hr_card">
                                                        <div className="section-title">
                                                            <h2>Our Mission</h2>
                                                        </div>
                                                        <div className="image-block text-center">
                                                            <p>
                                                                We thrive on diversity and aim to enhance the University’s mission by
                                                                fostering a healthy, impartial, unbiased work environment that guarantees our
                                                                staff an approachable, confidential and service-oriented environment that
                                                                enables our current staff to develop to their full potential.
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="hr_card " >
                                        <div className="">
                                            <div className="section-title">
                                                <h2>
                                                    Our Values
                                                </h2>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="block text-center">
                                                        <ul className="list-inline hr_card-list">
                                                            <li className="list-inline-item">
                                                                <div className="image-block text-center">
                                                                    Respect for others always.
                                                                </div>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <div className="image-block text-center">
                                                                    Striving for continuous improvement.
                                                                </div>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <div className="image-block text-center">
                                                                    Efficiency in everything that we do, through the way we work.
                                                                </div>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <div className="image-block text-center">
                                                                    Consistently add value with every service we provide.
                                                                </div>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <div className="image-block text-center">
                                                                    Excellence in our partnership with one another and the University
                                                                    community.
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <HRLine />

                                    <div className="row">
                                        <div className="col-md-8 col-sm-8">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-business-and-finance-cockade-line"
                                                        aria-hidden="true" />
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="media media-body">
                                                        <h3>Mahmoud, Ahmad L. fcna, fcai, fipmd,</h3>
                                                        <h5><strong>Deputy Registrar, Human Resources</strong></h5>
                                                        <p>
                                                            Preceding his appointment as the Deputy Registrar, Human Resources, Mr
                                                            Mahmoud, Ahmad L. served as a Deputy Director, Administration and Finance at
                                                            the FCT Muslim Pilgrims Welfare Board, Abuja and as a Deputy Director,
                                                            Accounts with the State Ministry of Health, Kano State. His accomplishments
                                                            include redesigning the HR structure to improve efficiency in staff recruitment,
                                                            retention and staff development.
                                                        </p>
                                                        <p>Mr Mahmoud, Ahmad L. holds a Master&#39;s in Treasury Management, a PGD in
                                                            Accounting and Finance, 2006 from Bayero University, Kano; a Diploma in Public
                                                            Accounting and Auditing from Kaduna Polytechnic. He is a member of some
                                                            professional bodies. Mr  Mahmoud is responsible for the general affairs of Human
                                                            Resources and provides advice and support throughout the University and also
                                                            oversees the Human Resources team and communications for staff and faculty.
                                                            Mr Mahmoud Ahmad L. is an articulate, enthusiastic, highly motivated, and
                                                            detail-oriented person with a long history of over 35 years of service.
                                                            Mr Mahmoud Ahmad L. joined Baze University in March 2014. </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-4">
                                            <div className="image-wrapper" style={{ float: 'right' }}>
                                                <div>
                                                    <img
                                                        height={'400px'}
                                                        width="100%"
                                                        loading="lazy"
                                                        src={require("./HR_mahmoud.png")}
                                                        typeof="foaf:Image"
                                                        style={{ borderRadius: '10px' }}
                                                    />

                                                </div>
                                            </div>

                                            {/* <div className="media-body">
                                                <img src={require("./HR_mahmoud.png")} style={{ borderRadius: '7px' }} />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <HRLine />
                                    <div className="row header">
                                        <div className="col-sm-12 text-justify">
                                            <h3>Meet Our Staff:</h3>
                                        </div>
                                    </div>
                                    <Accordion defaultExpanded>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <div className="hr_card_flex_container">
                                                <img className="img-circle" src={require("./avater.jpg")} style={{ width: "80px" }} />
                                                <span style={{ marginLeft: "20px" }}> <h3>{HrStaffData[0].name}</h3> <h5>{HrStaffData[0].position}</h5></span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p dangerouslySetInnerHTML={{ __html: HrStaffData[0].body }} />
                                        </AccordionDetails>
                                    </Accordion>
                                    <div className="row">
                                        {
                                            HrStaffData.length > 0 && HrStaffData.slice(1).map((item, index) => {
                                                return (
                                                    <div className="col-md-6" style={{ marginTop: "10px" }}>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel2a-content"
                                                                id="panel2a-header"
                                                            >
                                                                <div className="hr_card_flex_container">
                                                                    <img className="img-circle" src={item.image_file} style={{ width: "80px" }} />
                                                                    <span style={{ marginLeft: "20px" }}><h4>{item.name}</h4> <h5>{item.position}</h5> </span>
                                                                </div>


                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <p dangerouslySetInnerHTML={{ __html: item.body }} />
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-col layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-text container-fluid roundable block text">
                            <div className="row">
                                <div className="container">
                                    <div className="row left">
                                        <div className="col-sm-12 one-col block_quate">
                                            <h2>Contact Us</h2>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h4>Address:</h4>
                                                    <address>
                                                        Room 4, Registry, <br />Baze University, Abuja.
                                                    </address>

                                                </div>
                                                <div className="col-md-6">
                                                    <p> <strong>Contact Hours: </strong>Monday – Friday, 9 am- 4.30 pm:</p>
                                                    <div>
                                                        <span>For Enquiries:
                                                            <a target={"_blank"} href="Jobs: job@bazeuniversity.edu.ng" >
                                                                &nbsp; job@bazeuniversity.edu.ng</a></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>;
    }
}

export default HumanResourcesOffice;
