import React, {Component} from "react";
import Banner from "../../utils/banner/banner";
import Square from "../../../images/faculties/square.jpg"
import {connect} from "react-redux";
import "./baze-in-the-press.css"
import axios from "axios";
import {setBazeInThePressDetails} from "../../../actions/setactiondetailsection";
import {serverLink} from "../../../resources/url";
import BazeInThePressSingle from "../baze-in-the-press-single/baze-in-the-press-single";
const XMLParser = require('react-xml-parser');

class BazeInThePress extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        this.fetchNews();
    }

    async fetchNews () {
        if (this.props.pressData.length > 0) {
            this.setState({
                isLoading: false
            });
        }

        await axios.get(`${serverLink}news/baze-in-the-media`)
            .then(result => {
                const data = result.data;
                const xml = new XMLParser().parseFromString(data);
                const xml_items = xml.children[0].children;
                const news_feed = xml_items.filter(item => item.name === 'item');
                const filtered_baze_university = news_feed.filter(item => item.children[0].value.includes('Baze University'));
                const filtered_baze_university_tuition_fee = filtered_baze_university.filter(item => !item.children[0].value.includes('jacks up tuition fees'));
                const filtered_baze_university_court = filtered_baze_university_tuition_fee.filter(item => !item.children[0].value.includes('court'));
                const filtered_baze_university_court2 = filtered_baze_university_court.filter(item => !item.children[0].value.includes('Court'));
                this.props.setOnBazeInThePressDetails(filtered_baze_university_court2);
                this.setState({
                    isLoading: false
                })
            })
            .catch(e => {
                console.log(e)
                //console.log('NEWS FETCH NETWORK ERROR')
            })
    }

    render() {
        return <>
            <Banner image={Square} title="Baze University in the Press" caption=""/>

            {
                this.state.isLoading ?
                    <div className="container-fluid layout col1">
                        <div className="row">
                            <div className="layout-col col1 layout-12 col-sm-12">
                                <div className="layout-blocks-ucws-text container-fluid roundable block text">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row header left">
                                                <div className="col-sm-12">
                                                    <h2>
                                                        <div>Loading...</div>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="container-fluid layout col1" style={{margin: "20px"}}>
                        <div className="row">
                            <div className="layout-col col1 layout-12 col-sm-12">
                                <div className="layout-blocks-ucws-related-content container-fluid full-width block related-content no-bottom-margin">
                                    <div className="row">
                                        {
                                            this.props.pressData.length > 0 &&
                                            this.props.pressData.map((news, index) => {
                                                return <BazeInThePressSingle key={index} news={news.children} />
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </>

    }

}

const mapStateToProps = (state) => {
    return {
        pressData: state.BazeInThePress
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnBazeInThePressDetails: (p) => {
            dispatch(setBazeInThePressDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BazeInThePress)
