import React from "react";
import AppGuide from "../../images/faculties/square.jpg";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../utils/banner/banner";
import { useState } from "react";
import Loader from "react-loader-spinner";
import { useEffect } from "react";
import axios from "axios";
import { serverLink } from "../../resources/url";
import './short-courses.css'
import { EmailTemplates, formatDate, sendEmail } from "../../resources/constants";

const ShortCoursesDashboard = () => {
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(true);
    const [formtype, setformtype] = useState("");
    const [message, setMessage] = useState({
        type: "",
        message: ""
    })
    const [applications, setApplications] = useState([]);
    const [course, setCourse] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [courseName, setCoursename] = useState('')
    const [courseFee, setCourseFee] = useState('')
    const user = !window.sessionStorage.getItem("user") ? navigate('/') : window.sessionStorage.getItem("user")
    const courseCode = !window.sessionStorage.getItem("courseCode") ? '' : window.sessionStorage.getItem("courseCode")
    const name = window.sessionStorage.getItem("name")

    const getData = async () => {
        if (user !== "") {
            await axios.get(`${serverLink}short_courses/get_applications/${user}`)
                .then((result) => {
                    if (result.data.length > 0) {
                        setApplications(result.data)
                    }
                })

            await axios.get(`${serverLink}short_courses/courses/list`)
                .then((result) => {
                    if (result.data.length > 0) {
                        setCourseList(result.data)
                        if (courseCode !== "") {
                            let data = result.data.filter(x => x.CourseCode === courseCode)[0].CourseName
                            let fee = result.data.filter(x => x.CourseCode === courseCode)[0].Fee
                            setCoursename(data)
                            setCourseFee(fee)
                            if (courseCode !== "") {
                                setMessage({
                                    ...message,
                                    type: 'info',
                                    message: `Continue application  to ${data}`
                                })
                            }
                        }
                        setIsloading(false)
                    }
                }).catch((e) => {
                    console.log('NETWORK ERROR')
                })
        }
    }
   
    const rebind =async()=>{
        await axios.get(`${serverLink}short_courses/courses/list`)
        .then((result) => {
            if (result.data.length > 0) {
                setCourseList(result.data)
                if (courseCode !== "") {
                    let data = result.data.filter(x => x.CourseCode === courseCode)[0].CourseName
                    let fee = result.data.filter(x => x.CourseCode === courseCode)[0].Fee
                    setCoursename(data)
                    setCourseFee(fee)
                    if (courseCode !== "") {
                        setMessage({
                            ...message,
                            type: 'info',
                            message: `Continue application  to ${data}`
                        })
                    }
                }
            }
        }).catch((e) => {
            console.log('NETWORK ERROR')
        })
    }

    const email = {
        subject: "Baze University short courses",
        title: "Profile creation",
        name: "",
        body: `<div style="line-height:1.6">
                Your application to study ${courseName} have been recieved, we would inform you of the outcome within 2 weeks. <br/><br/>
                For  enquiries, please contact servicedesk@bazeuniversity.edu.ng
                </div>`,
        signature: ""
    }

    const onEdit = (e) => {
       
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const formData={
            EmailAddress: user,
            CourseCode: courseCode,
        }
        await axios.post(`${serverLink}short_courses/submit_application`, formData)
            .then((result)=>{
                if(result.data.message === "success"){
                    rebind();
                    sendEmail(user, email.subject, email.title, name, email.body, '')
                    window.sessionStorage.removeItem("courseCode")
                    setMessage({
                        ...message,
                        type: "success",
                        message: ""
                    })
                    setformtype("")
                }
                else if (result.data.message === "exist"){
                    setformtype("")
                    setMessage({
                        ...message,
                        type: "danger",
                        message: "Sorry, you have already applied for this course, we would contact you shortly"
                    })
                }
                else{
                    setformtype("")
                    setMessage({
                        ...message,
                        type: "danger",
                        message: "Netwrok error, please try agan ..."
                    })
                }
            }).catch(()=>{
                console.log('NETWROK ERROR')
            })
        
    }
    useEffect(() => {
        getData();

    }, [])
    return isLoading === true ?
        <>
            <Loader />
        </>
        : <>
            <Banner image={AppGuide} title="Short Courses" caption="Advance your career with our diverse range of short courses." />

            <div className="container-fluid layout col1" style={{ margin: "10px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-checklist container-fluid roundable block checklist">
                            <div className="row">
                                <div className="container">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <h3 style={{ float: 'left' }}>Hello {name}</h3>
                                            <button style={{ float: 'right' }} onClick={() => {
                                                navigate('/academics/short-courses/login')
                                            }} className={"btn btn-primary"} >Logout</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="checklist-items two-items">
                                                <div className="media checklist-item">
                                                    <div className="col-md-12">
                                                        <div className="col-md-2">
                                                            <div className="media-left">
                                                                <span
                                                                    className="icon icomoon ucws-checklist-check-square light-grey"
                                                                    aria-hidden="true"></span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <h2>{applications.length}</h2>
                                                            <h3>Application</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="media checklist-item">
                                                    <div className="col-md-12">
                                                        <div className="col-md-2">
                                                            <div className="media-left">
                                                                <span
                                                                    className="icon icomoon ucws-checklist-check-square light-grey"
                                                                    aria-hidden="true"></span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <h2>{applications.length > 0 ?
                                                                <>
                                                                    {
                                                                        applications.filter(x => x.CompletionStatus.toString() !== '0').length
                                                                    }
                                                                </> :
                                                                <>0</>}</h2>
                                                            <h3>Completed</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                    message.type === "success" || message.type === "danger" &&
                                    <div className={`alert alert-${message.type}`} role="alert">{message.message} &emsp;</div>
                                }
                                </div>
                            </div>
                        </div>


                        <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                            <div className="row">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <hr className="solid light-grey" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                            <div className="row">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="text-justify">
                                                <div className="table-responsive">
                                                    <div >
                                                        {/* <input name="search" placeholder="search" className="textBox" onChange={this.handleSearch} /> */}
                                                    </div>
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                            <tr style={{ background: 'grey', color: 'white' }}>
                                                                <td><h5>S/N</h5></td>
                                                                <td><h5>Course Code</h5></td>
                                                                <td><h5>Course Name</h5></td>
                                                                <td><h5>Application Status</h5></td>
                                                                <td><h5>Applied On</h5></td>
                                                            </tr>
                                                            {applications.length > 0 ?
                                                                <>
                                                                    {
                                                                        applications.map((x, y) => {
                                                                            let status = x.Status === '0' ? 'Draft Applications'
                                                                                : x.Status === '1' ? 'Application Submitted'
                                                                                    : x.Status === '2' ? 'Application Accepted'
                                                                                        : 'Application Rejected';
                                                                            let courseName = courseList.filter(x => x.CourseCode === x.CourseCode)[0].CourseName
                                                                            return (
                                                                                <tr key={y}>
                                                                                    <td><h5>{y + 1}</h5></td>
                                                                                    <td><h5>{x.CourseCode}</h5></td>
                                                                                    <td><h5>{courseList.filter(x => x.CourseCode === x.CourseCode)[0].CourseName}</h5></td>
                                                                                    <td><h5>{status}</h5></td>
                                                                                    <td><h5>{formatDate(x.DateAdded)}</h5></td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                </>
                                                                : <>
                                                                    <tr>
                                                                        <td><h3>No Application Record</h3></td></tr></>}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="container-fluid layout col1" style={{ marginTop: '20px' }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">

                        {
                            courseCode !== "" &&
                            <div className="layout-blocks-ucws-accordion container-fluid roundable block accordion border">
                                {
                                    message.type === "info" &&
                                    <div className={`alert alert-${message.type}`} role="alert">{message.message} &emsp; <button className="btn btn-primary" onClick={() => {
                                        setformtype("SubmitApp")
                                    }}>Proceed to apply</button></div>
                                }
                                <div className="row">
                                    <div className="container">

                                        {
                                            formtype === "SubmitApp" &&


                                            <div className="col-md-12">
                                                <div className="jumbotron" >
                                                    <div className="row">
                                                        <div className="row header">
                                                            <div className="col-sm-12">
                                                                <h2>Register</h2>
                                                            </div>
                                                        </div>
                                                        <div className="container-fluid" style={{marginTop:'10px'}} >
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="FullName">Full Name: </label>
                                                                    <input className="textBox" onChange={onEdit} disabled={true} value={courseName} name="CourseName" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="Email">Email Address: </label>
                                                                    <input className="textBox" onChange={onEdit} value={user} disabled={true} name="Email" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="Phone">Fees: </label>
                                                                    <input className="textBox" value={courseFee} disabled={true} onChange={onEdit} name="Fees" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <button type="submit" onClick={onSubmit} className={"btn btn-primary"}>Submit Application</button>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </>
}

export default ShortCoursesDashboard;