import React, { Component } from "react";
import "./eventsection.css";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { Link } from "react-router-dom";
import { setEventsDetails } from "../../../actions/setactiondetailsection";
import { connect } from "react-redux";
import { CapitalizeFirstAlphabet, formatDate, formatDateAndTime, todaysDate, toTitleCase } from "../../../resources/constants";
import { useState } from "react";
import { useEffect } from "react";
import { titleCase } from "title-case";
import ReactPaginate from 'react-paginate';

const EventSection = (props) => {
    const [Events, setEvents] = useState(props.events.length > 0 ? props.events : []);
    const [Events2, setEvents2] = useState(props.events.length > 0 ? props.events : []);

    useEffect(() => {
        fetchEvents();
    }, [])

    const fetchEvents = async () => {
        await axios.get(`${serverLink}event/list/active`)
            .then(result => {
                setEvents(result.data)
            })
            .catch(e => {
                console.log('NEWS FETCH NETWORK ERROR')
            })
    }


    const handleSearch = (e) => {
        let val = e.target.value.toString().toLowerCase();
        const filtered = Events2.length > 0 ?
            Events2.filter(x => x.Title.toString().toLowerCase().includes(val) ||
                x.Location.toString().toLowerCase().includes(val))
            : []

        setEvents(filtered)
    }

    function PaginatedItems({ itemsPerPage }) {
        const ev = Events
        const [itemOffset, setItemOffset] = useState(0);

        const endOffset = itemOffset + itemsPerPage;
        const currentItems = ev.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(ev.length / itemsPerPage);

        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % ev.length;
            setItemOffset(newOffset);
        };

        return (
            <>
                <div className="row col-md-12">
                    <Items currentItems={currentItems} />
                </div>
                <div className="row col-md-12">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        containerClassName="pg_container"
                        pageLinkClassName="pg_page-link"
                        previousLinkClassName="pg_page-link"
                        nextLinkClassName="pg_page-link"
                        activeClassName="pg_active"
                    />
                </div>
            </>
        );
    }

    //const events = typeof props.events !== "undefined" ? props.events.length : 0;
    let column = "col-md-6";
    // if (events === 1)
    //     column = "col-md-12";
    // else if (events === 2 || events === 4)
    //     column = "col-md-6";
    // else
    //     column = "col-md-4";

    function Items({ currentItems }) {
        return (
            <>
                {
                    currentItems.length > 1 ?
                        currentItems.map((event, index) => {
                            const currentDate = new Date(event.EventDate);
                            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                            ];
                            const event_day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();
                            return (
                                <div key={index} className={`col-sm-12 news-items__second ${column}`} style={{ paddingBottom: "10px", marginTop: '20px' }}>
                                    <div className="days-events">
                                        <div className="row">
                                            <div className="date-box-col col-xs-2" >
                                                <div className="date-box" >
                                                    <p> {monthNames[currentDate.getMonth()]}<br />
                                                        <span style={{ fontSize: '13px' }}>
                                                            {event_day}/
                                                        {event.EventDate !== null  ? event.EventDate.split("-")[0] : ''}</span> </p>
                                                </div>
                                            </div>
                                            <div className="col-xs-10">
                                                <div className="event-description">
                                                    <Link to={`/event/${event.Slug}`} style={{ backgroundImage: "none" }}>
                                                        {event.Title.length > 50 ? `${event.Title.substr(0,50)}...` :  event.Title}
                                                    </Link>
                                                    <p> <i className="fa fa-clock-o" /> {event.StartTime} - {event.EndTime === "NULL" ? parseInt(event.StartTime.split(":")[0]) + 2 + ":00" : event.EndTime}| <i className="fa fa-map-marker" /> {toTitleCase(event.Location)}
                                                        <div>
                                                            {formatDateAndTime(event.EventDate, "date")}
                                                        </div>
                                                    </p>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        : <div className="col-md-12">No Event at the moment</div>
                }
            </>
        );
    }

    return (
        <div className="layout-blocks-ucws-news-feed container-fluid roundable block news-feed">
            <div className="row">
                <div className="container">
                    <div className="row header">
                        <div className="col-sm-12">
                            {
                                props.page === 'home' ?
                                    <h2 style={{ color: `${typeof props.color !== "undefined" && props.color}` }}><i className="fa fa-calendar" /> Events <span style={{ fontSize: "20px" }}></span></h2> :

                                    <div className="d-flex justify-content-between">
                                        <h2 style={{ color: `${typeof props.color !== "undefined" && props.color}` }}><i className="fa fa-calendar" /> Events List</h2>
                                        <div style={{ padding: '8px', borderRadius: '5px' }}>
                                            <input name="search" placeholder="search events" className="textBox_t" onChange={handleSearch} />
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>

                    <div className="row divider">
                        <div className="col-sm-12">
                            <hr className="light-grey" />
                        </div>
                    </div>
                    {
                        props.page === 'home' ?
                            <div className="row news-items light-grey-accents">
                                {
                                    props.events.length > 1 ?
                                        props.events.filter(x => formatDate(x.EventDate) >= formatDate(todaysDate)).length > 0 ?
                                            props.events.filter(x => formatDate(x.EventDate) >= formatDate(todaysDate)).map((event, index) => {
                                                const currentDate = new Date(event.EventDate);
                                                const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                                                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                                                ];
                                                const event_day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();
                                                return (
                                                    <div key={index} className={`col-sm-12 news-items__second ${column}`} style={{ paddingBottom: "10px" }}>
                                                        <div className="days-events">
                                                            <div className="row">
                                                                <div className="date-box-col col-xs-2"  >
                                                                    <div className="date-box" style={{marginTop:'10px'}} >
                                                                        <p> {monthNames[currentDate.getMonth()]}<br />
                                                                            <span style={{ fontSize: '13px' }}>
                                                                                {event_day}/
                                                                                {event.EventDate !== null  ? event.EventDate.split("-")[0] : ''}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-10">
                                                                    <div className="event-description">
                                                                        <Link to={`/event/${event.Slug}`} style={{ backgroundImage: "none" }}>
                                                                            {event.Title}
                                                                        </Link>
                                                                        <p> <i className="fa fa-clock-o" /> {event.StartTime} - {event.EndTime === "NULL" ? parseInt(event.StartTime.split(":")[0]) + 2 + ":00" : event.EndTime} | <i className="fa fa-map-marker" /> {toTitleCase(event.Location)} </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            props.events.map((event, index) => {
                                                const currentDate = new Date(event.EventDate);
                                                const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                                                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                                                ];
                                                const event_day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();
                                                return (
                                                    <div key={index} className={`col-sm-12 news-items__second ${column}`} style={{ paddingBottom: "10px" }}>
                                                        <div className="days-events">
                                                            <div className="row">
                                                                <div className="date-box-col col-xs-2" style={{ marginTop: '12px' }}>
                                                                    <div className="date-box">
                                                                        <p> {monthNames[currentDate.getMonth()]}<br />
                                                                            <span style={{ fontSize: '13px' }}>
                                                                                {event_day}/
                                                                                {event.EventDate !== null  ? event.EventDate.split("-")[0] : ''}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-10">
                                                                    <div className="event-description">
                                                                        <Link to={`/event/${event.Slug}`} style={{ backgroundImage: "none" }}>
                                                                            {event.Title}
                                                                        </Link>
                                                                        <p> <i className="fa fa-clock-o" /> {event.StartTime} - {event.EndTime === "NULL" ? parseInt(event.StartTime.split(":")[0]) + 2 + ":00" : event.EndTime} | <i className="fa fa-map-marker" /> {toTitleCase(event.Location)}
                                                                            <div>
                                                                                {formatDateAndTime(event.EventDate, "date")}
                                                                            </div>
                                                                        </p>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        : <div className="col-md-12">No Event at the moment</div>
                                }

                            </div>
                            :

                            <div className="row news-items light-grey-accents">
                                <div style={{ marginBottom: '20px' }} className={'col-md-12'}>
                                    {/* <h3>Recent Events</h3> */}
                                    {
                                        Events.length > 1 ?
                                            Events.slice(0, 5).map((event, index) => {
                                                const currentDate = new Date(event.EventDate);
                                                const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                                                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                                                ];
                                                const event_day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();
                                                return (
                                                    <div key={index} className={`col-sm-12 news-items__second ${column}`} style={{ paddingBottom: "10px" }}>
                                                        <div className="days-events">
                                                            <div className="row">
                                                                <div className="date-box-col col-xs-2" >
                                                                    <div className="date-box">
                                                                        <p> {monthNames[currentDate.getMonth()]}<br />
                                                                            <span style={{ fontSize: '13px' }}>
                                                                                {event_day}/
                                                                                {event.EventDate !== null  ? event.EventDate.split("-")[0] : ''}</span> </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-10">
                                                                    <div className="event-description">
                                                                        <Link to={`/event/${event.Slug}`} style={{ backgroundImage: "none" }}>
                                                                            {event.Title}
                                                                        </Link>
                                                                        <p> <i className="fa fa-clock-o" /> {event.StartTime} - {event.EndTime === "NULL" ? parseInt(event.StartTime.split(":")[0]) + 2 + ":00" : event.EndTime}| <i className="fa fa-map-marker" /> {toTitleCase(event.Location)}
                                                                            <div>
                                                                                {formatDateAndTime(event.EventDate, "date")}
                                                                            </div>
                                                                        </p>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                            : <div className="col-md-12">No Event at the moment</div>
                                    }

                                </div>
                                <div className="row divider link-section-divider">
                                    <div className="col-sm-12 mt-5">
                                        <hr className="light-grey" />
                                        <h3>More Events</h3>
                                    </div>
                                    <br />
                                </div>

                                <PaginatedItems itemsPerPage={20} />
                                
                            </div>

                    }

                    {
                        Events.length > 0 && props.page === 'home' ?
                            <div className="row">
                                <div className="col-sm-12">
                                    <strong>
                                        <Link className="read-more all-news-link pull-right" to="/events">
                                            More Baze Events
                                        </Link>
                                    </strong>
                                </div>
                            </div> : ''
                    }
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        eventsData: state.BazeEventDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnEventsDetails: (p) => {
            dispatch(setEventsDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventSection);

