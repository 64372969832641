import Avater from "./avater.jpg";

export const HrStaffData = [
    {
        name: "Tonia Irozuoke, O. anupa,",
        position: "Senior Assistant Registrar, HR",
        body: `<p>Tonia is the Senior Assistant Registrar of Human Resources and is responsible for
        Performance evaluation, Recruitment, Employee Benefits, Pension and Health
        Benefits, Employee Training and Development, Remuneration and Compensation,
        onboarding of new Employees, Managing employee database, Service Committees,
        and Employment verification.</p> 
        <p>Tonia is a people person with strong management knowledge, qualification and
        experience with a proven track record in building and providing support to
        colleagues. She holds an M.Ed and a B.Ed in Guidance and Counselling.
        Tonia Irozuoke, O. has been with the University since September 2012.
        </p>`,
        image_file : Avater
    },
    {
        name: "Bashir Sani Tofa",
        position: "Assistant Registrar, HR",
        body: `<p>Bashir Sani Tofa obtained his O’level certificate at Dawakin Tofa Science College, in
        2001. Holds a BSc in Biology, 2008 and a Post Graduate Diploma in Biological
        Sciences (PGDBS) from Bayero University, Kano 2021. Bashir Sani Tofa is a member
        of the Association of Nigerian University Professional Administrators (ANUPA).
        </p> 
        <p>He started his career as a Civil Servant with the Ministry of Higher Education Kano
        State as Assistant Registrar at Kano State Polytechnic from September 2012 to
        February 2021 and voluntarily resigned to join Baze University on 2 nd March 2021 as
        an Assistant Registrar where he was posted to the Human Resources Department
        and working under the Deputy Registrar of Human Resources.
        </p>`,
        image_file : Avater
    },
    {
        name: "Joshua Idogho",
        position: "Administrative Officer I, HR",
        body: `<p>Joshua Idogho holds an MSc in Computer Science from Baze University, Abuja, a
        BSc in Physics and is currently undergoing self-development training in the UK
        leading to the award of a master&#39;s degree in Education (Leadership Management).
        </p> 
        <p>Some of his duties are Recruitment, Staff Training and Development,
        Remuneration, Compensation, template designs and Performance appraisal
        amongst others. <br/>
        Joshua Idogho has been with Baze University in the Human Resources Department
        as a Human Resource Officer for five years and he is result-focused personnel who
        is committed to creating, managing and maintaining a robust culture structure that
        promotes continuous business growth and staff performance outcome which leads
        to the achievement of organizational goals.
        </p>`,
        image_file : Avater
    },
    {
        name: "Fatimah Abdulsalam A.",
        position: "Administrative Officer I, HR",
        body: `<div>
        <p>Fatimah Adeola Abdulsalam is an alumna of Baze University. She holds a Bachelor of
        Law (LLB) and is currently studying for a Master of Business Administration (MBA)
        degree at Baze University.
        </p> 
        <p>Fatimah focuses on; <br/> </p>
        <ul>
            <li>1. Supporting the development and implementation of HR initiatives and systems</li>
            <li>2. Being actively involved in recruitment by preparing job interviews, posting ads/emails and managing the hiring process</li>
            <li>3. Assist in training and development programs</li>
            <li>4. Assist in performance management processes;</li>
            <li>5. Support the management of disciplinary and grievance issues.</li>
            <li>6. Maintain employee records according to policy and legal requirements.</li>
            <li>7. Review employment and working conditions to ensure legal compliance.</li>
        </ul>
        </div>
        `,
        image_file : Avater
    },
    {
        name: "Yusuf Umar",
        position: "Administrative Officer II, HR",
        body: `<p>Umar Yusuf holds a National Diploma Certificate at Ameer Shehu Idris College of
        Advance, Zaria in 2012. In 2017 he graduated with a BSc in Business Administration
        (Management) at Ahmadu Bello University, Zaria.
        </p> 
        <p>Umar Yusuf started his career as a Monitoring and Evaluation Officer at the Center
        for Girl Child Education in Gombe State from January to December 2020. He joined
        Baze University as an Administrative Officer II in November 2021.<br/>
        
        </p>`,
        image_file : Avater
    }
]