import React, {Component} from "react";
import LeadershipComponent from "./leadershipcomponent";

class LeadershipSenate extends Component {

    render() {
        return <LeadershipComponent title="The Senate" caption="" category="Senate" />
    }
}

export default LeadershipSenate;
