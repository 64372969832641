import React from 'react'
import Banner from '../../utils/banner/banner'
import './clean-energy-style.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import HRLine from '../../utils/hrline';
import { CleanEnergyLeadership, CleanEnergyProjects } from './clean-energy-data';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function CleanEnergyDetails(props) {
    const section = window.location.href;
    return (
        <div>
            <Banner image={require("../images/clean_4_1.png")} title="CCECC" caption="" disableTitle />
            {
                section.includes("center-leadership") &&
                <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right mt-5" style={{ marginTop: "30px" }}>
                    <div className="row">
                        <h2>Center Leadership</h2>
                        <HRLine />
                        <br />
                        <div className='col-md-12'>
                            <h3>Management</h3>
                            <p>The management will run the day-to-day operation of the center and is headed by a Director and assisted by three Deputy Directors.</p>
                            {
                                CleanEnergyLeadership.management.map((x, i) => {
                                    const staff = props.staffList.length > 0 ?
                                        props.staffList.filter(j => j.StaffID === x.staff_id) : [];
                                    return (
                                        <div className="col-md-6" style={{ marginTop: "10px" }} key={i}>
                                            <Accordion>
                                                <AccordionSummary

                                                >
                                                    <div className="hr_card_flex_container">
                                                        <img className="img-circle"
                                                            src={
                                                                x.useimage === true ?
                                                                    staff.length > 0 ? staff[0].image !== null ?
                                                                        staff[0].image.includes("simplefileupload") ?
                                                                            staff[0].image :
                                                                            staff[0].image.includes("portal.bazeuniversity") ?
                                                                                staff[0].image
                                                                                : `https://portal.bazeuniversity.edu.ng/staff/${staff[0].image}` :
                                                                        require('../images/noimage.png') :
                                                                        require('../images/noimage.png') :
                                                                    require('../images/noimage.png')
                                                            }
                                                            style={{ width: "80px" }} />
                                                        <span style={{ marginLeft: "20px" }}><h4>{x.name}</h4> <h5>{x.position}</h5> </span>
                                                    </div>


                                                </AccordionSummary>
                                            </Accordion>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className='col-md-12'>
                            <br />   <br />
                            <HRLine />
                            <br />
                            <h3>Advisory Board</h3>
                            <p>The Centre Advisory Committee members are listed below:</p>
                            {
                                CleanEnergyLeadership.advisory.map((x, i) => {
                                    const staff = props.staffList.length > 0 ?
                                        props.staffList.filter(j => j.StaffID === x.staff_id) : [];
                                    return (
                                        <div className="col-md-6" style={{ marginTop: "10px" }} key={i}>
                                            <Accordion>
                                                <AccordionSummary

                                                >
                                                    <div className="hr_card_flex_container">
                                                        <img className="img-circle"
                                                            src={
                                                                x.useimage === true ?
                                                                    staff.length > 0 ? staff[0].image !== null ?
                                                                        staff[0].image.includes("simplefileupload") ?
                                                                            staff[0].image :
                                                                            staff[0].image.includes("portal.bazeuniversity") ?
                                                                                staff[0].image
                                                                                : `https://portal.bazeuniversity.edu.ng/staff/${staff[0].image}` :
                                                                        require('../images/noimage.png') :
                                                                        require('../images/noimage.png') :
                                                                    require('../images/noimage.png')
                                                            }
                                                            style={{ width: "80px" }} />
                                                        <span style={{ marginLeft: "20px" }}><h4>{x.name}</h4> <h5>{x.position}</h5> </span>
                                                    </div>


                                                </AccordionSummary>
                                            </Accordion>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            {
                section.includes("ongoing-projects") &&
                <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right mt-5" style={{ marginTop: "30px" }}>
                    <div className="row">
                        <h2>Ongoing Projects</h2>
                        <HRLine />
                        <div className='col-md-12'>
                            <div className='table-responsive'>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <td>SN</td>
                                            <td>Project Title</td>
                                            <td>Duration</td>
                                            <td>Principal Investigator</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            CleanEnergyProjects.map((x, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>
                                                            {
                                                                x.link !== "" ?
                                                                    <Link to={x.link} target='_blank' >{x.title}</Link>
                                                                    : <p>{x.title}</p>
                                                            }
                                                        </td>
                                                        <td>{x.duration}</td>
                                                        <td>
                                                            {
                                                                x.investigators.map((j, k) => {
                                                                    const staff = props.staffList.length > 0 ?
                                                                        props.staffList.filter(q => q.StaffID === j.staff_id) : [];
                                                                    return (
                                                                        <p key={k}><Link to={`/academics/staff/${staff.length > 0 ? staff[0].Slug : ''}`} >{j.name}</Link></p>
                                                                    )
                                                                })
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                section.includes("journals") &&
                <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right mt-5" style={{ marginTop: "30px" }}>
                    <div className="row">
                        <h2>Our Journals</h2>
                        <HRLine />
                        <br />
                        <div className='col-md-12'>
                            <h3>African Journal of Energy & Climate Change (AFJEC)</h3>
                            <p>
                                <strong>Aim and scope</strong><br />
                                African Journal of Energy and Climate Change (AFJEC) is an interdisciplinary journal published by the Center for Clean Energy & Climate Change (CCECC). The Journal, published online quarterly, is covering the intersection of energy and climate-related fields, spanning the physical and social sciences, intending to identify practical solutions and strategies. AFJEC aims to promote communication and dialogue among scientists, engineers, economists, and policymakers working in the areas of energy and climate change. The journal provides a forum for innovative and systematic interdisciplinary research on topics such as energy generation, transport, and storage, the causes of climate change, climate impacts on energy systems, energy and climate policies, innovation, behavior and societal changes, and climate communication strategies.

                                <br />
                                The criteria for paper acceptance in AFJEC are the quality of the work and its presentation and breadth of interest, irrespective of whether the paper reports research or development, theory or experiment, original work or review.

                                <br /><br />
                                <strong>Subject areas</strong><br />
                                Clean and renewable energy, Climate Change, Sustainability and the Environment, Management, Monitoring, Policy and Law.

                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                section.includes("capacity-development") &&
                <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right mt-5" style={{ marginTop: "30px" }}>
                    <div className="row">
                        <h2>Capacity Development</h2>
                        <HRLine />
                        <br />
                        <div className='col-md-12'>
                            <p>
                                Lack of knowledge and technical skills are major barriers to the dissemination and usage of clean and renewable energy, and to taking action on climate change mitigation and adaptation.
                                <br />
                                CCECC will aim at strengthening the capacities of a broad range of private and public key market enablers in the clean energy and climate change sectors. These include public institutions (e.g. ministries, municipalities, regulators, rural electrification agencies), investors and financial institutions, training institutions, as well as local companies (e.g. utilities, project developers, manufacturers, installation and maintenance service providers).
                                <br />
                                Under the Strategy, the CCECC will be implementing the following activities:
                                <ol>
                                    <li>Organization training workshops on various sustainable energy aspects and technologies (e.g. planning, installation, maintenance, economics, policy, financing, project development, and resource assessment).</li>
                                    <li>Organization of train-the-trainers workshops/programs.</li>
                                    <li>Sharing of knowledge and information on good and/or poor practices to support the implementation of sustainable energy investments or programs.</li>
                                </ol>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                section.includes("investment-and-business-promotion") &&
                <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right mt-5" style={{ marginTop: "30px" }}>
                    <div className="row">
                        <h2>Investment and Business Promotion</h2>
                        <HRLine />
                        <br />
                        <div className='col-md-12'>
                            <p>
                                The investment and business promotion program of the CCECC aims at mitigating financial barriers to investments in small, medium, and large-scale renewable energy projects, as well as facilitating carbon finance. CCECC, with its partners in the public and private sectors, will be providing support to develop a technically and economically feasible portfolio of projects and attracts the interest of possible investors and financiers.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                section.includes("knowledge-management-and-awareness-raising") &&
                <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right mt-5" style={{ marginTop: "30px" }}>
                    <div className="row">
                        <h2>Knowledge Management and Awareness Raising</h2>
                        <HRLine />
                        <br />
                        <div className='col-md-12'>
                            <p>
                                The non-availability of reliable and updated energy information creates a major constraint for investors and project developers in the energy sector. Key data on existing policies, laws, stakeholders, prices, generation costs, resources, investments, and potential project sites is, in many cases, not available or not up to date.  The lack of quality information is perceived as a major investment risk by investors and project developers alike. The Centre will   initiate several key programs to enhance the existing knowledge and awareness barriers.

                                <br />
                                The center will be executing the following activities:
                                <br />

                                <ol>
                                    <li>The Centre will be developing an annual renewable energy baseline report which will provide detailed information on the existing national policies and laws, investments, resources, and economics of renewable energy technologies.</li>
                                    <li>The Centre will undertake several renewable energy potential assessments such as the bio-crops assessment, the wind and solar resource assessment, and the small hydro assessment.</li>
                                    <li>The Center will launch the Energy Planning Tool. The tool will provide decision-makers, project developers, investors, and other stakeholders with updated information and planning data on clean energy opportunities.</li>
                                    <li>The center will be organizing workshops, conferences, and other events.</li>
                                </ol>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            }



        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        staffList: state.BazeStaffListDetails,
    };
};


export default connect(mapStateToProps, null)(CleanEnergyDetails);