import axios from "axios";
import { serverLink } from "./url";
import swal from "sweetalert";

const CryptoJS = require("crypto-js");

export function encryptData(string, val = false) {
  try {
    let encrypt_method = "AES-256-CBC";
    let secret_key = val === false ? 'SayyoFilms' : 'BazeEncrypt';
    let secret_iv = val === false ? 'FilmsInternational' : 'BazeEncrypt';
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

    let result = decrypted.toString();
    return btoa(result);
  } catch (e) {
    console.log(e)
  }
}



export function decryptData(string, val = false) {
  try {
    let encrypt_method = "AES-256-CBC";
    let secret_key = val === false ? 'SayyoFilms' : 'BazeEncrypt';
    let secret_iv = val === false ? 'FilmsInternational' : 'BazeEncrypt';
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

    var result = decrypted.toString(CryptoJS.enc.Utf8);
    return result;
  } catch (e) {
    console.log(e)
  }
}


export function showAlert(title, msg, type) {
  return swal({
    title: title,
    text: msg,
    icon: type,
    button: "OK",
  })
}

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const currencyConverter = (amount) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });
  return formatter.format(amount);
};

const projectEmailUnsubscribe = "do-not-reply@bazeuniversity.edu.ng";
const projectEmail = "servicedesk@bazeuniversity.edu.ng";
const projectLogo = "https://www.bazeuniversity.edu.ng/images/logo.png";
export const projectDomain = "https://www.bazeuniversity.edu.ng/"
export const sendEmail = (email, subject, title, name, body, signature) => {
  const sendEmail = {
    logo: projectLogo,
    from: projectEmail,
    to: email,
    subject: subject,
    title: title,
    name: name,
    body: body,
    signature: signature,
  };
  axios.post(`${serverLink}short_courses/send_email/send`, sendEmail).then((r) => {
    console.log("email sent");
  });

  return "sent";
};

export const sendEmailAdm = (email, subject, title, name, body, signature) => {
  const sendEmail = {
    logo: projectLogo,
    from: email,
    to: projectEmailUnsubscribe,
    subject: subject,
    title: title,
    name: name,
    body: body,
    signature: signature,
  };
  axios.post(`${serverLink}send_email/send`, sendEmail).then((r) => {
    console.log("email sent");
  });

  return "sent";
};


export const EmailTemplates = (type, item) => {
  if (type === "1") {
    const message = {
      subject: "ACCOUNT CREATION",
      title: "",
      body: `<div style="line-height: 1.6">Dear Applicant,<br/> Your account have been created. Click the <a href="http://localhost:3000/academics/short-courses/login?z=${item.Indexer}">link</a> to confirm your account and proceed to apply. <br/>For further enquiries, please contact hr@bazeuniversity.edu.ng</div>`,
    };
    return message;
  } else if (type === "2") {
    const message = {
      subject: "JOB APPLICATION",
      title: "",
      body: `<div style="line-height: 1.6"></div>`
    }
    return message;
  }
  else if (type === '3') {
    const message = {
      subject: 'JOB APPLICATION',
      title: 'Offer of Appointment',
      body: `<div style="line-height: 1.6"></div>`
    }
    return message;
  }
  else if (type === '4'){
    const message = {
      subject: ' Unsubscribe Request',
      title: ' Unsubscribe Request',
      body: `<div style="line-height: 1.6">
      I am writing to request the removal of my email address from your mailing list. While I appreciate the content you have provided, I would like to discontinue receiving further emails.<br/>
      Thank you for your understanding and assistance in this matter.
      </div>`
    }
    return message;
  }
};



const date = new Date();

let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();

export const todaysDate = `${year}-${month}-${day}`;

export const formatDateAndTime = (date, option) => {
  if (date !== null) {
    const user_date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day =
      user_date.getDate() < 10
        ? "0" + user_date.getDate()
        : user_date.getDate();
    const hour =
      user_date.getHours() < 10
        ? "0" + user_date.getHours()
        : user_date.getHours();
    const min =
      user_date.getMinutes() < 10
        ? "0" + user_date.getMinutes()
        : user_date.getMinutes();
    const sec =
      user_date.getSeconds() < 10
        ? "0" + user_date.getSeconds()
        : user_date.getSeconds();

    let date_string = "";
    if (option === "date_and_time")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
    else if (option === "date")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()}`;
    else if (option === "day") date_string = day;
    else if (option === "full_month")
      date_string = monthNames[user_date.getMonth()];
    else if (option === "short_month")
      date_string = monthNamesShort[user_date.getMonth()];
    else if (option === "year_only") date_string = user_date.getFullYear();

    return date_string;
  } else {
    return "--";
  }
};

const positions = ["st", "nd", "rd", "th"]

export function toTitleCase(str) {
  if (str === "" || str === null) {
    return ''
  }
  else {
    return str.toLowerCase().split(' ').slice(0).map(function (word) {
      if (word.toString().length < 5) {
        if (word.toString().length === 1) {
          let _word;
          if (str.toLowerCase().split(' ')[0] === word.toString()) {
            _word = word.charAt(0).slice(0).toUpperCase() + word.slice(1);
          } else {
            _word = word.slice(0).toUpperCase();
          }
          return _word

        }
        else if (word.toString().length === 2) {
          let _word;
          if (str.toLowerCase().split(' ')[0] === word.toString()) {
            _word = word.charAt(0).slice(0).toUpperCase() + word.slice(1);
          } else {
            _word = word.slice(0)
          }
          return _word

        } else if (word.toString().length === 3) {

          let _word;
          if (containsNumbers(word) === true) {
            if (positions.includes(word.slice(1).toLowerCase())) {
              _word = word.charAt(0).slice(0).toString().toUpperCase() + word.slice(1).toLowerCase();
            }
            else {
              _word = word.toString().toUpperCase();
            }
          }
          else if (word.toString().length === 4) {

            let _word;
            if (containsNumbers(word) === true) {
              if (positions.includes(word.slice(1).toLowerCase())) {
                _word = word.charAt(0).slice(0).toString().toUpperCase() + word.slice(1).toLowerCase();
              }
              else {
                _word = word.toString().toUpperCase();
              }
            }
          }
          else
            if (str.toLowerCase().split(' ')[0] === word.toString()) {
              _word = word.charAt(0).slice(0).toUpperCase() + word.slice(1);
            }
            else if (word.toString().toLowerCase() === 'mou' || 'itf' || 'ceo' ) {
              _word = word.toString().toUpperCase()
            }
            else if (word.toString().toLowerCase() === 'law') {
              _word = word.toString().toUpperCase()
            }
            else {
              _word = word.toString().toLowerCase()
            }
          return _word

        } else {
          return (word.charAt(0).toUpperCase() + word.slice(1));
        }
      }
      else {
        return (word.charAt(0).slice(0).toUpperCase() + word.slice(1));
      }

    }).join(' ');
  }
}

function containsNumbers(str) {
  return /\d/.test(str);
}


export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

export function descDynamicSort(property) {
  var sortOrder = -1;
  if (property[0] === "-") {
    sortOrder = 1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

export function escape_string(str) {
  return str.replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, function (char) {
    switch (char) {
      case "\0":
        return "\\0";
      case "\x08":
        return "\\b";
      case "\x09":
        return "\\t";
      case "\x1a":
        return "\\z";
      case "\n":
        return "\\n";
      case "\r":
        return "\\r";
      case "\"":
      case "'":
      case "\\":
      case "%":
        return "\\" + char; // prepends a backslash to backslash, percent,
      // and double/single quotes
    }
  });
}


export const checkImageURL = (url) => {
  if (!url) return false
  else {
      const pattern = new RegExp('^https?:\\/\\/.+\\.(png|jpg|jpeg|bmp|gif|webp)$', 'i');
      return pattern.test(url);
  }
};

// export async function checkImage(url){
     
//   const res = await axios.get(url);
//   console.log(res)
//   const buff = await res.blob();
 
//   return buff.type.startsWith('image/')

// }
export function checkImage(url) {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  })
}


export function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}