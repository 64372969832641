import gallery1 from './img/facility (1).jpeg'
import gallery2 from './img/facility (2).jpeg'
import gallery3 from './img/facility (3).jpeg'
import gallery4 from './img/facility (4).png'
import gallery5 from './img/facility (5).png'
import gallery6 from './img/facility (6).png'

export const gallery_images_data = [
    { images: gallery1 },
    { images: gallery2 },
    { images: gallery3 },
    { images: gallery4 },
    { images: gallery5 },
    { images: gallery6 },
];