import React, { Component } from "react";
import "./aboutbaze.css";
import Banner from "../../utils/banner/banner";
import Square from "../../../images/faculties/square.jpg"
import Card from "../../utils/card/card";
import { connect } from "react-redux";
import GlobeImage from "../../../images/general/globe.png";
import IdeaImage from "../../../images/general/idea.png";
import CampusImage from "../../../images/general/campus_aerial_view.png";
import CampusImage2 from "./baze_map.png";
import VirtualTour from "../../utils/virtualtour";
import Googlemap from "../../utils/googlemap";


class AboutBaze extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let faculty_list = "";
        if (this.props.facultyList.length > 0) {
            this.props.facultyList.map((faculty, index) => {
                return faculty_list += this.props.facultyList.length !== (index + 1) ? `${faculty.FacultyName}, ` : `and ${faculty.FacultyName}`
            })
        }

        return <>
            <Banner image={Square} title="About Baze University" caption="" />

            <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 min-height-fix">
                                <div className="rounded-box grey-back">
                                    <Card title="About Us" description="Baze University is a distinctive quality-based educational institution making a difference in the Nation’s history through the positive impact of its service and graduates output." button="" btn_link="" />
                                    <Card title="Our Mission" description="To establish and maintain a more suitable academic environment, synergizing world-class human capital and best technology for creating and impacting knowledge to develop and modernize the Nigerian society" button="" btn_link="" />
                                    <Card title="Our Vision" description="To be a distinctive quality-based educational institution, making difference in the nation's history through the positive impact of its services and its graduate output." button="" btn_link="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 min-height-fix">
                                <div>
                                    <Card title="Statement of Quality" description="Baze delivers quality by having experienced international staff, superb teaching equipment, overseas external examiners, and first-rate buildings to guarantee standards. Baze aims to provide university education to British standards in Nigeria at about half the cost of sending a student to study abroad Currently, no Nigerian university is rated in the top 5,000 in the world. Many Nigerians thus feel compelled to send their sons and daughters to be educated abroad, at substantial cost (at least $28,000 per year for tuition, travel and living expenses). Baze delivers the same quality of education and living at about $14,000 (N2.2 m) – and, at a critical stage of their lives, students will be staying closer to their families." button="" btn_link="" />
                                    <Card title="Our Campus" description={`The main Campus is 6km from Abuja Central Area, on Jabi Airport Road Bypass Ring Road. Academic activities commenced in the Academic and Administrative building, the Social Area and the Sports Complex. Baze has another site at Bwari for further development. Abuja is in the centre of Nigeria, and has excellent air and road links. Baze began in April 2011 with a Foundation class and three Faculties: Management & Social Scences| Law | Computing & Applied Sciences. Currently BAZE University has ${this.props.facultyList.length} Faculties which includes ${faculty_list}. There are several specialities within these Faculties`} button="" btn_link="" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 min-height-fix">
                                <div className="rounded-box grey-back">
                                    <Card image={IdeaImage} title="Technology-Based" description="Technology is fast revolutionising the world and we aim to be a part of the revolution. Our interest in the subject is reflected in our recent hosting of the event that focused on the importance of drone technology in development programmes." button="" btn_link="" />
                                    <Card image={GlobeImage} title="Diversity" description="Diversification of thought and processes is important and we tailor our curriculum to portray this quality. Our students have the knowledge-base and corresponding skills required to excel everywhere regardless of geography, cultural orientation." button="" btn_link="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Virtual tour*/}
            {/* <VirtualTour /> */}
            {/*Virtual tour*/}

            <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right">
                <div className="row">
                    <div className="">
                        <div className="row">
                            <div className="col-sm-12 ">
                                <div className="col-md-4">
                                    <h2>An Aerial View of Our Campus</h2>
                                    <p>The aerial view showcases our main facilities and infrastructure that are designed and constructed to ensure that students acquire quality education in an environment that is conducive and comfortable for their advancement in knowledge and thought. With facilities ranging from sports and recreations, malls to religious centres, our students have all of their needs met without stepping outside the campus grounds.</p>
                                    <p>In addition to our sound and first-rate buildings, we have employed and acquired highly experienced individuals that will ensure that these facilities are put to the best and most productive use. These individuals - ranging from the academic staff (who are visionaries of the education system) to the security guards and cleaning services - are dedicated to the provision of the best services available to guarantee that standards set forth by the University's board are exceedingly met.</p>
                                    {/* <p><a className="btn btn-default btn-ghost" target="_blank"
                                        href="https://portal.bazeuniversity.edu.ng/tour/index.html" role="button">Campus tour</a></p> */}
                                </div>
                                <div className="col-md-8">
                                    <Googlemap height={600} />
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    }

}
const mapStateToProps = (state) => {
    return {
        facultyList: state.BazeFacultyListDetails
    };
};
export default connect(mapStateToProps, null)(AboutBaze);
