import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import "./alertdialog.css";
import {Button, DialogActions} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialog(props) {
    return (
        <Dialog
            className="dialogBox"
            open={props.openDialog}
            TransitionComponent={Transition}
            keepMounted
            style={{marginTop:'150px'}}
            // fullWidth={props.isFullWidth}
            maxWidth="lg"
            // onClose={props.isCloseBtnAppear}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                <div className=" header">
                    <div className="title">
                        <h3>{props.title}</h3>
                        <button
                            onClick={props.onSetOpenDialog}
                            type="button"
                            className="btn btn-light"
                        >
                            <i className="fa fa-times" aria-hidden="true"/>
                        </button>
                    </div>
                    <hr/>
                </div>
            </DialogTitle>
            <DialogContent>
                {props.children}

            </DialogContent>
            <DialogActions>

                <Button className="btn btn-sm btn-primary" onClick={props.onSetOpenDialog} color="primary">
                  Close
                </Button>
          </DialogActions>
        </Dialog>
    );
}
