import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./clean-energy-style.css"
import { AssociateResearchers, ResearchersData } from './clean-energy-data';
import { titleCase } from 'title-case';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1850 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 1850, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};



function AssociateResearchFellows(props) {
    return (
        <Carousel
            swipeable={true}
            draggable={false}
            showDots={true}
            responsive={responsive}
            infinite={true}
            autoPlay={props.deviceType === "mobile" ? true : false}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .55"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
            {
                AssociateResearchers.map((x, i) => {
                    const staff = props.staffList.length > 0 ?
                        props.staffList.filter(j => j.StaffID === x.staff_id) : [];
                    console.log(staff)
                    return (
                        <div className="col-md-12 text-center" key={i}>
                            <div className="single-person">
                                <div className="person-image">
                                    {
                                        staff.length > 0 ?
                                            <img
                                                src={
                                                    staff[0].image !== null ?
                                                    staff[0].image.includes("simplefileupload") ?
                                                        staff[0].image :
                                                            staff[0].image.includes("portal.bazeuniversity") ?
                                                                staff[0].image
                                                                : `https://portal.bazeuniversity.edu.ng/staff/${staff[0].image}` :
                                                            require('../images/noimage.png')
                                                }
                                                alt=""
                                                style={{
                                                    borderRadius: "50%",
                                                    minHeight:"100px",
                                                    maxHeight:"250px"
                                                }}
                                            /> :
                                            <img
                                                src={x.image !== "" ? x.image : require('../images/noimage.png')}
                                                alt=""
                                                width={"200px"}
                                                style={{
                                                    borderRadius: "50%"
                                                }}
                                            />
                                    }
                                </div>
                                <div className="person-info mt-3">
                                    <p>
                                        <Link to={`/academics/staff/${staff.length > 0 ? staff[0].Slug : ''}`} className="speciality">
                                            {titleCase(x.name)}
                                        </Link><br />
                                        <span className="speciality"><strong>Qualification: &nbsp;</strong> {x.qual} </span> <br />
                                        {
                                            x.dept !== "" && <span className="speciality"><strong>Dept.:&nbsp;</strong> {x.dept} <br /></span>
                                        }
                                        <span className="speciality mt-3"><strong>Research. Area<br/> &nbsp;</strong> {x.research_area} </span>

                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </Carousel>
    )
}

const mapStateToProps = (state) => {
    return {
        staffList: state.BazeStaffListDetails,
    };
};


export default connect(mapStateToProps, null)(AssociateResearchFellows);
