import React, { useState } from "react";
import Banner from "../../utils/banner/banner";
import "../../admission/application-guide/application-guide.css"
import { connect } from "react-redux";
import { setStaffProfileDetails } from '../../../actions/setactiondetailsection'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { serverLink } from "../../../resources/url";
import axios from "axios";
import decryptData, { checkDecryptData } from '../../utils/general.jsx'
import orcid from './orcid.png'
import StaffBanner from './Staff-banner-1.png'
import Loader from "react-loader-spinner";
import Avater from './avater.jpg'
import * as DOMPurify from 'dompurify';
import { checkImageURL } from "../../../resources/constants";

const StaffProfile = (props) => {
    const [loading, setLoading] = useState(true)
    const location = useLocation();
    const navigate = useNavigate();
    const slug = location.pathname.split('/')[3]

    if (slug === "") navigate("");
    const [staffList, setStaffList] = useState(props.staffList !== undefined ? props.staffList : [])
    const [staffDetails, setStaffDetails] = useState(
        props.staffList !== undefined ?
            staffList.filter(item => item.Slug === slug) : []
    )


    const [qualifications, setQualification] = useState([])
    const [teaching, setTeaching] = useState([])
    const [publications, setPublications] = useState([])
    const [books, setBooks] = useState([])
    const [bookChapeter, setBookChapter] = useState([])
    const [conferencePaper, setConferencePaper] = useState([])
    const [Magazine, setMagazine] = useState([])
    const [Blog, setBlog] = useState([])
    const [Patent, setPatent] = useState([])
    const [Journal, setJournal] = useState([])
    const [staff_id, steStaffID] = useState('')
    const [locData, setLocData] = useState({
        country_code: "",
        country_name: "",
        city: "",
        postal: "",
        latitude: "",
        longitude: "",
        IPv4: "",
        state: "",
    })

    const [mediaList, setMediaList] = useState([])
    useEffect(() => {
        getLocationData();
        if (staffDetails === undefined) {
            navigate('/')
        }
        let staff_id;

        axios.get(`${serverLink}staff/website/staff_list/${slug}`)
            .then((result) => {
                if (result.data.length > 0) {
                    setStaffList(result.data)
                    setStaffDetails(result.data);
                    staff_id = result.data[0].StaffID;
                    steStaffID(result.data[0].StaffID);

                    // axios.get(`https://techcrunch.com/wp-content/uploads/2021/07/GettyImages-1207206237.jpg?w=730&crop=1`).then((res) => {
                    //     console.log(res)
                    // })
                }
                setLoading(false)
            })
    }, [slug])

    const getLocationData = async () => {
        await axios.get('https://geolocation-db.com/json/').then((result) => {
            setLocData(result.data);
        }).catch((e) => {
            console.log(e)
        })
    }

    const ImgeUrl = (url) => {
        if (url === null || url === "") {
            return Avater;
        } else if (url.includes("simplefileupload")) {
            return checkImageURL(url) ? url : Avater
        }
        else {
            return `${checkImageURL(`${serverLink}public/uploads/news_uploads/${url}`) ? `${serverLink}public/uploads/news_uploads/${url}` : Avater} `
        }
    }

    const updateStaffPublication = async (type, redirect_link, PubID) => {
        let sendData = {
            EntryID: PubID,
            userIP: locData.IPv4,
            redirect: redirect_link,
            lat: locData.latitude,
            lng: locData.longitude,
        }

        if (type === "view") {
            await axios.patch(`${serverLink}resources/publication/update-view-count`, sendData).then(data => {
                if (data.data.message === "success") {
                    window.open(redirect_link, '_blank');
                }
            }).catch(err => { console.log("Error Updating Count") });
        } else {
            await axios.patch(`${serverLink}resources/publication/update-download-count`, sendData).then(data => {
                if (data.data.message === "success") {
                    window.open(`https://portal.bazeuniversity.edu.ng/staff/${redirect_link}`, '_blank');
                }
            }).catch(err => { console.log("Error Updating Count") });
        }
    }



    const getData = async () => {
        await axios.get(`${serverLink}staff/taught-module/${staff_id}`)
            .then((result) => {
                if (result.data.data.length > 0) {
                    setTeaching(
                        result.data.data
                    )
                }
            })

        await axios.get(`${serverLink}staff/staff_qualification/list/${staff_id}`)
            .then((result) => {
                if (result.data.length > 0) {
                    setQualification(
                        result.data
                    )
                }
            })

        await axios.get(`${serverLink}staff/publications/list/${staff_id}`).then((data) => {
            const books = data.data.filter(x => x.PublicationTypeID === '1')
            const bookChapeter = data.data.filter(x => x.PublicationTypeID === '2')
            const conferencePaper = data.data.filter(x => x.PublicationTypeID === '3')
            const Magazine = data.data.filter(x => x.PublicationTypeID === '4')
            const Blog = data.data.filter(x => x.PublicationTypeID === '5')
            const Patent = data.data.filter(x => x.PublicationTypeID === '6')
            const Journal = data.data.filter(x => x.PublicationTypeID === '7')
            setBooks(books);
            setBookChapter(bookChapeter);
            setConferencePaper(conferencePaper);
            setMagazine(Magazine);
            setBlog(Blog);
            setPatent(Patent);
            setJournal(Journal);
        })
        await axios.get(`${serverLink}staff/socialhandles/list/${staff_id}`).then((result) => {
            if (result.data.length > 0) {
                setMediaList(result.data[0])
            }
            //setLoading(false)
        })

    }

    useEffect(() => {
        if (staff_id !== "") {
            getData();
        }
    }, [staff_id])

    useEffect(() => {
        if (staff_id !== "") {
            getData();
        }
    }, [])


    const UpdateDownloadCount = async (item) => {
        await axios.post(`${serverLink}staff/update_publications_count/${item.EntryID}`)
            .then(result => {
                if (result.data.message === "success") {

                }
                // axios.get(`https://portal.bazeuniversity.edu.ng/student${item.ThesisFile.split("..")[1]}`).then((res) => {
                //     console.log(res)
                // })
            })
    }

    return loading ?
        <>
            <div style={{ Top: '100px' }} >
                <Loader
                    type="ThreeDots"
                    color="#63656A"
                    height={200}
                    width={200}
                    className="text-center"

                />
            </div></> :

        <>

            <>
                <Banner image={StaffBanner}
                    title={staffDetails[0] !== undefined ?
                        `${`${staffDetails[0].title === null ? '' : staffDetails[0].title}` + " " + staffDetails[0].staff_name}` : slug
                    } caption={''} />
                {
                    staffDetails.length > 0 ?
                        <>

                            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                                <div className="row">
                                    <div className="layout-col col1 layout-12 col-sm-12">
                                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="col-image" style={{ maxWidth: '350px', minHieght: '350px' }}>
                                                        {staffDetails[0].image === "" || staffDetails[0].image === null || staffDetails[0].image === undefined ? <>
                                                            <picture>
                                                                <img src={Avater} style={{ maxHeight: '250px' }} />
                                                            </picture>
                                                        </> :
                                                            <>
                                                                <picture>
                                                                    <img
                                                                        src={

                                                                            staffDetails[0].image.includes("simplefileupload") ?

                                                                                staffDetails[0].image :
                                                                                staffDetails[0].image.includes("portal.bazeuniversity") ?
                                                                                    staffDetails[0].image
                                                                                    : `https://portal.bazeuniversity.edu.ng/staff/${staffDetails[0].image}`


                                                                        }
                                                                        alt={staffDetails[0].staff_name}
                                                                        style={{ maxHeight: '300px', borderRadius: '10px', width: '100%' }}
                                                                    />
                                                                </picture>
                                                            </>}


                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="container text-justify">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div style={{ marginTop: '22px' }}>
                                                                    <strong>Qualification: </strong>
                                                                    {
                                                                        qualifications.length > 0 ?
                                                                            qualifications.map((x, y) => {
                                                                                return (
                                                                                    <span key={y}>{x.acronym},&nbsp;</span>
                                                                                )
                                                                            })
                                                                            :
                                                                            <div>
                                                                                No Qualifications
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div style={{ marginTop: '12px' }}>
                                                                    <strong>Role Title: </strong>{staffDetails[0].Position}
                                                                </div>
                                                                <div style={{ marginTop: '12px' }}>
                                                                    <strong>Phone Number: </strong>{staffDetails[0].telephone}
                                                                </div>
                                                                <div style={{ marginTop: '12px' }}>
                                                                    <strong>Email Address: </strong>{staffDetails[0].email}
                                                                </div>
                                                                <div style={{ marginTop: '12px' }}>
                                                                    <strong>Department: </strong>{staffDetails[0].DepartmentName}
                                                                </div>
                                                                <div style={{ marginTop: '12px' }}>
                                                                    <strong>Profile Hits<i className="fa fa-eye"></i>: </strong>{staffDetails[0].visithit}
                                                                </div>
                                                                <div style={{ marginTop: '12px', marginBottom: '12px', display: 'flex' }} >

                                                                    <a target="_blank" href={mediaList.facebook} style={{ textDecoration: 'none' }}>
                                                                        <img src="https://img.icons8.com/color/48/000000/facebook-new.png" />
                                                                    </a>

                                                                    <a target="_blank" href={mediaList.linkedin} style={{ textDecoration: 'none' }}>
                                                                        <img src="https://img.icons8.com/color/48/000000/linkedin-circled--v1.png" />
                                                                    </a>

                                                                    <a target="_blank" href={mediaList.researchgate} style={{ textDecoration: 'none' }}>
                                                                        <img width={'37px'} style={{ borderRadius: '50%', marginBottom: '-20px' }} src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/72/000000/external-researchgate-a-social-networking-site-for-scientists-and-researchers-to-share-papers-logo-color-tal-revivo.png" />&nbsp;
                                                                    </a>

                                                                    <a target="_blank" href={mediaList.academia} style={{ textDecoration: 'none' }}>
                                                                        <img width={'37px'} style={{ borderRadius: '50%', marginBottom: '-20px' }} src="https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/72/000000/external-academia-edu-online-teaching-and-learning-website-logo-shadow-tal-revivo.png" />
                                                                    </a>

                                                                    <a target="_blank" href={mediaList.scholar} style={{ textDecoration: 'none' }}>
                                                                        <img src="https://img.icons8.com/color/48/000000/google-scholar--v3.png" />
                                                                    </a>

                                                                    <a target="_blank" href={mediaList.orcid} style={{ textDecoration: 'none' }}>
                                                                        <img style={{ marginBottom: '-20px' }} src={orcid} width="40px" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 text-justify">
                                                    {
                                                        staffDetails[0].biography !== "" &&
                                                        <>
                                                            <div className="col-sm-12" >
                                                                <h3>Biography</h3>
                                                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(staffDetails[0].biography) }} ></div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                                <div className="row">
                                    <div className="layout-col col1 layout-12 col-sm-12">
                                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="container-fluid layout col1">
                                                        <div className="row">
                                                            <div className="layout-col col1 layout-12 col-sm-12">


                                                                <div className="layout-blocks-ucws-accordion container-fluid roundable block accordion border">
                                                                    <div className="row">
                                                                        <div className="container">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="panel-group" id="accordion1585270170" role="tablist"
                                                                                        aria-multiselectable="true">
                                                                                        <div className="panel panel-default">
                                                                                            <div className="panel-heading" role="tab" id="heading733025994">
                                                                                                <h4 className="panel-title">
                                                                                                    <a className="collapsed" role="button" data-toggle="collapse"
                                                                                                        href="#collapseApplicationBasic">Qualifications
                                                                                                        <span aria-hidden="true"><span className="ucws-icon ucws-icon-chevron"></span></span>
                                                                                                    </a>
                                                                                                </h4>
                                                                                            </div>
                                                                                            <div id="collapseApplicationBasic" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading733025994" aria-expanded="false" style={{ height: '0px' }}>
                                                                                                <div className="panel-body">
                                                                                                    <div className="text-justify">
                                                                                                        <div className="table-responsive">
                                                                                                            <table className="table table-bordered">
                                                                                                                <tbody>
                                                                                                                    <tr style={{ background: 'grey', color: 'white' }}>
                                                                                                                        <td><h5>Title</h5></td>
                                                                                                                        <td><h5>Field</h5></td>
                                                                                                                        <td><h5>Institute</h5></td>
                                                                                                                        <td><h5>Year Acquired   </h5></td>
                                                                                                                    </tr>
                                                                                                                    {qualifications.length > 0 ?
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                qualifications.map((x, y) => {
                                                                                                                                    return (
                                                                                                                                        <tr style={{ textAlign: 'left' }} key={y}>
                                                                                                                                            <td>{x.acronym}</td>
                                                                                                                                            <td>{x.name}</td>
                                                                                                                                            <td>{x.from_institute}</td>
                                                                                                                                            <td>{x.AcquireYear}</td>
                                                                                                                                        </tr>
                                                                                                                                    )

                                                                                                                                })}
                                                                                                                        </> :
                                                                                                                        <>
                                                                                                                            <tr>
                                                                                                                                <td>No Qualifications</td>
                                                                                                                            </tr>
                                                                                                                        </>}



                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="panel panel-default">
                                                                                            <div className="panel-heading " role="tab" id="teaching">
                                                                                                <h4 className="panel-title">
                                                                                                    <a className="collapsed" role="button" data-toggle="collapse"
                                                                                                        href="#teachingR" aria-expanded="true"
                                                                                                        aria-controls="teachingR"
                                                                                                        data-ucws-analytics-toggle=""
                                                                                                        data-ucws-analytics-category="Accordion"
                                                                                                        data-ucws-analytics-label="teach outside Alberta"
                                                                                                        data-ucws-analytics-off-class="collapsed">Teaching Responsibilities<span aria-hidden="true"><span className="ucws-icon ucws-icon-chevron"></span></span>
                                                                                                    </a>
                                                                                                </h4>
                                                                                            </div>
                                                                                            <div id="teachingR" className="panel-collapse collapse " role="tabpanel" aria-labelledby="teaching">
                                                                                                <div className="panel-body">
                                                                                                    <div className="text-justify">
                                                                                                        <div className="table-responsive">
                                                                                                            <table className="table table-bordered">
                                                                                                                <tbody>
                                                                                                                    <tr style={{ background: 'grey', color: 'white' }}>
                                                                                                                        <td><h5>Module Code</h5></td>
                                                                                                                        <td><h5>Module Description</h5></td>
                                                                                                                        <td><h5>Credit Load</h5></td>
                                                                                                                        <td><h5>Semesters Taken</h5></td>
                                                                                                                    </tr>
                                                                                                                    {teaching.length > 0 ?
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                teaching.map((x, y) => {
                                                                                                                                    return (
                                                                                                                                        <tr style={{ textAlign: 'left' }} key={y}>
                                                                                                                                            <td>{x.ModuleCode}</td>
                                                                                                                                            <td>{x.ModuleDescription}</td>
                                                                                                                                            <td>{x.CreditUnit}</td>
                                                                                                                                            {/* <td>{x.StudentCount}</td> */}
                                                                                                                                            <td>{x.Trimester.toString().toUpperCase()}</td>
                                                                                                                                        </tr>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </>
                                                                                                                        : <><tr>
                                                                                                                            <td>No Teaching responsibilities</td></tr></>}

                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                        <div className="panel panel-default">
                                                                                            <div className="panel-heading " role="tab" id="heading614380467">
                                                                                                <h4 className="panel-title">
                                                                                                    <a className="collapsed" role="button" data-toggle="collapse"
                                                                                                        href="#collapseApplicationProcess" aria-expanded="true"
                                                                                                        aria-controls="collapseApplicationProcess"
                                                                                                        data-ucws-analytics-toggle=""
                                                                                                        data-ucws-analytics-category="Accordion"
                                                                                                        data-ucws-analytics-label="Studied outside Alberta"
                                                                                                        data-ucws-analytics-off-class="collapsed">Research<span aria-hidden="true"><span className="ucws-icon ucws-icon-chevron"></span></span>
                                                                                                    </a>
                                                                                                </h4>
                                                                                            </div>
                                                                                            <div id="collapseApplicationProcess" className="panel-collapse collapse " role="tabpanel" aria-labelledby="heading614380467">
                                                                                                <div className="panel-body">
                                                                                                    <div className="text-justify">
                                                                                                        <div className="row">
                                                                                                            <div className="col-sm-12">
                                                                                                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(staffDetails[0].research) }} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                        <div className="panel panel-default">
                                                                                            <div className="panel-heading " role="tab" id="heading6143804675">
                                                                                                <h4 className="panel-title">
                                                                                                    <a className="collapsed" role="button" data-toggle="collapse"
                                                                                                        href="#collapseApplicationProcess5" aria-expanded="true"
                                                                                                        aria-controls="collapseApplicationProcess5"
                                                                                                        data-ucws-analytics-toggle=""
                                                                                                        data-ucws-analytics-category="Accordion"
                                                                                                        data-ucws-analytics-label="Studied"
                                                                                                        data-ucws-analytics-off-class="collapsed">Publications<span aria-hidden="true"><span className="ucws-icon ucws-icon-chevron"></span></span>
                                                                                                    </a>
                                                                                                </h4>
                                                                                            </div>
                                                                                            <div id="collapseApplicationProcess5" className="panel-collapse collapse " role="tabpanel" aria-labelledby="heading6143804675">
                                                                                                <div className="panel-body">
                                                                                                    <div className="text-justify">

                                                                                                        {
                                                                                                            books.length > 0 ?
                                                                                                                <>
                                                                                                                    <h2>Books</h2>
                                                                                                                    <ol>
                                                                                                                        {
                                                                                                                            books.map((x, y) => {
                                                                                                                                return (
                                                                                                                                    <li key={y}>
                                                                                                                                        <span
                                                                                                                                            className="link"
                                                                                                                                            onClick={() => { updateStaffPublication("view", decryptData(x.OnlineURL), x.EntryID); }}
                                                                                                                                        >{x.WorkTitle} {!x.UploadFile ? "" : <> | <span className="fa fa-file-pdf-o" onClick={() => { updateStaffPublication("download", x.UploadFile, x.EntryID); }} /></>}</span><br />
                                                                                                                                        <strong>Authors</strong>: {x.Authors} &emsp;&emsp; <strong>Publisher: </strong>{x.Publisher}<br />
                                                                                                                                        <strong>Publication Date: </strong>{x.PublishedYear} &emsp;&emsp; <strong>Edition: </strong>{x.Edition}<br />
                                                                                                                                        <strong>Place of Publication: </strong>{x.PlaceOfPublication}<br />
                                                                                                                                        <i className="fa fa-eye">&nbsp;{x.ViewCount}</i> &emsp;| <i className="fa fa-download">&nbsp;{x.DownloadCount}</i>

                                                                                                                                    </li>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            )}
                                                                                                                    </ol>

                                                                                                                </>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }

                {
                    bookChapeter.length > 0 ?
                        <>
                            <h2>Book Chapter</h2>
                            <ol>
                                {
                                    bookChapeter.map((x, y) => {
                                        return (
                                            <li key={y}>
                                                <span
                                                    className="link"
                                                    onClick={() => { updateStaffPublication("view", decryptData(x.OnlineURL), x.EntryID); }}
                                                >{x.WorkTitle} {!x.UploadFile ? "" : <> | <span className="fa fa-file-pdf-o" onClick={() => { updateStaffPublication("download", x.UploadFile, x.EntryID); }} /></>}</span><br />
                                                <strong>Authors</strong>: {x.Authors === "" || x.Authors === "None" || x.Authors === "Nil" || x.Authors === "nil" || x.Authors === "NIL" ? staffDetails[0].staff_name : x.Authors} &emsp;&emsp; <strong>Publisher: </strong>{x.Publisher}<br />
                                                <strong>Publication Date: </strong>{x.PublishedYear}<br />
                                                <strong>Place of Publication: </strong>{x.PlaceOfPublication}<br />
                                                <i className="fa fa-eye">&nbsp;{x.ViewCount}</i> &emsp;| <i className="fa fa-download">&nbsp;{x.DownloadCount}</i>

                                            </li>
                                        )
                                    }
                                    )}
                            </ol>
                        </>
                        :
                        <></>
                }


                                                                                                        {
                                                                                                            conferencePaper.length > 0 ?
                                                                                                                <>
                                                                                                                    <h2>conference Paper</h2>
                                                                                                                    <ol>
                                                                                                                        {
                                                                                                                            conferencePaper.map((x, y) => {
                                                                                                                                return (
                                                                                                                                    <li key={y}>
                                                                                                                                        <span
                                                                                                                                            className="link"
                                                                                                                                            onClick={() => { updateStaffPublication("view", decryptData(x.OnlineURL), x.EntryID); }}
                                                                                                                                        >{x.WorkTitle} {!x.UploadFile ? "" : <> | <span className="fa fa-file-pdf-o" onClick={() => { updateStaffPublication("download", x.UploadFile, x.EntryID); }} /></>}</span><br />
                                                                                                                                        <strong>Authors</strong>: {x.Authors} &emsp;&emsp; <br />
                                                                                                                                        <strong>Paper Tile/Proceeding: </strong>{x.PaperTitle}<br />
                                                                                                                                        <strong>Publication Date: </strong>{x.PublishedYear} &emsp;&emsp;<br />
                                                                                                                                        <strong>Place of Publication: </strong>{x.PlaceOfPublication}<br />
                                                                                                                                        <i className="fa fa-eye">&nbsp;{x.ViewCount}</i> &emsp;| <i className="fa fa-download">&nbsp;{x.DownloadCount}</i>

                                                                                                                                    </li>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            )}
                                                                                                                    </ol>

                                                                                                                </>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }


                                                                                                        {
                                                                                                            Blog.length > 0 ?
                                                                                                                <>
                                                                                                                    <h2>Blogs</h2>
                                                                                                                    <ol>
                                                                                                                        {
                                                                                                                            Blog.map((x, y) => {
                                                                                                                                return (
                                                                                                                                    <li key={y}>
                                                                                                                                        <span
                                                                                                                                            className="link"
                                                                                                                                            onClick={() => { updateStaffPublication("view", decryptData(x.OnlineURL), x.EntryID); }}
                                                                                                                                        >{x.WorkTitle} {!x.UploadFile ? "" : <> | <span className="fa fa-file-pdf-o" onClick={() => { updateStaffPublication("download", x.UploadFile, x.EntryID); }} /></>}</span><br />
                                                                                                                                        <strong>Authors</strong>: {x.Authors} &emsp;&emsp; <br />
                                                                                                                                        <strong>Blog Name: </strong>{x.BlogName}<br />
                                                                                                                                        <strong>Publication Date: </strong>{x.PublishedYear} &emsp;&emsp;<br />
                                                                                                                                        <i className="fa fa-eye">&nbsp;{x.ViewCount}</i> &emsp;| <i className="fa fa-download">&nbsp;{x.DownloadCount}</i>
                                                                                                                                    </li>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            )}
                                                                                                                    </ol>

                                                                                                                </>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }

                                                                                                        {
                                                                                                            Journal.length > 0 ?
                                                                                                                <>
                                                                                                                    <h2>Journal</h2>
                                                                                                                    <ol>
                                                                                                                        {
                                                                                                                            Journal.map((x, y) => {
                                                                                                                                return (
                                                                                                                                    <li key={y}>
                                                                                                                                        <span
                                                                                                                                            className="link"
                                                                                                                                            onClick={() => { updateStaffPublication("view", decryptData(x.OnlineURL), x.EntryID); }}
                                                                                                                                        >{x.WorkTitle} {!x.UploadFile ? "" : <> | <span className="fa fa-file-pdf-o" onClick={() => { updateStaffPublication("download", x.UploadFile, x.EntryID); }} /></>}</span><br />
                                                                                                                                        <strong>Authors</strong>: {x.Authors} &emsp;&emsp; <br />
                                                                                                                                        <strong>Article Title: </strong>{x.ArticleTitle}<br />
                                                                                                                                        <strong>Publication Date: </strong>{x.PublishedYear} &emsp;&emsp;<br />
                                                                                                                                        <strong>Journal Issue Number: </strong>{x.JournalIssueNumber}<br />
                                                                                                                                        <strong>Volume Number: </strong>{x.VolumeNumber} | <strong>Database Name: </strong>{x.DatabaseName} | <strong>DOI: </strong>{x.DOI}<br />
                                                                                                                                        <strong>Page Range:</strong>{x.PageRange}<br />
                                                                                                                                        <i className="fa fa-eye">&nbsp;{x.ViewCount}</i> &emsp;| <i className="fa fa-download">&nbsp;{x.DownloadCount}</i>
                                                                                                                                    </li>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            )}
                                                                                                                    </ol>

                                                                                                                </>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }


                                                                                                        {
                                                                                                            Magazine.length > 0 ?
                                                                                                                <>
                                                                                                                    <h2>Magazine</h2>
                                                                                                                    <ol>
                                                                                                                        {
                                                                                                                            Magazine.map((x, y) => {
                                                                                                                                return (
                                                                                                                                    <li key={y}>
                                                                                                                                        <span
                                                                                                                                            className="link"
                                                                                                                                            onClick={() => { updateStaffPublication("view", decryptData(x.OnlineURL), x.EntryID); }}
                                                                                                                                        >{x.WorkTitle} {!x.UploadFile ? "" : <> | <span className="fa fa-file-pdf-o" onClick={() => { updateStaffPublication("download", x.UploadFile, x.EntryID); }} /></>}</span><br />
                                                                                                                                        <strong>Authors</strong>: {x.Authors} &emsp;&emsp; <br />
                                                                                                                                        <strong>Magazine Name: </strong>{x.PaperTitle}<br />
                                                                                                                                        <strong>Publication Date: </strong>{x.PublishedYear} &emsp;&emsp;<br />
                                                                                                                                        <i className="fa fa-eye">&nbsp;{x.ViewCount}</i> &emsp;| <i className="fa fa-download">&nbsp;{x.DownloadCount}</i>
                                                                                                                                    </li>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            )}
                                                                                                                    </ol>

                                                                                                                </>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }


                                                                                                        {
                                                                                                            Patent.length > 0 ?
                                                                                                                <>
                                                                                                                    <h2>Patent</h2>
                                                                                                                    <ol>
                                                                                                                        {
                                                                                                                            Patent.map((x, y) => {
                                                                                                                                return (
                                                                                                                                    <li key={y}>
                                                                                                                                        <span
                                                                                                                                            className="link"
                                                                                                                                            onClick={() => { updateStaffPublication("view", decryptData(x.OnlineURL), x.EntryID); }}
                                                                                                                                        >{x.WorkTitle} {!x.UploadFile ? "" : <> | <span className="fa fa-file-pdf-o" onClick={() => { updateStaffPublication("download", x.UploadFile, x.EntryID); }} /></>}</span><br />
                                                                                                                                        <strong>Authors</strong>: {x.Authors} &emsp;&emsp; <br />
                                                                                                                                        <strong>Magazine Name: </strong>{x.PaperTitle}<br />
                                                                                                                                        <strong>Publication Date: </strong>{x.PublishedYear} &emsp;&emsp;<br />
                                                                                                                                        <strong>Locale: </strong>{x.PlaceOfPublication}<br />
                                                                                                                                        <strong>Patent Number: </strong>{x.PatentNumber}<br />
                                                                                                                                        <strong>Assignee: </strong>{x.Assignee}<br />
                                                                                                                                        <strong>Database: </strong>{x.Database}<br />
                                                                                                                                        <i className="fa fa-eye">&nbsp;{x.ViewCount}</i> &emsp;| <i className="fa fa-download">&nbsp;{x.DownloadCount}</i>
                                                                                                                                    </li>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            )}
                                                                                                                    </ol>

                                                                                                                </>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">


                                                    <div style={{ background: '#f4f4f4', padding: '8px', borderRadius: '5px', paddingLeft: '15px' }}>
                                                        <h3>Contact </h3>
                                                        <hr />
                                                        <h4>Email</h4>
                                                        <a href={`mailto:${staffDetails[0].email}`} target='_blank'>{staffDetails[0].email}</a>
                                                        <br />
                                                        <h4>Phone</h4>
                                                        <a href={`tel:${staffDetails[0].telephone}`} target='_blank'>{staffDetails[0].telephone}</a>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                                <div className="row">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <hr className="solid light-grey" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid layout col1">
                                <div className="row">
                                    <div className="layout-col col1 layout-12 col-sm-12">
                                        <div className="layout-blocks-ucws-info-shim container-fluid roundable block info-shim">
                                            <div className="row">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="more-info-line">
                                                                <div className="more-info-icon">
                                                                    <span className="icon icomoon ucws-info-shim-info light-grey"
                                                                        aria-hidden="true" /> Did you know Baze University Admission system is always open?
                                                                    <a href="https://portal.bazeuniversity.edu.ng/admission"
                                                                        target="_blank" rel="noopener"> Apply here</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div className="text-center" >
                            <h2>Please check back in a while...</h2>
                        </div>
                }
            </>

        </>

}
const mapStateToProps = (state) => {
    return {
        staffList: state.BazeStaffListDetails,
        facultyList: state.BazeFacultyListDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnStaffProfileDetails: (p) => {
            dispatch(setStaffProfileDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffProfile);
