import React, {Component} from "react";
import LeadershipComponent from "./leadershipcomponent";

class LeadershipManagement extends Component {

    render() {
        return <LeadershipComponent title="Management" caption="" category="Management" />
    }
}

export default LeadershipManagement;

