import React, { Component } from "react";
import { serverLink } from "../../../../resources/url";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { height } from "@mui/system";
import "./slider.css";
import { checkImageURL } from "../../../../resources/constants";
import DefBanner from './def_banner.jpg'
class HomeSlider extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;

        this.state = {
            slider: this.props.sliders,
            numOfSlides: this.props.sliders.length - 1,
            currentIndex: 0,
            isFinished: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.changeSlider()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    changeSlider = () => {
        if (this._isMounted) {
            setInterval(() => {
                if (this.state.currentIndex === this.state.numOfSlides) {
                    this._isMounted && this.setState({ currentIndex: 0, isFinished: true })
                }

                if (this.state.currentIndex < this.state.numOfSlides) {
                    if (!this.state.isFinished)
                        this._isMounted && this.setState({ currentIndex: this.state.currentIndex + 1 })
                    this._isMounted && this.setState({ isFinished: false })
                }

            }, 5000);
        }
    }

    movePrev = () => {
        if (this.state.currentIndex !== 0) {
            this._isMounted && this.setState({ currentIndex: this.state.currentIndex - 1 })
        } else {
            this._isMounted && this.setState({ currentIndex: this.state.numOfSlides })
        }
    }

    moveNext = () => {
        if (this.state.currentIndex !== this.state.numOfSlides) {
            this._isMounted && this.setState({ currentIndex: this.state.currentIndex + 1 })
        } else {
            this._isMounted && this.setState({ currentIndex: 0 })
        }
    }

    ImgeUrl = (url) => {
        if(url === null || url === "" ) {
            return DefBanner;
        }else if(url.includes("simplefileupload")) {
            return checkImageURL(url) ? url : DefBanner
        }
        else {
            return `${checkImageURL(`${serverLink}public/uploads/sliders_uploads/${url}`) ? `${serverLink}public/uploads/sliders_uploads/${url}` : DefBanner} ` 
        }
    }

    render() {
        return (
            <div className="mar-top">
                <Carousel infiniteLoop={true} interval={5000} autoPlay={true} renderThumbs={([]) => { }}>
                    {this.props.sliders !== undefined &&
                        this.props.sliders.length > 0 &&
                        this.props.sliders.map((x, i) => {
                            return (
                                <div key={i}>
                                    <img src={this.ImgeUrl(x.ImagePath)} style={{ maxHeight: '650px' }} />

                                    {
                                        x.Caption !== "" &&
                                        <div className="caption_div">
                                            <div className="main_text">{x.Title}</div>
                                            <div >
                                                {
                                                    x.Caption !== null ? x.Caption : ''
                                                }
                                            </div>
                                            {
                                                x.BtnName !== null ?
                                                    <div className="btn-wrapper left">
                                                        <a target={"_blank"} href={x.BtnLink} className="btn btn-default btn-cta btn-primary">
                                                            {x.BtnName}
                                                        </a>

                                                    </div> : ''
                                            }

                                        </div>
                                    }

                                    {
                                        x.BtnName !== null ?
                                            <a target={"_blank"} href={x.BtnLink} className="small_screen_btn" style={{ color: 'white' }}>
                                                {x.BtnName}
                                            </a> : ''
                                    }
                                </div>
                            )
                        })
                    }
                </Carousel>
            </div>
        )
    }
}

export default HomeSlider;