// HEADER
export const setFacultyListDetails = (p) => {
    return {
        type: "SET_BAZE_FACULTY_LIST_DETAILS",
        payload: p,
    };
};

export const setFacultyDetails = (p)=>{
    return{
        type:"SET_FACULTY_DETAILS",
        payload: p
    }
};
// HOMEPAGE DETAILS
export const setHomepageDetails = (p) => {
    return {
        type: "SET_BAZE_HOMEPAGE_DETAILS",
        payload: p,
    };
};

// EVENTS DETAILS
export const setEventsDetails = (p) => {
    return {
        type: "SET_BAZE_EVENTS_DETAILS",
        payload: p,
    };
};

// NEWS DETAILS
export const setNewsDetails = (p) => {
    return {
        type: "SET_BAZE_NEWS_DETAILS",
        payload: p,
    };
};

// NEWS PAGE DETAILS
export const setNewsPageDetails = (p) => {
    return {
        type: "SET_BAZE_NEWS_PAGE_DETAILS",
        payload: p,
    };
};

// COURSE LIST DETAILS
export const setCourseListDetails = (p) => {
    return {
        type: "SET_BAZE_COURSES_DETAILS",
        payload: p,
    };
};

// COURSE PAGE DETAILS
export const setCoursePageDetails = (p) => {
    return {
        type: "SET_BAZE_COURSE_PAGE_DETAILS",
        payload: p,
    };
};

// STAFF LIST DETAILS
export const setStaffListDetails = (p) => {
    return {
        type: "SET_BAZE_STAFF_LIST_DETAILS",
        payload: p,
    };
};

// STAFF PROFILE PAGE DETAILS
export const setStaffProfileDetails = (p) => {
    return {
        type: "SET_BAZE_STAFF_PROFILE_DETAILS",
        payload: p,
    };
};

// STAFF PROFILE PAGE DETAILS
export const setManagementListDetails = (p) => {
    return {
        type: "SET_BAZE_MANAGEMENT_LIST_DETAILS",
        payload: p,
    };
};

// GALLERY DETAILS
export const setGalleryDetails = (p) => {
    return {
        type: "SET_BAZE_GALLERY_DETAILS",
        payload: p,
    };
};

// ALUMNI FEEDBACK DETAILS
export const setAlumniFeedbackDetails = (p) => {
    return {
        type: "SET_BAZE_ALUMNI_FEEDBACK_DETAILS",
        payload: p,
    };
};

// BAZE IN THE PRESS DETAILS
export const setBazeInThePressDetails = (p) => {
    return {
        type: "SET_BAZE_IN_THE_PRESS_DETAILS",
        payload: p,
    };
};


//RUNNING MODULES DETAILS
export const setRunningModules=(p)=>{
    return{
        type:"SET_RUNNING_MODULES",
        payload:p
    }
}


export const setStaffPublicationsAction=(p)=>{
    return{
        type:"SET_STAFF_PUBLICATIONS",
        payload:p
    }
}



