import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import OurCampusImg from "../../../images/general/our_campus.jpg";
import Googlemap from "../../utils/googlemap";
import Address from "./address";

class ContactUs extends Component {
    render() {
        return <>
            <Banner image={OurCampusImg} title="Contact Us" caption="" />

            <Address />

            <div className="container-fluid layout col2-50-50">
                <Googlemap height={600} />
            </div>

        </>;
    }
}

export default ContactUs;
