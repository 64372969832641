export const ResearchersData = [
    {
        staff_id: "E1824",
        image: "https://api.bazeuniversity.edu.ng:5001/public/uploads/leaderships_uploads/Aminu_Hussain__HOD_Computer_EEE.png",
        name: "Dr. Munir Aminu Husein",
        dept: "Electrical and Computer Engineering",
        qual: "Ph.D.",
        research_area: "Renewable energy, electrification planning, energy access"
    },
    {
        staff_id: "E1260",
        image: "",
        name: "Prof. Asso. Amina Batagarawa",
        dept: "Architecture",
        qual: "Ph.D.",
        research_area: "Sustainable architecture"
    },
    {
        staff_id: "E0560",
        image: "https://portal.bazeuniversity.edu.ng/staff/portal/users/account/profile_pix/202207151218501588164425.jpeg",
        name: "Dr. Fatima Muktar Mairami",
        dept: "Biotechnology",
        qual: "Ph.D.",
        research_area: "Biotechnology and microbiology"
    },
    {
        staff_id: "E0047",
        image: "",
        name: "Dr. John Chizea",
        dept: "Economics",
        qual: "Ph.D.",
        research_area: "Energy Economics"
    },
    {
        staff_id: "E0039",
        image: "",
        name: "Prof. Chukwuma Onwagna Okafor",
        dept: "",
        qual: "Ph.D.",
        research_area: "Electricity policy"
    },
    {
        staff_id: "E1754",
        image: "",
        name: "Prof. Sani Isah Abba",
        dept: "Civil Engineering",
        qual: "Ph.D.",
        research_area: "Climate modeling and prediction using machine learning"
    },
    {
        staff_id: "E0541",
        image: "",
        name: "Prof. Zuhair Jubril",
        dept: "Private and Commercial Law",
        qual: "Ph.D.",
        research_area: "Electricity and energy law"
    },
    {
        staff_id: "E1853",
        image: "",
        name: "Dr. Kelachi Celestine Omehia",
        dept: "",
        qual: "Ph.D.",
        research_area: "Environmental management and climate change"
    },
    {
        staff_id: "E1782",
        image: "",
        name: "Dr. Jazuli Abdullahi",
        dept: "Civil Engineering",
        qual: "Ph.D.",
        research_area: "Hydrology and climatology"
    },
    {
        staff_id: "E1114",
        image: "",
        name: "Dr. Barnabas Ojo. Morakinyo",
        dept: "Survey and Geo-informatics",
        qual: "Ph.D.",
        research_area: "Geo-informatics"
    },
    {
        staff_id: "E0767",
        image: "",
        name: "Dr. Hezekhiah Agogo",
        dept: "",
        qual: "Ph.D.",
        research_area: "Chemical engineering and environmental management"
    }
]


export const AssociateResearchers = [
    {
        staff_id: "E1650",
        image: "",
        name: "Usman Alhaji Dodo",
        dept: "Electrical and Computer Engineering",
        qual: "M.Sc.",
        research_area: "Energy and artificial intelligence"
    },
    {
        staff_id: "E1701",
        image: "",
        name: "Talatu Belgore",
        dept: "Electrical and Computer Engineering",
        qual: "M.Sc.",
        research_area: "Electrical Power and Energy"
    },
    {
        staff_id: "E1058",
        image: "",
        name: "Yusuf Abdullahi Badamasi",
        dept: "Electrical and Computer Engineering",
        qual: "M.Sc.",
        research_area: "Renewable energy and energy access"
    },
    {
        staff_id: "E1049",
        image: "",
        name: "Abdullahi Sada",
        dept: "Electrical and Computer Engineering",
        qual: "M.Sc.",
        research_area: "Renewable energy and energy access"
    },
    {
        staff_id: "E1829",
        image: "",
        name: "Shehu Anas Faskari",
        dept: "Electrical and Computer Engineering",
        qual: "M.Sc.",
        research_area: "Power system and control"
    }
]

export const CleanEnergyPartners =
{
    government: [
        {
            organization: "National Council on Climate Change",
            address: "<p>A new organization; has no physical site yet.</p>"
        },
        {
            organization: "Energy Commission of Nigeria",
            address: "<p>The Director General<br/> Energy Commission of Nigeria <br/>Plot 701C, Central Area, Abuja</p>"
        },
        {
            organization: "Nigeria Electricity Regulatory Commission",
            address: "<p>Chairman/CEO <br/> Nigeria Electricity Regulatory Commission <br/>Plot 1387, Cadastral Zone A00 <br/>Central Business District, Abuja </p>"
        },
        {
            organization: "Council for the Regulation of Engineering in Nigeria (COREN)",
            address: "<p>The President<br/>Council for the Regulation of Engineering in Nigeria<br/>22 Addis Ababa Crescent Wuse Zone 4, Abuja<br/>info@coren.gov.ng</p>"
        },
        {
            organization: "Department of Climate Change, Federal Ministry of Environment",
            address: "<p>The Director <br/>Department of Climate Change <br/>Federal Ministry of Environment <br/> Plot 444 Aguiyi Ironsi Way, Maitama, Abuja</p>"
        },
        {
            organization: "National Biotechnology Development Agency (NABDA)",
            address: "<p>Director General/CEO <br/> National Biotechnology Development Agency <br/> Umaru Musa Ya’adua Way, Lugbe <br/> Airport Road, Abuja <br/>info@nabda.gov.ng</p>"
        },
        {
            organization: "Rural Electrification Agency ",
            address: "<p>The Managing Director and CEO <br/>Rural Electrification Agency <br/>No 22, Freetown Street <br/>Wuse 2, Abuja <br/>info@rea.gov.ng</p>"
        },
        {
            organization: "National Power Training Institute of Nigeria (NAPTIN)",
            address: "<p/>Director General <br/> National Power Training Institute of Nigeria <br/>Plot 21, Cadastral Zone, Idu Industrial Area <br/>Mbora District, Abuja <br/> naptin@naptin.gov.ng</p>"
        },
        {
            organization: "Abuja Environmental Protection Agency (AEPA)",
            address: "<p>Abuja, Nigeria</p>"
        },
        {
            organization: "Abuja Municipal Area Council (AMAC)",
            address: "<p>Abuja, Nigeria</p>"
        },
        {
            organization: "NASENI",
            address: "<p>Executive Vice Chairman/CEO, The National Agency for Science and Engineering Infrastructure <br/> Idu Industrial Layout, Garki, Abuja <br/> nasenihq@naseni.org</p>"
        },
        {
            organization: "Transmission Company of Nigeria",
            address: "<p>The Chief Executive Officer <br/> Transmission Company of Nigeria <br/> 14, Zambezi Crescent, Maitama, Abuja <br/> info@tcn.org.ng <br/>"
        }
    ],
    private: [
        {
            organization: "Abuja Electricity Distribution Company (AEDC)",
            address: "<p>Managing Director/CEO <br/> Abuja Electricity Distribution Company (AEDC) <br/> No 1 Ziguinchor Street, Off IBB Way <br/> Wuse Zone 4, Abuja </p>"
        }, {
            organization: "Over 20 private renewable energy firms in Nigeria ",
            address: "<p></p>"
        },
    ],
    development_institutions: [
        {
            organization: "Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH",
            address: "<p>GIZ Büro Nigeria, 12 Charles de Gaulle Street <br/> Asokoro, Abuja <br/> giz-nigeria@giz.de   | Phone: +234 805 529 9996 </p>"
        },
        {
            organization: "United States Agency for International Development (USAID) ",
            address: "<p>Plot 1075 Diplomatic Drive <br> Central District Area, Abuja <br/> abujainfo@usaid.gov  |Phone:  +234 803 900 9300</p>"
        },

    ],
    professionals: [
        {
            organization: "Renewable Energy Association of Nigeria (REAN) ",
            address: "<p>Chief Executive Officer <br/> Renewable Energy Association of Nigeria <br/> 13 Lumumbashi Street, Abacha Estate <br/> Wuse Zone 4, Abuja.info@rean.org.ng </p>"
        },
        {
            organization: "Nigerian Society of Engineers",
            address: "<p>The President <br/> Nigerian Society of Engineers <br/> National Engineering Centre <br/>Labour House Road <br/> Central Business District, Abuja <br/> info@nse.org.ng <br/></p>"
        },
        {
            organization: "Nigerian Environmental Society",
            address: "<p>National President <br/> Nigerian Environmental Society <br/> 41 Moses A. Majekodunmi Crescent <br/> Utako District, Abuja <br/> info@nes.org.ng </p>"
        },
        {
            organization: "Waste Management Society of Nigeria",
            address: "<p>Plot 444, Aguiyi Ironsi Way, Wuse, Abuja</p>"
        },
        {
            organization: "Waste Management Society of Nigeria",
            address: "<p>102 Lagos Street, Ebute-Metta <br/> Lagos, Nigeria <br/> nigerianrecyclers@gmail.com <br/> +234 803 378 8641 </p>"
        },
        {
            organization: "Center for Renewable Energy Research, Bayero University, Kano",
            address: "<p>Bayero University Kano (BUK) <br/> Kano</p>"
        },
        {
            organization: "Clean Energy and Entrepreneurship Center, Federal Polytechnic Nassarawa",
            address: "<p>Federal Polytechnic Nassarawa <br/> Nassarawa </p>"
        },
    ],
    research: [
        {
            organization: "Center for Renewable Energy Research, Bayero University, Kano",
            address: "<p>Bayero University Kano (BUK) <br/> Kano</p>"
        },
        {
            organization: "Clean Energy and Entrepreneurship Center, Federal Polytechnic Nassarawa",
            address: "<p>Federal Polytechnic Nassarawa <br/> Nassarawa </p>"
        },
    ]
}


export const CleanEnergyLeadership = {
    management: [
        {
            name: "Dr. Munir Aminu Husein",
            position: "Director",
            staff_id: "E1824"
        },
        {
            name: "Prof. Amina Batagarawa",
            position: "Deputy Director, Programs",
            staff_id: ""
        },
        {
            name: "Deputy Director, Research ",
            position: "",
            staff_id: ""
        },
        {
            name: "Deputy Director, Collaboration",
            position: "",
            staff_id: ""
        }
    ],
    advisory: [
        {
            name: "Dr. Rislan Kanya",
            position: "",
            staff_id: "E0011",
            useimage: true
        },
        {
            name: "Dr Hakeem Baba-Ahmed",
            position: "",
            staff_id: "E1593",
            useimage: true
        },
        {
            name: "Dr Najashi Bara’u Gafai",
            position: "",
            staff_id: "E1328",
            useimage: false
        },
        {
            name: "Dr. Mani Ibrahim",
            position: "",
            staff_id: "E0012",
            useimage: true
        },

    ]
}

export const CleanEnergyProjects = [
    {
        title: "Large-scale electrification planning using a geospatial approach",
        duration: "2 years",
        investigators: [
            {
                name: "Dr. Munir Aminu",
                staff_id: "E1824"
            }
        ],
        link: ""

    },
    {
        title: "Field Bioclimatic and Cost-Building Research",
        duration: "2 years",
        investigators: [
            {
                name: "Prof. Amina Batagarawa",
                staff_id: "E1824",
            }
        ],
        link: ""
    },
    {
        title: "Sustainable municipal solid waste management for Nigerian cities",
        duration: "3 years",
        investigators: [
            {
                name: "Dr. Munir Aminu",
                staff_id: "E1824"
            }
        ],
        link: ""
    },
    {
        title: "Flash-flood susceptibility assessment using multi-criteria decision-making and machine learning supported by remote sensing and GIS techniques",
        duration: "3 years",
        investigators: [
            {
                name: "Dr. Sani Isah Abba",
                staff_id: "E1824"
            }
        ],
        link: "https://scholar.google.com/citations?view_op=view_citation&hl=en&user=4h2JX7YAAAAJ&citation_for_view=4h2JX7YAAAAJ:hMod-77fHWUC"
    },
    {
        title: "Electrification of healthcare facilities using stand-alone renewable energy system",
        duration: "1 year",
        investigators: [
            {
                name: "Dr. Munir Aminu",
                staff_id: "E1824"
            }
        ],
        link: ""
    },
    {
        title: "Electrification of refugee camps in Sub-Saharan Africa",
        duration: "8 months",
        investigators: [
            {
                name: "Dr. Munir Aminu",
                staff_id: "E1824"
            }
        ],
        link: ""
    },
    {
        title: "Integration of waste-to-energy plants in grid-connected hybrid renewable energy systems in Abuja",
        duration: "2 years",
        investigators: [
            {
                name: "Engr. Usman Alhaji Dodo",
                staff_id: "E1824"
            }
        ],
        link: ""
    },
    {
        title: "Impact of climate change on hydro-climatological parameters in Nigeria",
        duration: "2 years",
        investigators: [
            {
                name: "Dr. Jazuli Abdullah",
                staff_id: "E1824"
            }
        ],
        link: ""

    },
    {
        title: "Nigerian Grid Integration Study",
        duration: "2.5 year",
        investigators: [
            {
                name: "Dr. Munir Aminu",
                staff_id: "E1824"
            }
        ],
        link: ""
    }
]