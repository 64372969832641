import { act } from "react-dom/test-utils";
import {
    setCourseListDetails, setCoursePageDetails,
    setEventsDetails,
    setFacultyListDetails,
    setHomepageDetails, setManagementListDetails,
    setNewsDetails, setStaffListDetails, setStaffProfileDetails
} from "../actions/setactiondetailsection";

export const FacultyListDetailsReducer = (state = "", action) => {
    switch (action.type) {
        case "SET_BAZE_FACULTY_LIST_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const FacultyDetailsReducer=(state="", action)=>{
    switch(action.type){
        case "SET_FACULTY_DETAILS":
            return action.payload;
        default:
            return state;
    }
}

export const HomepageDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_BAZE_HOMEPAGE_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const EventsDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_BAZE_EVENTS_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const NewsDetailsReducer = (state = "", action) => {
    switch (action.type) {
        case "SET_BAZE_NEWS_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const NewsPageDetailsReducer = (state = "", action) => {
    switch (action.type) {
        case "SET_BAZE_NEWS_PAGE_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const CourseListDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_BAZE_COURSES_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const CoursePageDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_BAZE_COURSE_PAGE_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const StaffListDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_BAZE_STAFF_LIST_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const StaffProfileDetailsReducer = (state = "", action) => {
    switch (action.type) {
        case "SET_BAZE_STAFF_PROFILE_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const ManagementListDetailsReducer = (state = "", action) => {
    switch (action.type) {
        case "SET_BAZE_MANAGEMENT_LIST_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const GalleryDetailsReducer = (state = "", action) => {
    switch (action.type) {
        case "SET_BAZE_GALLERY_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const AlumniFeedbackDetailsReducer = (state = "", action) => {
    switch (action.type) {
        case "SET_BAZE_ALUMNI_FEEDBACK_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const BazeInThePressDetailsReducer = (state = "", action) => {
    switch (action.type) {
        case "SET_BAZE_IN_THE_PRESS_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const RunningModulesReducer = (state="", action)=>{
    switch(action.type){
        case "SET_RUNNING_MODULES":
            return action.payload;
        default:
            return state;
    }
}

export const StaffPublicationsReducer = (state=[], action)=>{
    switch(action.type){
        case "SET_STAFF_PUBLICATIONS":
            return action.payload;
        default:
            return state;
    }
}