import React, {Component} from "react";
import {Link} from "react-router-dom";

class NotFound extends Component {
    constructor() {
        super();

        this.state = {
            searchField: ""
        }
    }
    onSearchChange = (e) => {
        this.setState({ searchField: e.target.value });

    }

    onSubmitSearch = (e) => {
        e.preventDefault();

        if (this.state.searchField.trim().toString() === ""){
            return false;
        }
        window.location.href = `/search/${this.state.searchField}`;

    }


    render() {
        return (
            <div className="container-fluid block full-bleed four-o-roar" style={{ marginTop: "160px" }}>
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 animation-message">
                                <div className="message">

                                    <h2>Page not found!</h2>

                                    <div id="search-ucalgary">
                                        <form >
                                            <div className="input-group input-group-lg">
                                                <input className="form-control" value={this.state.searchField}  onChange={this.onSearchChange} type="text" placeholder="Search Course, Staff, Publication"/>
                                                <span className="input-group-btn">
                                                    <button className="btn btn-default btn-search" type="button"  onClick={this.onSubmitSearch}>
                                                        <span className="visible-md-block visible-lg-block search-text">
                                                          Search
                                                        </span>
                                                    </button>
                                                </span>
                                            </div>
                                        </form>
                                    </div>

                                    <p>
                                        <Link to="/">
                                            Go to the University homepage
                                        </Link>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default NotFound;
