const CryptoJS = require("crypto-js");
//const crypto = require('crypto');

export function encryptData(string, val = false) {
    let encrypt_method = "AES-256-CBC";
    let secret_key = val === false ? 'SayyoFilms' : 'BazeEncrypt';
    let secret_iv = val === false ? 'FilmsInternational' : 'BazeEncrypt';
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

    let result = decrypted.toString();
    return btoa(result);
}


export default function decryptData(string, val = false) {
    let encrypt_method = "AES-256-CBC";
    let secret_key = val === false ? 'SayyoFilms' : 'BazeEncrypt';
    let secret_iv = val === false ? 'FilmsInternational' : 'BazeEncrypt';
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

    var result = decrypted.toString(CryptoJS.enc.Utf8);
    return result;
}

export function checkDecryptData(string, val = false) {
    try {
        let encrypt_method = "AES-256-CBC";
        let secret_key = val === false ? 'SayyoFilms' : 'BazeEncrypt';
        let secret_iv = val === false ? 'FilmsInternational' : 'BazeEncrypt';
        // hash
        let kee = CryptoJS.SHA256(secret_key);
        let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

        kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
        ivv = CryptoJS.enc.Utf8.parse(ivv);

        var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

        var result = decrypted.toString(CryptoJS.enc.Utf8);
        return true;
    } catch (e) {
        return false
    }
}