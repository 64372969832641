import React, {useEffect} from "react";
import axios from "axios";
import {serverLink} from "../../resources/url";
import Topbar from "./topbar";
import Menubar from "./menubar";
import {setFacultyListDetails, setFacultyDetails} from "../../actions/setactiondetailsection";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";

function Header (props) {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const getFacultyList =  async () => {
        axios.get(`${serverLink}faculty/list/academics`)
            .then(result => {
                const dataset = result.data;
                if (dataset.length > 0) {
                    props.setOnFacultyListDetails(dataset)
                }
            })
            .catch(error => {
                console.log(error)
            })
     }
 
     useEffect(() => {
         getFacultyList();
     },[])
     
    return (
        <div className="convocation">
            <a href="#content" className="visually-hidden focusable skip-link">
                Skip to main content
            </a>
            <header className="container-fluid">
                <Topbar faculty_list={props.facultyList} />
                <Menubar faculty_list={props.facultyList}/>
            </header>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        facultyList: state.BazeFacultyListDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnFacultyListDetails: (p) => {
            dispatch(setFacultyListDetails(p));
        },
        SetOnFacultyDetails:(p)=>{
            dispatch(setFacultyDetails(p))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

