import React from "react";
import Banner from "../../utils/banner/banner";
import ImageBanner from "../../../images/general/Publications.jpeg";
import "../../admission/application-guide/application-guide.css"
import 'react-data-table-component-extensions/dist/index.css';
import { serverLink } from "../../../resources/url";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import decryptData from "../../utils/general";
import Loader from "react-loader-spinner";

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { descDynamicSort, dynamicSort, toTitleCase } from "../../../resources/constants";
import { setStaffPublicationsAction } from "../../../actions/setactiondetailsection";


const Publications = (props) => {
    // console.log(props)
    const [publicationList, setPublicationList] = useState(props.staffPublications.length > 0 ? props.staffPublications : []);
    const [publicationBackupList, setPublicationBackup] = useState(props.staffPublications.length > 0 ? props.staffPublications : []);
    const [facultyList, setFacultyList] = useState(props.facultyList.length > 0 ? props.facultyList : []);
    const [publicationType, setPublicationType] = useState([]);
    const [byHit, setByHit] = useState( props.staffPublications.length > 0 ? props.staffPublications.sort(descDynamicSort("ViewCount")) : [] )
    const [catLoading, setCatLoading] = useState(true)
    const [Loading, setLoading] = useState(false);
    // const [byHit, setByHit] = useState([])
    const [locData, setLocData] = useState({
        country_code: "",
        country_name: "",
        city: "",
        postal: "",
        latitude: "",
        longitude: "",
        IPv4: "",
        state: "",
    })

    const cols = [
        {
            name: "S/N",
            selector: (row, index) => `${index + 1}`,
            sortable: true,
            width: "7%"
        },
        {
            name: "Title",
            selector: row => `${toTitleCase(row.PaperTitle ? row.PaperTitle.toString().trim() : row.ArticleTitle.toString().trim())}`,
            cell: (row) => (
                <>
                    <span
                        style={{ textDecoration: 'underline' }}
                        onClick={()=>updateStaffPublication("view", decryptData(row.OnlineURL), row.EntryID)}
                    >{toTitleCase(row.WorkTitle.toString().trim())} {
                        !row.UploadFile ? '' :
                            <span
                                style={{ textDecoration: 'underline' }}
                                onClick={()=>updateStaffPublication("download", row.UploadFile, row.EntryID)}>
                                <i className="fa fa-file-pdf-o" style={{ paddingLeft: '5px', fontSize: '15px', borderLeft: '2px solid grey' }} />
                            </span>
                    }</span>
                </>
            ),
            sortable: true,
            width: '25%'
        },
        {
            name: "Type",
            selector: row => `${row.PubType}`,
            sortable: true,
            width: '15%'
        },
        {
            name: "Author",
            selector: row => `${toTitleCase(row.Author)}`,
            cell: (row) => (
                <Link to={`/academics/staff/${row.AuthorSlug}`} style={{ textDecoration: 'none !important' }}><h5>{toTitleCase(row.Author)}</h5>
                </Link>
            ),
            sortable: true,
            width: '15%'
        },
        {
            name: "Other Author(s)",
            selector: row => `${toTitleCase(row.Authors)}`,
            cell: (row) => (
                <small style={{ fontSize: '13px' }}>{toTitleCase(row.Authors)}</small>
            ),
            sortable: true,
            width: '15%'
        },
        {
            name: "Year",
            selector: row => `${row.PubYear}`,
            sortable: true,
            width: '8%'
        },
        {
            name: "Views",
            selector: row => row.ViewCount !== '' ? parseInt(row.ViewCount) : 0,
            cell: (row) => (
                <div>
                    <span data-toggle="tooltip" title="Views">
                        <i className="fa fa-eye" /> {row.ViewCount} </span>
                </div>
            ),
            sortable: true,
            width: '8%'
        },
        {
            name: "Downloads",
            selector: row => row.DownloadCount !== '' ? parseInt(row.DownloadCount) : 0,
            cell: (row) => (
                <div>
                    <span data-toggle="tooltip" title="Downloads" style={{ marginTop: '10px' }}>
                        <i className="fa fa-download" /> {row.DownloadCount} </span>
                </div>
            ),
            sortable: true,
            width: '9%'
        },
    ];

    // console.log(publicationBackupList)

    const [data2, setData2] = useState(props.staffPublications.length > 0 ? props.staffPublications : [])

    useEffect(() => {
        getPublication()
        getLocationData()
    }, []);

    const getLocationData = async () => {
        await axios.get('https://geolocation-db.com/json/').then((result)=> {
            setLocData(result.data);
        }).catch((e)=>{
            console.log(e)
        })
    }


    const updateStaffPublication = async (type, redirect_link, PubID) => {
        let sendData = {
            EntryID: PubID,
            userIP: locData.IPv4,
            redirect: redirect_link,
            lat: locData.latitude,
            lng: locData.longitude,
        }

        if (type === "view"){
            await axios.patch(`${serverLink}resources/publication/update-view-count`, sendData).then(data => {
                setLoading(false);
                if (data.data.message === "success"){
                        window.open(redirect_link, '_blank');
                }
            }).catch(err => {setLoading(false);console.log("Error Updating Count")});
        }else {
            await axios.patch(`${serverLink}resources/publication/update-download-count`, sendData).then(data => {
                setLoading(false);
                if (data.data.message === "success"){
                    window.open(`https://portal.bazeuniversity.edu.ng/staff/${redirect_link}`, '_blank');
                }
            }).catch(err => {setLoading(false);console.log("Error Updating Count")});
        }
    }



    const getPublication = async () => {
        await axios.get(`${serverLink}resources/pub-publication-types`)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    setPublicationType(data)
                }
            })

        await axios.get(`${serverLink}resources/publication/data`)
            .then(result => {
                const data = result.data;
                if (data.publicationList.length > 0) {
                    setCatLoading(false)
                    setPublicationList(data.publicationList)
                    setFacultyList(data.facultyList)
                    setPublicationType(data.publicationType)
                    setPublicationBackup(data.publicationList)
                    setData2(data.publicationList)
                    setByHit(data.byHit)
                }
            })
    }

    const handleSearch = (e) => {
        let filtered = publicationBackupList.filter((x) =>
            !x.PubType || !x.PubYear || !x.WorkTitle || !x.Author ||
            x.PubType.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
            x.PubYear.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
            x.WorkTitle.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
            x.Author.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        )
        setPublicationList(filtered)
        setData2(filtered)
    }

    const filterByFaculty = (e) => {
        const filtered = publicationBackupList.filter(x => x.FacultyID.toString().toLowerCase() === e.toString().toLowerCase())
        setPublicationList(filtered)
        setData2(filtered)
    }

    const filterByType = (e) => {
        const filtered = publicationBackupList.filter(x => !x.PublicationTypeID || x.PublicationTypeID.toString().toLowerCase() === e.toString().toLowerCase())
        setPublicationList(filtered);
        setData2(filtered)
    }

    const getFacultyPubCount = (e) => {
        const filtered = publicationBackupList.filter(x => !x.FacultyID || x.FacultyID.toString().toLowerCase() === e.toString().toLowerCase())
        return filtered.length;
    }

    const getPubTypeCount = (e) => {
        const filtered = publicationBackupList.filter(x => !x.PublicationTypeID || x.PublicationTypeID.toString().toLowerCase() === e.toString().toLowerCase())
        return filtered.length;
    }

    const [tableData, setTableData] = useState({})
    useEffect(() => {
        const columns = cols;
        const data = data2.sort((a, b) => a.WorkTitle !== b.WorkTitle ? a.WorkTitle < b.WorkTitle ? -1 : 1 : 0)
        setTableData({
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search Publication',

        })
    }, [data2])



    return publicationBackupList.length < 1 ?
        <Loader
            type="ThreeDots"
            color="#63656A"
            height={200}
            width={200}
            className="text-center"
        /> :
        <>
            <Banner image={ImageBanner} title="Staff Publications" caption="Publications made by our staff" />

            <div className="container-fluid layout col1" style={{ margin: "10px" }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="layout-blocks-ucws-checklist container-fluid roundable block checklist">
                            <div className="row">
                                <div className="container-fluid">
                                    <div className="row header">
                                        <div className="col-sm-12">

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">

                                            <div className="layout-col col1 layout-12 col-sm-12">
                                                <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <div className="input-group input-group-lg col-md-12" style={{ marginBottom: '10px' }}>
                                                                <input className="form-control" name={"search"} onChange={handleSearch} type="text" placeholder="Search Publication" />
                                                                <span className="input-group-btn">
                                                                </span>
                                                            </div>
                                                            <DataTableExtensions
                                                                {...tableData}
                                                            >
                                                                <DataTable
                                                                    noHeader
                                                                    defaultSortField="Name"
                                                                    defaultSortAsc={false}
                                                                    pagination
                                                                    paginationPerPage={30}
                                                                    highlightOnHover />
                                                            </DataTableExtensions>

                                                        </div>
                                                        <div className="col-md-3">
                                                            <hr />
                                                            <h3 className={"text-uppercase"}>Faculties</h3>
                                                            <a onClick={() => { setData2(publicationBackupList) }}
                                                               href={"#"}
                                                               style={{ fontWeight: "bold", fontsize: "14px", color: "#00B2FF" }}
                                                            >
                                                                All Publications ({publicationBackupList.length})
                                                            </a>
                                                            <h4><b>Undergraduate</b></h4>
                                                            <ul style={{ marginBottom: '30px' }}>
                                                                {facultyList.length > 0 ?
                                                                    <>
                                                                        {facultyList.filter(e => e.FacultyCode !== 'PGS').map((x, y) => {
                                                                            return  getFacultyPubCount(x.FacultyID) > 0 ?  (
                                                                                <li onClick={() => {
                                                                                    filterByFaculty(x.FacultyID)
                                                                                }} key={y}><a style={{ cursor: 'pointer' }}>{x.FacultyName}
                                                                                    <strong>({getFacultyPubCount(x.FacultyID)})</strong>
                                                                                </a>
                                                                                </li>
                                                                            ) : <></>
                                                                        })}
                                                                    </>
                                                                    : <></>}
                                                            </ul>
                                                            <h4><b>Postgraduate</b></h4>
                                                            <ul style={{ marginBottom: '30px' }}>
                                                                {facultyList.length > 0 ?
                                                                    <>
                                                                        {facultyList.filter(e => e.FacultyCode === 'PGS').map((x, y) => {
                                                                            return getFacultyPubCount(x.FacultyID) > 0 ? (
                                                                                <li onClick={() => {
                                                                                    filterByFaculty(x.FacultyID)
                                                                                }} key={y}><a style={{ cursor: 'pointer' }}>{x.FacultyName}
                                                                                    <strong>({getFacultyPubCount(x.FacultyID)})</strong>
                                                                                </a>
                                                                                </li>
                                                                            ) : <></>
                                                                        })}
                                                                    </>
                                                                    : <>
                                                                        <small>
                                                                            <strong>
                                                                                <span style={{ fontStyle: 'italic' }}>loading...
                                                                                </span>
                                                                            </strong>
                                                                        </small>
                                                                    </>
                                                                }
                                                            </ul>

                                                            <h4><b>Publication by Type</b></h4>
                                                            <ul style={{ marginBottom: '30px' }}>
                                                                {publicationType.length > 0 ?
                                                                    <>
                                                                        {publicationType.map((x, y) => {
                                                                            return (
                                                                                <li onClick={() => {
                                                                                    filterByType(x.EntryID)
                                                                                }} key={y}><a style={{ cursor: 'pointer' }}>{x.TypeName}
                                                                                    <strong>({getPubTypeCount(x.EntryID)})</strong>
                                                                                </a>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </>
                                                                    : <>
                                                                        <small>
                                                                            <strong>
                                                                                <span style={{ fontStyle: 'italic' }}>loading...
                                                                                </span>
                                                                            </strong>
                                                                        </small>
                                                                    </>}
                                                            </ul>
                                                            <hr />

                                                            {/* <h4><b>Top 5 Publication by Hits</b></h4>
                                                            <ul style={{ marginBottom: '30px' }}>
                                                                {publicationList.length > 0 ?
                                                                    <>
                                                                        {
                                                                            byHit.slice(0, 5).map((x, y) => {
                                                                                return (
                                                                                    <li onClick={() => {
                                                                                        filterByFaculty(x.FacultyID)
                                                                                    }} key={y}><a style={{ cursor: 'pointer' }}>{x.FacultyName}
                                                                                            <strong>({getFacultyPubCount(x.FacultyID)})</strong>
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </>
                                                                        : <></>}
                                                                </ul> */}
                                                                {/* <h4><b>Postgraduate</b></h4>
                                                                <ul style={{ marginBottom: '30px' }}>
                                                                    {facultyList.length > 0 ?
                                                                        <>
                                                                            {facultyList.filter(e => e.FacultyCode === 'PGS').map((x, y) => {
                                                                                return (
                                                                                    <li onClick={() => {
                                                                                        filterByFaculty(x.FacultyID)
                                                                                    }} key={y}><a style={{ cursor: 'pointer' }}>{x.FacultyName}
                                                                                            <strong>({getFacultyPubCount(x.FacultyID)})</strong>
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </>
                                                                        : <>
                                                                            <small>
                                                                                <strong>
                                                                                    <span style={{ fontStyle: 'italic' }}>loading...
                                                                                    </span>
                                                                                </strong>
                                                                            </small>
                                                                        </>
                                                                    }
                                                                </ul> */}

                                                                {/* <h4><b>Publication by Type</b></h4>
                                                                <ul style={{ marginBottom: '30px' }}>
                                                                    {publicationType.length > 0 ?
                                                                        <>
                                                                            {publicationType.map((x, y) => {
                                                                                return (
                                                                                    <li onClick={() => {
                                                                                        filterByType(x.EntryID)
                                                                                    }} key={y}><a style={{ cursor: 'pointer' }}>{x.TypeName}
                                                                                            <strong>({getPubTypeCount(x.EntryID)})</strong>
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </>
                                                                        : <>
                                                                            <small>
                                                                                <strong>
                                                                                    <span style={{ fontStyle: 'italic' }}>loading...
                                                                                    </span>
                                                                                </strong>
                                                                            </small>
                                                                        </>}
                                                                </ul> */}
                                                                {/* <hr /> */}

                                                                <h4><b>Top 5 Publication by Hits</b></h4>
                                                                <ul style={{ marginBottom: '30px' }}>
                                                                    {publicationList.length > 0 ?
                                                                        <>
                                                                            {
                                                                                byHit.slice(0, 5).map((x, y) => {
                                                                                    return (
                                                                                        <li key={y} ><a target='_blank' href={decryptData(x.OnlineURL)}>
                                                                                            {toTitleCase(x.WorkTitle)} by
                                                                                            {toTitleCase(x.Author)}
                                                                                            &emsp;({x.ViewCount})</a></li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                        : <>
                                                                            <small>
                                                                                <strong>
                                                                                    <span style={{ fontStyle: 'italic' }}>loading...
                                                                                    </span>
                                                                                </strong>
                                                                            </small>
                                                                        </>}
                                                                </ul>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                            <div className="row">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <hr className="solid light-grey" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="container-fluid layout col1">
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-info-shim container-fluid roundable block info-shim">
                            <div className="row">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="more-info-line">
                                                <div className="more-info-icon">
                                                    <span className="icon icomoon ucws-info-shim-info light-grey"
                                                          aria-hidden="true"></span> Did you know Baze University Admission system is always open?
                                                    <a href="https://portal.bazeuniversity.edu.ng/admission"
                                                       target="_blank" rel="noopener"> Apply here</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>

}
const mapStateToProps = (state) => {
    return {
        facultyList: state.BazeFacultyListDetails,
        staffPublications: state.BazeStaffPublications
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnStaffPublications: (p) => {
            dispatch(setStaffPublicationsAction(p));
        },
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Publications)
