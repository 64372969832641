import React from "react";

const HRLine = () => {
    return <div className="row divider link-section-divider">
        <div className="col-sm-12">
            <hr className="light-grey"/>
        </div>
    </div>
}

export default HRLine;
